import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { map, compact, find } from 'lodash';
import Modal from '../../../components/Modal';
import appConfig from '../../../config/app';

import checkoutConfigActions from '../../../redux/futures/checkout_config/actions';
import * as checkoutConfigTypes from '../../../redux/futures/checkout_config/types';

import baseHelper from '../../../helpers/BaseHelper';
import ConfirmModal from '../../../components/ConfirmModal';
import PanelSettingCheckout from '../../../components/PanelSettingCheckout';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { produce } from 'immer';

// ModalSettingCheckoutConfig.propTypes = {
//     visible: PropTypes.bool,
//     onCancel: PropTypes.func,
// }
export default function ModalSettingCheckoutConfig(props) {
    const [selectedCheckoutConfig, setSelectedCheckoutConfig] = useState({});
    const [mode, setMode] = useState(appConfig.FORM_MODE.CREATE);

    const [isLoadedCheckoutConfig, setIsLoadedCheckoutConfig] = useState(false);
    const [isShowModalCheckoutConfig, setIsShowModalCheckoutConfig] = useState(false);

    const checkoutConfigReducer = useSelector((state) => state.checkoutConfigReducer);

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const panelSettingCheckoutRef = React.createRef();
    const inputsRef = new Set();

    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    });

    useEffect(() => {
        dispatch(checkoutConfigActions.list());

        if (queryStrings.get('is_new')) {
            openModalCreate();
        }
    }, []);

    useEffect(() => {
        if (checkoutConfigReducer.action == checkoutConfigTypes.LIST_CHECKOUT_CONFIG) {
            setIsLoadedCheckoutConfig(true);

            const checkoutConfigID = queryStrings.get('checkout_config_id');
            if (checkoutConfigID) {
                const _selectedCheckoutConfig = find(
                    checkoutConfigReducer.checkout_configs,
                    (item) => item.checkout_config_id == checkoutConfigID
                );

                if (_selectedCheckoutConfig) {
                    setSelectedCheckoutConfig(_selectedCheckoutConfig);
                    setMode(appConfig.FORM_MODE.EDIT);
                    setIsShowModalCheckoutConfig(true);
                }
            }
        }

        if (
            [checkoutConfigTypes.CREATE_CHECKOUT_CONFIG, checkoutConfigTypes.UPDATE_CHECKOUT_CONFIG].includes(checkoutConfigReducer.action)
        ) {
            if (checkoutConfigReducer.status) {
                // history.push("/settings");
                dispatch(checkoutConfigActions.list());
                setIsShowModalCheckoutConfig(false);

                window.LadiUI.toastCustom('success', '', checkoutConfigReducer.message);
            } else {
                window.LadiUI.toastCustom('danger', '', checkoutConfigReducer.message);
            }
        }

        if (
            [checkoutConfigTypes.MARK_DEFAULT_CHECKOUT_CONFIG, checkoutConfigTypes.DELETE_CHECKOUT_CONFIG].includes(
                checkoutConfigReducer.action
            )
        ) {
            if (checkoutConfigReducer.status) {
                dispatch(checkoutConfigActions.list());
                window.LadiUI.closeModal('confirm-checkout-config-mark-default');
                window.LadiUI.closeModal('confirm-checkout-config-delete');
                window.LadiUI.toastCustom('success', '', checkoutConfigReducer.message);
            } else {
                window.LadiUI.toastCustom('danger', '', checkoutConfigReducer.message);
            }
        }

        if (checkoutConfigTypes.GET_CHECKOUT_CONFIG == checkoutConfigReducer.action) {
            if (checkoutConfigReducer.status) {
                setSelectedCheckoutConfig(checkoutConfigReducer.checkout_config);
                setMode(appConfig.FORM_MODE.EDIT);
                setIsShowModalCheckoutConfig(true);
            }
        }
    }, [checkoutConfigReducer]);

    const onChangeInput = (event) => {
        setSelectedCheckoutConfig(
            produce(selectedCheckoutConfig, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const openModalCreate = () => {
        setMode(appConfig.FORM_MODE.CREATE);
        setSelectedCheckoutConfig({});
        setIsShowModalCheckoutConfig(true);
    };

    const openModalEdit = (checkoutConfig) => {
        dispatch(checkoutConfigActions.getInfo(checkoutConfig.checkout_config_id));
    };

    const submit = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);
        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);
            return;
        }

        if (!panelSettingCheckoutRef.current.validate()) {
            return;
        }
        const checkoutConfigConfig = panelSettingCheckoutRef.current.getData();
        const checkoutConfig = produce({}, (draft) => {
            draft.checkout_config_id = selectedCheckoutConfig.checkout_config_id;
            draft.name = selectedCheckoutConfig.name;
            draft.config = checkoutConfigConfig;
        });


        if (checkoutConfigConfig && checkoutConfigConfig.policy_config && checkoutConfigConfig.policy_config.is_required) {
            if (!checkoutConfigConfig.policy_config.htlm_text || checkoutConfigConfig.policy_config.htlm_text == '') {
                window.LadiUI.toastCustom('danger', '', 'Vui lòng nhập vào nội dung xác nhận điều khoản!');
                return;
            }
        }


        if (mode == appConfig.FORM_MODE.CREATE) {
            dispatch(checkoutConfigActions.create(checkoutConfig));
        } else {
            dispatch(checkoutConfigActions.update(checkoutConfig));
        }
    };

    const isLoadingSubmit =
        checkoutConfigReducer.loading &&
        [checkoutConfigTypes.CREATE_CHECKOUT_CONFIG, checkoutConfigTypes.UPDATE_CHECKOUT_CONFIG].includes(checkoutConfigReducer.waiting);
    const isLoadingMarkDefault =
        checkoutConfigReducer.loading && [checkoutConfigTypes.MARK_DEFAULT_CHECKOUT_CONFIG].includes(checkoutConfigReducer.waiting);
    const isLoadingDelete =
        checkoutConfigReducer.loading && [checkoutConfigTypes.DELETE_CHECKOUT_CONFIG].includes(checkoutConfigReducer.waiting);
    if (!isLoadedCheckoutConfig) {
        return <div className='loader' style={{ display: 'initial', position: 'initial' }}></div>;
    }

    return (
        // item.is_default == appConfig.STATUS.ACTIVE
        <div className='page-setting' id='checkout-config' title={t('SETTINGS.CHECKOUT_CONFIG')}>
            <div className='block-title-action mb-12'>
                <h3 className='title'>Cấu hình thanh toán</h3>
                <div className='btn-action'>
                    <button type='button' className='ladiui btn btn-primary ' onClick={openModalCreate}>
                        <img className='ladiui btn-custom-img' src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg' alt='' />
                        {t('CHECKOUTS.ADD_CHECKOUT_CONFIG')}
                    </button>
                </div>
            </div>

            <div className='ladi-card mt-24'>
                <table className='ladiui table text-left'>
                    <thead>
                        <tr className='ladiui table-vertical'>
                            <th scope='col'>{t('COMMON.ID')}</th>
                            <th scope='col'>{t('CHECKOUTS.CHECKOUT_CONFIG_NAME')}</th>
                            <th scope='col'>{t('COMMON.CREATED_AT')}</th>
                            <th scope='col'>{t('COMMON.UPDATED_AT')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(checkoutConfigReducer.checkout_configs, (item, index) => (
                            <tr key={index} onClick={() => setSelectedCheckoutConfig(item)} className='ladiui table-vertical main'>
                                <td>{item.checkout_config_id}</td>
                                <td>
                                    {item.name}
                                    {item.is_default == appConfig.STATUS.ACTIVE ? (
                                        <span className='address-default-btn'>Mặc định</span>
                                    ) : (
                                        ''
                                    )}
                                </td>
                                <td>{baseHelper.formatStrToDate(item.created_at)}</td>
                                <td>{baseHelper.formatStrToDate(item.updated_at)}</td>
                                <td className='text-right pd-0'>
                                    <div className='ladiui btn-group'>
                                        <div className='ladiui dropdown hide-mt ba-c'>
                                            <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                                <i className='ladiui icon icon-ldp-dot'></i>
                                            </button>
                                            <ul className='ladiui dropdown-menu r-0'>
                                                <li>
                                                    <a className='ladiui dropdown-item' onClick={() => openModalEdit(item)}>
                                                        {t('ACTIONS.EDIT')}
                                                    </a>
                                                </li>
                                                {item.is_default == appConfig.STATUS.INACTIVE && (
                                                    <React.Fragment>
                                                        <li>
                                                            <a
                                                                className='ladiui dropdown-item'
                                                                onClick={() => {
                                                                    setSelectedCheckoutConfig(item);
                                                                    window.LadiUI.showModal('confirm-checkout-config-mark-default');
                                                                }}
                                                            >
                                                                {t('ACTIONS.MARK_DEFAULT')}
                                                            </a>
                                                        </li>
                                                        {/* <li><a onClick={() => {
                                                                setSelectedCheckoutConfig(item);
                                                                window.LadiUI.showModal('confirm-checkout-config-delete');
                                                            }}>{t('ACTIONS.DELETE')}</a></li> */}
                                                    </React.Fragment>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isShowModalCheckoutConfig && (
                <Modal
                    id='modal-checkout-config'
                    title={mode == appConfig.FORM_MODE.CREATE ? t('CHECKOUTS.ADD_CHECKOUT_CONFIG') : t('CHECKOUTS.EDIT_CHECKOUT_CONFIG')}
                    visible={isShowModalCheckoutConfig}
                    onCancel={() => {
                        setIsShowModalCheckoutConfig(false);
                        history.push('/settings?tab=CONFIG_CHECKOUT');
                    }}
                    onOk={submit}
                    width={600}
                    isLoading={isLoadingSubmit}
                >
                    <div className='ladiui'>
                        <label className='ladiui-label'>{t('CHECKOUTS.CHECKOUT_CONFIG_NAME')}</label>
                        <Input
                            ref={(ref) => inputsRef.add(ref)}
                            name='name'
                            validationName={t('CHECKOUTS.CHECKOUT_CONFIG_NAME')}
                            value={selectedCheckoutConfig.name}
                            onChange={onChangeInput}
                            validations={{ isRequired: true }}
                        />
                    </div>

                    <PanelSettingCheckout ref={panelSettingCheckoutRef} checkoutConfig={selectedCheckoutConfig.config} />
                </Modal>
            )}

            <ConfirmModal
                id='confirm-checkout-config-mark-default'
                title={t('CHECKOUTS.MSG_CHECKOUT_CONFIG_MARK_DEFAULT_TITLE')}
                content={t('CHECKOUTS.MSG_CHECKOUT_CONFIG_MARK_DEFAULT_CONTENT', selectedCheckoutConfig.name)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.APPLY')}
                onOk={() => dispatch(checkoutConfigActions.markDefault(selectedCheckoutConfig.checkout_config_id))}
                isLoading={isLoadingMarkDefault}
            />

            <ConfirmModal
                id='confirm-checkout-config-delete'
                title={t('CHECKOUTS.MSG_CHECKOUT_CONFIG_DELETE_TITLE')}
                content={t('CHECKOUTS.MSG_CHECKOUT_CONFIG_DELETE_CONTENT', selectedCheckoutConfig.name)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.DELETE')}
                onOk={() => dispatch(checkoutConfigActions.delete(selectedCheckoutConfig.checkout_config_id))}
                isLoading={isLoadingDelete}
            />
        </div>
    );
}
