/** @format */

import config from './config';
import i18n from '../i18n';
import i18next from 'i18next';

export const status = {
    OK: 200,
    CREATED: 201,
    NO_STORE: 204,
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    UNAUTHORIZED: 401,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    MANY_REQUEST: 429,
    EXPIRED_SESSION: 503,
    ERROR: 500,
};

export const endpoint = {
    //--------------------LUID------------------------
    PROFILE: `${config.ACCOUNT_UID}/profile`,

    AUTH_RENEW_TOKEN: `${config.API_UID_V2_0}/auth/renew-token`,

    //--------------------LS-------------------------
    STORE_LIST: `${config.API_V2_0}/store/list`,
    STORE_DELETE_IDS: `${config.API_V2_0}/store/deletes`,
    STORE_DELETE_ID: `${config.API_V2_0}/store/delete`,
    STORE_CREATE: `${config.API_V2_0}/store/create`,
    STORE_SHOW: `${config.API_V2_0}/store/show`,
    STORE_UPDATE: `${config.API_V2_0}/store/update`,
    STORE_INFO: `${config.API_V2_0}/store/get-user-info`,
    CREATE_NEW_IP: `${config.API_V2_0}/store/create-new-ip`,
    DELETE_IP: `${config.API_V2_0}/store/delete-ip`,
    SHOW_TRACKING_STORE: `${config.API_V2_0}/store/show-tracking`,
    UPDATE_TRACKING_STORE: `${config.API_V2_0}/store/update-tracking`,
    UPDATE_STATUS_STORE: `${config.API_V2_0}/store/store-active`,

    STORE_PAGE_LIST: `${config.API_V2_0}/page-checkout/list-store`,
    STORE_PAGE_DELETE: `${config.API_V2_0}/page-checkout/delete`,
    STORE_PAGE_DELETES: `${config.API_V2_0}/page-checkout/deletes`,

    CUSTOMER_LIST: `${config.API_V2_0}/customer/list`,
    CUSTOMER_SHOW: `${config.API_V2_0}/customer/show`,
    CUSTOMER_DELETE_IDS: `${config.API_V2_0}/customer/deletes`,
    CUSTOMER_DELETE_ID: `${config.API_V2_0}/customer/delete`,
    CUSTOMER_CREATE: `${config.API_V2_0}/customer/create`,
    CUSTOMER_UPDATE: `${config.API_V2_0}/customer/update`,
    CUSTOMER_SEARCH: `${config.API_V2_0}/customer/search`,
    APPLY_DEFAULT_ADDRESS_CUSTOMER: `${config.API_V2_0}/customer/apply-default-address`,
    LIST_DETAIL_CUSTOMER_ORDER: `${config.API_V2_0}/customer/list-detail-order`,
    EXPORT_CUSTOMER: `${config.API_V2_0}/file/export-customer`,
    IMPORT_CUSTOMER: `${config.API_V2_0}/file/import-customer`,
    UPDATE_CUSTOM_FIELD_BY_CUSTOMER: `${config.API_V2_0}/customer/update-custom-fields`,

    VERIFY_ACCOUNT: `${config.API_V2_0}/customer/admin-verify-email-cus`,
    GET_PASSWORD_TOKEN: `${config.API_V2_0}/customer/get-password-token`,

    CUSTOMER_TAG_DELETE: `${config.API_V2_0}/customer-tag/delete`,
    CUSTOMER_TAG_SEARCH: `${config.API_V2_0}/customer-tag/search`,
    CUSTOMER_TAG_LIST: `${config.API_V2_0}/customer-tag/list`,
    CUSTOMER_TAG_LIST_ALL: `${config.API_V2_0}/customer-tag/list-all`,
    CUSTOMER_TAG_UPDATE: `${config.API_V2_0}/customer-tag/update`,
    CUSTOMER_TAG_CREATE: `${config.API_V2_0}/customer-tag/create`,

    CUSTOMER_ADDRESS_CREATE: `${config.API_V2_0}/customer-address/create`,
    CUSTOMER_ADDRESS_UPDATE: `${config.API_V2_0}/customer-address/update`,
    CUSTOMER_ADDRESS_DELETE: `${config.API_V2_0}/customer-address/delete`,

    PRODUCT_LIST: `${config.API_V2_0}/product/list`,
    LIST_ALL_PRODUCT: `${config.API_V2_0}/product/list-products`,
    PRODUCT_DELETE_IDS: `${config.API_V2_0}/product/deletes`,
    PRODUCT_DELETE_ID: `${config.API_V2_0}/product/delete`,
    PRODUCT_CREATE: `${config.API_V2_0}/product/create`,
    PRODUCT_UPDATE: `${config.API_V2_0}/product/update`,
    PRODUCT_SHOW: `${config.API_V2_0}/product/show`,
    SEARCH_PRODUCT: `${config.API_V2_0}/product/search`,
    IMPORT_PRODUCT: `${config.API_V2_0}/file/import-products`,
    EXPORT_PRODUCT: `${config.API_V2_0}/file/export-products`,
    IMPORT_EVENT: `${config.API_V2_0}/file/import-events`,
    EXPORT_EVENT: `${config.API_V2_0}/file/export-events`,
    IMPORT_SERVICE: `${config.API_V2_0}/file/import-services`,
    EXPORT_SERVICE: `${config.API_V2_0}/file/export-services`,

    LIST_REVIEW: `${config.API_V2_0}/product-review/list`,
    CREATE_REVIEW: `${config.API_V2_0}/product-review/create`,
    CREATE_REVIEWS: `${config.API_V2_0}/product-review/creates`,
    UPDATE_REVIEW: `${config.API_V2_0}/product-review/update`,
    DELETE_REVIEW: `${config.API_V2_0}/product-review/delete`,
    DELETE_REVIEWS: `${config.API_V2_0}/product-review/deletes`,

    PRODUCT_CATEGORY_LIST: `${config.API_V2_0}/product-category/list`,
    PRODUCT_CATEGORY_CREATE: `${config.API_V2_0}/product-category/create`,
    PRODUCT_CATEGORY_UPDATE: `${config.API_V2_0}/product-category/update`,
    PRODUCT_CATEGORY_UPDATE_POSITION: `${config.API_V2_0}/product-category/update-position`,
    PRODUCT_CATEGORY_SHOW: `${config.API_V2_0}/product-category/show`,
    LIST_SELECT_CATEGORY: `${config.API_V2_0}/product-category/list-select`,
    SEARCH_CATEGORY: `${config.API_V2_0}/product-category/search`,
    PRODUCT_CATEGORY_DELETE: `${config.API_V2_0}/product-category/delete`,
    PRODUCT_CATEGORIES_DELETE: `${config.API_V2_0}/product-category/deletes`,

    PRODUCT_TAG_SEARCH: `${config.API_V2_0}/product-tag/search`,
    PRODUCT_TAG_LIST_ALL: `${config.API_V2_0}/product-tag/list-all`,
    PRODUCT_TAG_LIST: `${config.API_V2_0}/product-tag/list`,
    PRODUCT_TAG_UPDATE: `${config.API_V2_0}/product-tag/update`,
    PRODUCT_TAG_CREATE: `${config.API_V2_0}/product-tag/create`,
    PRODUCT_TAG_DELETE: `${config.API_V2_0}/product-tag/delete`,
    REORDER_PRODUCT_TAG: `${config.API_V2_0}/product-tag/re-order`,

    PRODUCT_LIST_VIDEO: `${config.API_V2_0}/product-video/list`,
    PRODUCT_SHOW_VIDEO: `${config.API_V2_0}/product-video/show`,

    VARIANT_DELETE_ID: `${config.API_V2_0}/product-variant/delete`,
    VARIANT_CREATE: `${config.API_V2_0}/product-variant/create`,
    VARIANT_UPDATE: `${config.API_V2_0}/product-variant/update`,
    VARIANT_CHANGE_IMAGE: `${config.API_V2_0}/product-variant/change-image`,
    VARIANT_SHOW: `${config.API_V2_0}/product-variant/show`,
    VARIANT_SEARCH: `${config.API_V2_0}/product-variant/search`,
    GET_VARIANT_INFOS: `${config.API_V2_0}/product-variant/get-variant-infos`,
    RE_ORDER_VARIANT: `${config.API_V2_0}/product-variant/re-order`,
    PACKAGE_CREATE: `${config.API_V2_0}/product-variant/package-create`,
    PACKAGE_UPDATE: `${config.API_V2_0}/product-variant/package-update`,
    PACKAGE_UPDATE_POSITION: `${config.API_V2_0}/product-variant/package-update-position`,
    PACKAGE_DELETE: `${config.API_V2_0}/product-variant/package-delete`,

    PRODUCT_OPTION_DELETE: `${config.API_V2_0}/product-option/delete`,
    PRODUCT_OPTION_CREATE_UPDATE: `${config.API_V2_0}/product-option/create`,

    SAVE_DATA_OPTION_NAME: `${config.API_V2_0}/product-option/update-name`,

    PRODUCT_IMAGE_DELETE: `${config.API_V2_0}/product-image/delete`,
    PRODUCT_IMAGE_CREATE: `${config.API_V2_0}/product-image/create`,
    PRODUCT_IMAGE_UPDATE_SRC: `${config.API_V2_0}/product-image/update-src`,
    PRODUCT_UPDATE_POSITION: `${config.API_V2_0}/product-image/update-position`,

    ORDER_LIST: `${config.API_V2_0}/order/list-order`,
    ORDER_DELETE_IDS: `${config.API_V2_0}/order/deletes`,
    ORDER_DELETE_ID: `${config.API_V2_0}/order/delete`,
    ORDER_CREATE: `${config.API_V2_0}/order/create`,
    ORDER_UPDATE: `${config.API_V2_0}/order/update`,
    ORDER_UPDATE_STATUS: `${config.API_V2_0}/order/update-status`,
    ORDER_SHOW: `${config.API_V2_0}/order/show`,
    FULLFILL_ORDER: `${config.API_V2_0}/order/fullfill`,
    MARK_AS_PAID: `${config.API_V2_0}/order/mark-as-paid`,
    MARK_AS_PAIDS: `${config.API_V2_0}/order/mark-as-paids`,
    UPDATE_ORDER_CUSTOMER_INFO: `${config.API_V2_0}/order/update-customer-info`,
    ASSIGN_ORDER: `${config.API_V2_0}/order/assign`,
    ASSIGN_TO_OTHER_ORDER: `${config.API_V2_0}/order/assign-to-other`,
    LIST_MY_ORDER: `${config.API_V2_0}/order/list-my-orders`,
    PARTIAL_UPDATE_ORDER: `${config.API_V2_0}/order/partial-update`,
    REFUND_ORDER: `${config.API_V2_0}/order/refund`,
    SUGGEST_REFUND: `${config.API_V2_0}/order/suggest-refund`,
    RESEND_MAIL: `${config.API_V2_0}/order/resend-mail`,
    CANCEL_ORDER: `${config.API_V2_0}/order/cancel`,
    CANCEL_ORDERS: `${config.API_V2_0}/order/cancels`,
    UPDATE_ORDER_ADDRESS: `${config.API_V2_0}/order/update-order-address`,
    EXPORT_ORDER: `${config.API_V2_0}/file/export-order`,
    EXPORT_TRANSACTION: `${config.API_V2_0}/file/export-transaction`,
    UPDATE_TAG: `${config.API_V2_0}/order/update-tag`,
    UPDATE_SPAMS: `${config.API_V2_0}/order/update-spams`,
    REMOVE_DUPLICATION_ORDER: `${config.API_V2_0}/order/remove-duplication`,
    GENERATE_CHECKOUT_ORDER: `${config.API_V2_0}/order/generate-checkout`,
    RESEND_TICKET_LIST: `${config.API_V2_0}/order/resend-ticket`,

    ORDER_TAG_DELETE: `${config.API_V2_0}/order-tag/delete`,
    ORDER_TAG_SEARCH: `${config.API_V2_0}/order-tag/search`,
    ORDER_TAG_LIST_ALL: `${config.API_V2_0}/order-tag/list-all`,
    ORDER_TAG_LIST: `${config.API_V2_0}/order-tag/list`,
    ORDER_TAG_UPDATE: `${config.API_V2_0}/order-tag/update`,
    ORDER_TAG_CREATE: `${config.API_V2_0}/order-tag/create`,

    PAYMENT_UPDATE_STATUS: `${config.API_V2_0}/payment/update-status`,
    PAYMENT_LIST_GATEWAYS: `${config.API_V2_0}/payment/list-gateways`,

    DISCOUNT_LIST: `${config.API_V2_0}/discount/list`,
    DISCOUNT_DELETE_IDS: `${config.API_V2_0}/discount/deletes`,
    DISCOUNT_DELETE_ID: `${config.API_V2_0}/discount/delete`,
    DISCOUNT_CREATE: `${config.API_V2_0}/discount/create`,
    DISCOUNT_UPDATE: `${config.API_V2_0}/discount/update`,
    DISCOUNT_SHOW: `${config.API_V2_0}/discount/show`,
    VALIDATE_DISCOUNT: `${config.API_V2_0}/discount/validate-discount`,
    ACTIVE_DISCOUNT: `${config.API_V2_0}/discount/active`,
    EXPIRE_DISCOUNT: `${config.API_V2_0}/discount/expire`,
    EXPORT_DISCOUNT: `${config.API_V2_0}/file/export-coupon`,

    STAFF_LIST: `${config.API_V2_0}/staff/list`,
    STAFF_CREATE: `${config.API_V2_0}/staff/create`,
    STAFF_UPDATE: `${config.API_V2_0}/staff/update`,
    STAFF_REMOVE: `${config.API_V2_0}/staff/remove`,
    STAFF_UPDATE: `${config.API_V2_0}/staff/update`,

    PACKAGE_UPGRADE: `${config.API_V2_0}/package/upgrade`,

    LIST_COUNTRIES: `${config.API_V2_0}/address-config/list-countries`,
    LIST_STATES: `${config.API_V2_0}/address-config/list-states`,
    LIST_DISTRICTS: `${config.API_V2_0}/address-config/list-districts`,
    LIST_WARDS: `${config.API_V2_0}/address-config/list-wards`,

    SHIPPING_LIST_PARTNERS: `${config.API_V2_0}/shipping/list-partners`,
    SHIPPING_LIST_HUBS: `${config.API_V2_0}/shipping/list-hubs`,
    SHIPPING_LIST_SERVICES: `${config.API_V2_0}/shipping/list-services`,
    SHIPPING_GET_FEE: `${config.API_V2_0}/shipping/get-fee`,
    SHIPPING_CANCEL: `${config.API_V2_0}/shipping/cancel`,
    SHIPPING_LIST: `${config.API_V2_0}/shipping/list`,
    SHIPPING_DELETE_IDS: `${config.API_V2_0}/shipping/deletes`,
    SHIPPING_DELETE_ID: `${config.API_V2_0}/shipping/delete`,
    SHIPPING_SHOW: `${config.API_V2_0}/shipping/show`,
    SHIPPING_CREATE: `${config.API_V2_0}/shipping/create`,
    SHIPPING_UPDATE: `${config.API_V2_0}/shipping/update`,
    SHIPPING_UPDATE_FEE: `${config.API_V2_0}/shipping/update-fee`,
    SHIPPING_UPDATE_TRACKING_NUMBER: `${config.API_V2_0}/shipping/update-tracking-number`,
    SHIPPING_UPDATE_STATUS: `${config.API_V2_0}/shipping/update-status`,
    SHIPPING_UPDATE_PAYMENT_STATUS: `${config.API_V2_0}/shipping/update-payment-status`,

    SHIPPING_LIST_STATIONS: `${config.API_V2_0}/shipping/get-station`,

    SHIPPING_RATE_LIST: `${config.API_V2_0}/shipping-rate/list`,
    SHIPPING_RATE_CREATE: `${config.API_V2_0}/shipping-rate/create`,
    SHIPPING_RATE_DELETE: `${config.API_V2_0}/shipping-rate/delete`,
    SHIPPING_RATE_DETAIL_CREATE: `${config.API_V2_0}/shipping-rate/create-detail`,
    SHIPPING_RATE_DETAIL_UPDATE: `${config.API_V2_0}/shipping-rate/update-detail`,
    SHIPPING_RATE_DETAIL_DELETE: `${config.API_V2_0}/shipping-rate/delete-detail`,
    SHIPPING_RATE_GET_AVAILABLE: `${config.API_V2_0}/shipping-rate/get-available-shipping-rate`,

    //------------SEPAY BANK HUB ------------
    LIST_BANK_HUB: `${config.API_V2_0}/payment/list-bank`,
    GET_BANK_ACCOUNT_HOLDER: `${config.API_V2_0}/payment/get-account-holder-name`,
    CONNECT_BANK_HUB: `${config.API_V2_0}/payment/connect-mb`,
    SUBMIT_OTP_CONNECT: `${config.API_V2_0}/payment/submit-otp-connect`,
    RESEND_OTP_CONNECT: `${config.API_V2_0}/payment/resend-otp-connect`,

    SUBMIT_OTP_DELETE: `${config.API_V2_0}/payment/confirm-delete-bank`,
    REQUEST_OTP_DELETE: `${config.API_V2_0}/payment/request-delete-bank`,

    //------------------------------------------------

    SETTING_SHIPPING_CONNECT: `${config.API_V2_0}/setting/connect-shipping-partner`,
    SETTING_SHIPPING_DISCONNECT: `${config.API_V2_0}/setting/disconnect-shipping-partner`,
    SETTING_SHIPPING_INACTIVE: `${config.API_V2_0}/setting/inactive-shipping-partner`,
    SETTING_SHIPPING_ACTIVE: `${config.API_V2_0}/setting/active-shipping-partner`,
    SETTING_LIST_TEMPLATE: `${config.API_V2_0}/setting/list-template`,
    SETTING_CREATE_TEMPLATE: `${config.API_V2_0}/setting/create-template`,
    SETTING_UPDATE_TEMPLATE: `${config.API_V2_0}/setting/update-template`,
    SETTING_RESTORE_TEMPLATE: `${config.API_V2_0}/setting/restore-template`,
    SETTING_PAYMENT_CONNECT: `${config.API_V2_0}/setting/connect-payment-gateway`,
    SETTING_PAYMENT_DISCONNECT: `${config.API_V2_0}/setting/disconnect-payment-gateway`,
    SETTING_PAYMENT_ACTIVE: `${config.API_V2_0}/setting/active-payment-gateway`,
    SETTING_PAYMENT_GATEWAY: `${config.API_V2_0}/setting/update-payment-gateway`,
    SETTING_PAYMENT_EXPIRED_TIME: `${config.API_V2_0}/setting/update-expired-time`,
    SETTING_PAYMENT_INACTIVE: `${config.API_V2_0}/setting/inactive-payment-gateway`,
    SETTING_LIST_WEB_HOOK: `${config.API_V2_0}/web-hook/list`,
    SETTING_WEB_HOOK_CREATE: `${config.API_V2_0}/web-hook/create`,
    SETTING_WEB_HOOK_UPDATE: `${config.API_V2_0}/web-hook/update`,
    SETTING_WEB_HOOK_DELETE: `${config.API_V2_0}/web-hook/delete`,
    SETTING_WEB_HOOK_LIST_TRANSACTION: `${config.API_V2_0}/web-hook/list-transaction`,
    SETTING_LIST_NOTIFICATION_EMAIL: `${config.API_V2_0}/setting/list-notification-email`,
    SETTING_CREATE_NOTIFICATION_EMAIL: `${config.API_V2_0}/setting/create-notification-email`,
    SETTING_CHANGE_STATUS_NOTIFICATION_EMAIL: `${config.API_V2_0}/setting/change-status-notification-email`,
    SETTING_SEND_NOTIFICATION_EMAIL: `${config.API_V2_0}/setting/send-notification-email`,
    SETTING_DELETE_NOTIFICATION_EMAIL: `${config.API_V2_0}/setting/delete-notification-email`,
    SETTING_UPDATE_NOTIFICATION_EMAIL_CONFIG: `${config.API_V2_0}/setting/update-notification-email-configs`,

    SETTING_ADD_CUSTOM_FIELD: `${config.API_V2_0}/custom-field/create`,
    SETTING_LIST_BY_CUSTOM_FIELD: `${config.API_V2_0}/custom-field/list`,
    SETTING_DELETE_CUSTOM_FIELD: `${config.API_V2_0}/custom-field/delete`,
    SETTING_UPDATE_CUSTOM_FIELD: `${config.API_V2_0}/custom-field/update`,
    LIST_ALL_CUSTOM_FIELD: `${config.API_V2_0}/custom-field/list`,
    UPDATE_CUSTOM_FIELD_BY_ORDER: `${config.API_V2_0}/order/update-custom-fields`,

    LIST_BANKS_VIET_QR: `https://api.vietqr.io/v2/banks`,

    STORE_ADDRESS_CREATE: `${config.API_V2_0}/store-address/create`,
    STORE_ADDRESS_UPDATE: `${config.API_V2_0}/store-address/update`,
    STORE_ADDRESS_DELETE: `${config.API_V2_0}/store-address/delete`,
    STORE_ADDRESS_LIST: `${config.API_V2_0}/store-address/list`,

    LIST_CUSTOMER_HISTORY: `${config.API_V2_0}/customer-history/list`,
    DELETE_CUSTOMER_HISTORY: `${config.API_V2_0}/customer-history/delete`,
    CREATE_CUSTOMER_HISTORY: `${config.API_V2_0}/customer-history/create`,
    UPDATE_CUSTOMER_HISTORY: `${config.API_V2_0}/customer-history/update`,

    LIST_ORDER_HISTORY: `${config.API_V2_0}/order-history/list`,
    DELETE_ORDER_HISTORY: `${config.API_V2_0}/order-history/delete`,
    CREATE_ORDER_HISTORY: `${config.API_V2_0}/order-history/create`,
    UPDATE_ORDER_HISTORY: `${config.API_V2_0}/order-history/update`,

    LIST_SHIPPING_HISTORY: `${config.API_V2_0}/shipping-history/list`,
    DELETE_SHIPPING_HISTORY: `${config.API_V2_0}/shipping-history/delete`,
    CREATE_SHIPPING_HISTORY: `${config.API_V2_0}/shipping-history/create`,
    UPDATE_SHIPPING_HISTORY: `${config.API_V2_0}/shipping-history/update`,

    APPLY_DEFAULT_ADDRESS_STORE: `${config.API_V2_0}/store/apply-default-address`,

    OVERVIEW_REPORT: `${config.API_V2_0}/report/overview`,
    ORDER_OVERTIME_REPORT: `${config.API_V2_0}/report/order-over-time`,
    REPORT_TOP_PRODUCT: `${config.API_V2_0}/report/top-product`,

    API_LIST_THIRD_PARTY: `${config.API_V2_0}/api/list-third-party`,
    API_REVOKE_TOKEN: `${config.API_V2_0}/api/revoke-by-owner`,
    API_RESET_API_KEY: `${config.API_V2_0}/api/reset-api-key`,

    INVENTORY_LIST_VARIANT: `${config.API_V2_0}/inventory/list`,
    INVENTORY_UPDATE_QUANTITY: `${config.API_V2_0}/inventory/update-quantity`,
    INVENTORY_UPDATES_QUANTITY: `${config.API_V2_0}/inventory/updates-quantity`,

    ASSIGN_LIST: `${config.API_V2_0}/assign/list`,
    ASSIGN_RELOAD: `${config.API_V2_0}/assign/list`,
    ASSIGN_CREATE: `${config.API_V2_0}/assign/create`,
    ASSIGN_UPDATE: `${config.API_V2_0}/assign/update`,
    ASSIGN_APPLY_BASIC: `${config.API_V2_0}/assign/apply-basic`,
    ASSIGN_CREATE_ADVANCED: `${config.API_V2_0}/assign/create-advanced`,
    ASSIGN_UPDATE_STORE_STATUS: `${config.API_V2_0}/assign/update-store-assign-status`,
    ASSIGN_UPDATE_ONLINE_STATUS: `${config.API_V2_0}/assign/update-online-status`,

    PRINT_ORDER: `${config.API_V2_0}/print/print-order`,
    PRINTS_ORDER: `${config.API_V2_0}/print/prints-order`,
    PRINT_SHIPPING: `${config.API_V2_0}/print/print-shipping`,
    PRINTS_SHIPPING: `${config.API_V2_0}/print/prints-shipping`,

    LIST_CHECKOUT: `${config.API_V2_0}/checkout/list`,
    SHOW_CHECKOUT: `${config.API_V2_0}/checkout/show`,
    UPDATE_CHECKOUT: `${config.API_V2_0}/checkout/update`,
    DELETE_CHECKOUT: `${config.API_V2_0}/checkout/deletes`,
    SEND_NOTIFICATION_CHECKOUT: `${config.API_V2_0}/checkout/send-notification`,

    CREATE_CHECKOUT_CONFIG: `${config.API_V2_0}/checkout-config/create`,
    LIST_CHECKOUT_CONFIG: `${config.API_V2_0}/checkout-config/list`,
    SHOW_CHECKOUT_CONFIG: `${config.API_V2_0}/checkout-config/show`,
    UPDATE_CHECKOUT_CONFIG: `${config.API_V2_0}/checkout-config/update`,
    DELETE_CHECKOUT_CONFIG: `${config.API_V2_0}/checkout-config/delete`,
    MARK_DEFAULT_CHECKOUT_CONFIG: `${config.API_V2_0}/checkout-config/mark-default`,
    GET_CHECKOUT_CONFIG: `${config.API_V2_0}/checkout-config/get-info`,
    CHECKOUT_CONFIG_SHOW_CUSTOM: `${config.API_V2_0}/checkout-config/show-custom`,

    PRODUCT_CROSS_SELL_LIST: `${config.API_V2_0}/product-cross-sell/list`,
    PRODUCT_CROSS_SELL_DELETES: `${config.API_V2_0}/product-cross-sell/deletes`,
    PRODUCT_CROSS_SELL_DELETE: `${config.API_V2_0}/product-cross-sell/delete`,
    PRODUCT_CROSS_SELL_CREATE: `${config.API_V2_0}/product-cross-sell/create`,
    PRODUCT_CROSS_SELL_UPDATE: `${config.API_V2_0}/product-cross-sell/update`,
    PRODUCT_CROSS_SELL_SHOW: `${config.API_V2_0}/product-cross-sell/show`,

    UPLOAD_FILES: `${config.API_FILE_V2_0}/file/up-file`,
    UPLOAD_FILE_DIGITAL: `${config.API_V2_0}/upload-file/user-upload`,
    UPLOAD_FILE_LESSON: `${config.API_V2_0}/upload-file/user-upload`,
    BUNNY_CREATE_VIDEO: `${config.API_V2_0}/upload-file/bunny-create-video`,
    CHUNK_VIDEO: `${config.API_V2_0}/upload-file/chunk-upload`,

    TICKET_SEAT_LIST: `${config.API_V2_0}/ticket-seat/list`,
    TICKET_SEAT_SHOW: `${config.API_V2_0}/ticket-seat/show`,
    TICKET_SEAT_UPDATE: `${config.API_V2_0}/ticket-seat/update`,
    TICKET_SEAT_CHANGE_CHECK_IN: `${config.API_V2_0}/ticket-seat/change-check-in`,
    TICKET_SEAT_DELETE: `${config.API_V2_0}/ticket-seat/delete`,
    TICKET_SEAT_CHECK_IN: `${config.API_V2_0}/ticket-seat/check-in`,
    IMPORT_TICKET_SEAT: `${config.API_V2_0}/file/import-ticket-seat`,
    EXPORT_TICKET_SEAT: `${config.API_V2_0}/file/export-ticket-seat`,
    DOWNLOAD_TEMPLATE_TICKET_SEAT: `${config.API_V2_0}/file/download-template-ticket-seat`,

    LIST_CREDIT_TRANSACTION: `${config.API_V2_0}/credit/list-credit-transaction`,

    LIST_PAGE_CHECKOUT: `${config.API_V2_0}/page-checkout/list`,
    CREATE_PAGE_CHECKOUT: `${config.API_V2_0}/page-checkout/create`,
    CREATE_PAGE_CHECKOUT_V2: `${config.API_V2_0}/page-checkout/create-v2`,
    UPDATE_PAGE_CHECKOUT: `${config.API_V2_0}/page-checkout/update`,
    UPDATE_PAGE_CHECKOUT_V2: `${config.API_V2_0}/page-checkout/update-v2`,
    SHOW_PAGE_CHECKOUT: `${config.API_V2_0}/page-checkout/show`,
    SHOW_PAGE_CHECKOUT_V2: `${config.API_V2_0}/page-checkout/show-v2`,
    DELETE_PAGE_CHECKOUT: `${config.API_V2_0}/page-checkout/delete`,
    DELETES_PAGE_CHECKOUT: `${config.API_V2_0}/page-checkout/deletes`,
    PAGE_COURSE_SHOW: `${config.API_V2_0}/page-checkout/page-course-show`,
    PAGE_CHECKOUT_UPDATE_NAME: `${config.API_V2_0}/page-checkout/update-name`,

    PAGE_COURSE_ACTIVE_AFFILIATE: `${config.API_V2_0}/store/course-active-affiliate`,

    LIST_DOMAIN: `${config.API_V2_0}/domain/list`,
    LIST_VERIFIED_DOMAIN: `${config.API_V2_0}/domain/list-verified`,
    CREATE_DOMAIN: `${config.API_V2_0}/domain/create`,
    DELETE_DOMAIN: `${config.API_V2_0}/domain/delete`,
    VERIFY_DOMAIN: `${config.API_V2_0}/domain/verify`,
    MARK_DEFAULT_DOMAIN: `${config.API_V2_0}/domain/mark-default`,
    REMOVE_DEFAULT_DOMAIN: `${config.API_V2_0}/domain/remove-default`,

    LIST_FORM_CONFIG: `${config.API_V2_0}/form-config/list`,
    CREATE_FORM_CONFIG: `${config.API_V2_0}/form-config/create`,
    UPDATE_FORM_CONFIG: `${config.API_V2_0}/form-config/update`,
    SHOW_FORM_CONFIG: `${config.API_V2_0}/form-config/show`,
    DELETE_FORM_CONFIG: `${config.API_V2_0}/form-config/delete`,
    DELETES_FORM_CONFIG: `${config.API_V2_0}/form-config/deletes`,
    SHOW_ACCOUNT_CONFIG: `${config.API_V2_0}/form-config/show-account-config`,
    ADD_ACCOUNT_CONFIG: `${config.API_V2_0}/form-config/add-account-config`,
    CANCEL_ACCOUNT_CONFIG: `${config.API_V2_0}/form-config/cancel-account-config`,

    INTEGRATE_LIST_FORM_ACCOUNT: `${config.API_V2_0}/form-account/list`,
    INTEGRATE_DELETES_FORM_ACCOUNT: `${config.API_V2_0}/form-account/delete`,
    INTEGRATE_UPDATE_FORM_ACCOUNT: `${config.API_V2_0}/form-account/update`,
    INTEGRATE_SHOW_FORM_ACCOUNT: `${config.API_V2_0}/form-account/show`,
    INTEGRATE_OAUTH2_GET_LINK: `${config.API_V2_0}/form-account/oauth2-get-link`,
    INTEGRATE_OAUTH_ACCESS: `${config.API_V2_0}/form-account/oauth-access`,
    INTEGRATE_LOAD_DATA_FORM_ACCOUNT: `${config.API_V2_0}/form-account/load-data`,
    INTEGRATE_LOAD_CUSTOM_FIELDS_FORM_ACCOUNT: `${config.API_V2_0}/form-account/load-custom-fields`,
    INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_SPREADSHEET: `${config.API_V2_0}/form-account/load-google-spreadsheet`,
    INTEGRATE_FORM_ACCOUNT_LOAD_GOOGLE_WORKSHEET: `${config.API_V2_0}/form-account/load-google-worksheet`,
    INTEGRATE_FORM_ACCOUNT_CREATE_GOOGLE_SHEET: `${config.API_V2_0}/form-account/create-google-sheet`,
    INTEGRATE_FORM_ACCOUNT_SELECT_MANUALLY_GOOGLE_SHEET_ID: `${config.API_V2_0}/form-account/get-spreadsheet-info`,
    INTEGRATE_GOOGLE_SHEETS: `${config.API_V2_0}/form-account/google-sheets`,
    INTEGRATE_OAUTH2_GOOGLE_SHEETS: `${config.API_V2_0}/form-account/oauth2-google-sheets`,
    INTEGRATE_HARAVAN: `${config.API_V2_0}/oauth/haravan`,
    INTEGRATE_SAPO: `${config.API_V2_0}/oauth/sapo`,
    INTEGRATE_CREATE_ALL: `${config.API_V2_0}/form-account/create`,
    INTEGRATE_CREATE_SAPO: `${config.API_V2_0}/oauth/sapo-access`,
    INTEGRATE_SHOPIFY: `${config.API_V2_0}/oauth/shopify`,
    INTEGRATE_NHANH: `${config.API_V2_0}/oauth/nhanh`,
    INTEGRATE_CREATE_NHANH: `${config.API_V2_0}/oauth/nhanh-access`,

    LIST_ALL_CONNECT: `${config.API_V2_0}/form-account/list-all`,

    COURSE_MODULE_CREATE: `${config.API_V2_0}/course-module/create`,
    COURSE_MODULE_LIST: `${config.API_V2_0}/course-module/list`,
    COURSE_MODULE_DELETE: `${config.API_V2_0}/course-module/delete`,
    COURSE_MODULE_UPDATE: `${config.API_V2_0}/course-module/update`,
    COURSE_LESSON_CREATE: `${config.API_V2_0}/course-lesson/create`,
    COURSE_LESSON_DELETE: `${config.API_V2_0}/course-lesson/delete`,
    COURSE_LESSON_UPDATE: `${config.API_V2_0}/course-lesson/update`,
    COURSE_DOCUMENT_DELETE: `${config.API_V2_0}/course-document/delete`,
    REPLICATION_MODULE: `${config.API_V2_0}/course-module/cloning`,
    MODULE_UPDATE_POSITION: `${config.API_V2_0}/course-module/update-position`,
    LESSON_UPDATE_POSITION: `${config.API_V2_0}/course-lesson/update-position`,
    REPLICATION_LESSON: `${config.API_V2_0}/course-lesson/cloning`,
    COURSE_DOCUMENT_CREATE: `${config.API_V2_0}/course-document/create`,
    HISTORY_LEARN: `${config.API_V2_0}/course-lesson/history-learn`,
    COURSE_CUSTOMER_LIST: `${config.API_V2_0}/course-customer/list`,
    LOCK_CUSTOMER: `${config.API_V2_0}/course-customer/lock-customer`,
    CANCEL_LOCK_CUSTOMER: `${config.API_V2_0}/course-customer/un-lock-customer`,
    LOCK_COURSE: `${config.API_V2_0}/course-customer/lock-course`,
    CANCEL_LOCK_COURSE: `${config.API_V2_0}/course-customer/un-lock-course`,
    CREATE_CUSTOMER: `${config.API_V2_0}/course-customer/create`,
    UPDATE_CUSTOMER: `${config.API_V2_0}/course-customer/update-customer`,
    CREATE_MANY_CUSTOMERS: `${config.API_V2_0}/course-customer/add-students`,
    CREATE_ACCOUNT: `${config.API_V2_0}/course-customer/create-student`,
    ADD_COURSE_TO_STUDENT: `${config.API_V2_0}/course-customer/add-course-to-student`,

    FILTER_LIST: `${config.API_V2_0}/filter/list`,
    FILTER_CREATE: `${config.API_V2_0}/filter/create`,
    FILTER_UPDATE: `${config.API_V2_0}/filter/update`,
    FILTER_DELETE: `${config.API_V2_0}/filter/delete`,
    FILTER_DUPLICATE: `${config.API_V2_0}/filter/duplicate`,
};

export default {
    COOKIE: {
        SSID: 'SSID',
        STORE_ID: 'LS_STORE_ID',
        EXPIRE: 365,
        IS_IFRAME: 'IS_IFRAME',
        LADI_UID_OWNER: 'LADI_UID_OWNER',
        LADIPAGE_STORE_ID: 'LADIPAGE_STORE_ID',
    },
    LOCAL_FORAGE: {
        COUNTRY_KEY: 'COUNTRIES',
        STATE_KEY: 'STATES',
        DISTRICT_KEY: 'DISTRICTS',
        WARD_KEY: 'WARDS',
        USER_INFO: 'USER_INFO',
        RECENT_PARTNRER_CODE: 'RECENT_PARTNRER_CODE',
        VERSION_CACHE: 'VERSION_CACHE',
        LOCAL_FORAGE_PROMOTION_DATA: 'LOCAL_FORAGE_PROMOTION_DATA',
    },
    VER: '2.0',
    DEFAULT_FORMAT_DATE_TIME: 'DD/MM/YYYY HH:mm',
    DEFAULT_FORMAT_DATE: 'DD/MM/YYYY',
    REPORT_OVERTIME_FORMAT_DATE: 'DD/MM',
    DEFAULT_DATE_PICKER_FORMAT_DATE: 'dd-MM-yyyy',
    DEFAUT_TIME: 'HH:mm',
    DB_DATE_TIME_FORMAT: 'DD-MM-YYYY HH:mm:ss',
    CUSTOM_FORMAT_DATE_TIME: 'YYYY-MM-DD HH:mm:ss Z',

    IMAGE_SIZE_ALLOW: 5, // MB
    FILE_SIZE_UPLOAD: 25, // MB

    PAGINATION: {
        DEFAULT_LIMIT: 10,
        LIMIT_OPTIONS: [
            { value: 10, name: 10 },
            { value: 20, name: 20 },
            { value: 50, name: 50 },
            { value: 100, name: 100 },
        ],
    },
    SEPARATOR: {
        DEFAULT: '|',
        SLASH: '/',
    },
    PLAN_MONTHS: [3, 6, 9, 12, 24],
    STATUS_OPTIONS: [
        { value: 1, name: `${i18n.t('STATUS.ACTIVE')}` },
        { value: 0, name: `${i18n.t('STATUS.INACTIVE')}` },
    ],
    FORM_MODE: {
        CREATE: 'CREATE',
        EDIT: 'EDIT',
        VIEW: 'VIEW',
    },
    APP: {
        LS: 'ls',
        LP: 'lp',
    },
    DEFAULT_PAYMENT_REDIRECT_AFTER: 5,
    DISCOUNT: {
        TYPE: {
            PERCENT: {
                value: 1,
                name: i18n.t('DISCOUNT_TYPE.PERCENT'),
            },
            FIXED: {
                value: 2,
                name: i18n.t('DISCOUNT_TYPE.FIXED'),
            },
        },
        ONE_PER_CUS: {
            YES: 1,
            NO: 0,
        },
        APPLY_TO: {
            ENTIRE_ORDER_OR_PRODUCT: 1,
            PRODUCT_TAGS: 2,
            PRODUCTS: 3,
        },
        MIN_REQUIREMENT: {
            NONE: 1,
            MIN_AMOUNT: 2,
            MIN_QUANTITY: 3,
        },
        CUSTOMER_GROUPS: {
            EVERY_ONE: 1,
            CUSTOMER_TAGS: 2,
            CUSTOMERS: 3,
        },
        RULE_TYPE: {
            CODE: {
                CODE: 1,
                NAME: i18n.t('DISCOUNTS.RULE_TYPE.CODE'),
            },
            PROMOTION: {
                CODE: 2,
                NAME: i18n.t('DISCOUNTS.RULE_TYPE.PROMOTION'),
            },
        },
        STATUS: {
            ACTIVE: {
                CODE: 'Active',
                NAME: i18n.t('DISCOUNTS.STATUS.ACTIVE'),
            },
            EXPIRED: {
                CODE: 'Expired',
                NAME: i18n.t('DISCOUNTS.STATUS.EXPIRED'),
            },
            PENDING: {
                CODE: 'Pending',
                NAME: i18n.t('DISCOUNTS.STATUS.PENDING'),
            },
        },
    },
    SHIPPING_TYPE: {
        FREE: 1,
        CUSTOM: 2,
        AVAILABLE: 3,
    },
    SHIPPING_PARTNERS: {
        GHN: {
            CODE: 'GHN',
            NAME: i18n.t('SHIPPING_PARTNERS.GHN'),
        },
        GHTK: {
            CODE: 'GHTK',
            NAME: i18n.t('SHIPPING_PARTNERS.GHTK'),
        },
        VNPOST: {
            CODE: 'VNPOST',
            NAME: i18n.t('SHIPPING_PARTNERS.VNPOST'),
        },
        VTPOST: {
            CODE: 'VTPOST',
            NAME: i18n.t('SHIPPING_PARTNERS.VIETTEL_POST'),
        },
        OTHER: {
            CODE: 'OTHER',
            NAME: i18n.t('SHIPPING_PARTNERS.OTHER'),
        },
    },
    OTHER_STATE: -1,
    GHTK: {
        DELIVER_WORK_SHIFT: {
            DEFAULT: {
                code: '',
                name: 'Mặc định',
            },
            MORNING: {
                code: 1,
                name: 'Buổi sáng',
            },
            AFTERNOON: {
                code: 2,
                name: 'Buổi chiều',
            },
            EVENING: {
                code: 3,
                name: 'Buổi tối',
            },
        },
        METHOD: {
            ROAD: {
                code: 'road',
                name: 'Đường bộ',
            },
            FLY: {
                code: 'fly',
                name: 'Đường bay',
            },
        },
        FREE_SHIP: {
            ONLY_COD: 1,
            COD_AND_TOTAL: 0,
        },
    },
    ACTIVE: 1,
    INACTIVE: 0,
    ORDER_STATUS: {
        OPEN: {
            CODE: 'Open',
            NAME: i18n.t('ORDER_STATUS.OPEN'),
        },
        CANCELED: {
            CODE: 'Canceled',
            NAME: i18n.t('ORDER_STATUS.CANCELED'),
        },
    },
    ORDER_SPAM: {
        SPAM: {
            CODE: 1,
            NAME: i18n.t('ORDER_SPAM.SPAM'),
        },
        NOT_SPAM: {
            CODE: 0,
            NAME: i18n.t('ORDER_SPAM.NOT_SPAM'),
        },
    },
    ORDER_FILTER_TYPE: {
        DRAFT: 'DRAFT',
        PAYMENT_PENDING: 'PAYMENT_PENDING',
        SHIPPING_PENDING: 'SHIPPING_PENDING',
        SPAM: 'SPAM',
        ALL: 'ALL',
        DUPLICATION: 'DUPLICATION',
        SEARCH_ALL: 'SEARCH_ALL',
    },
    PAYMENT_STATUS: {
        PENDING: {
            CODE: 'Pending',
            NAME: i18n.t('PAYMENT_STATUS.PENDING'),
        },
        SUCCESS: {
            CODE: 'Success',
            NAME: i18n.t('PAYMENT_STATUS.SUCCESS'),
        },
        // CANCELED: {
        //     CODE: 'Canceled',
        //     NAME: i18n.t('PAYMENT_STATUS.CANCELED')
        // },
    },
    SHIPPING_PAYMENT_STATUS: {
        PENDING: {
            CODE: 'Pending',
            NAME: i18n.t('SHIPPING_PAYMENT_STATUS.PENDING'),
        },
        PAID: {
            CODE: 'Paid',
            NAME: i18n.t('SHIPPING_PAYMENT_STATUS.PAID'),
        },
        RECEIVED: {
            CODE: 'Received',
            NAME: i18n.t('SHIPPING_PAYMENT_STATUS.RECEIVED'),
        },
    },
    SHIPPING_STATUS: {
        PENDING: {
            CODE: 'Pending',
            NAME: i18n.t('SHIPPING_STATUS.PENDING'),
        },
        READY_TO_PICK: {
            CODE: 'ReadyToPick',
            NAME: i18n.t('SHIPPING_STATUS.READY_TO_PICK'),
        },
        DELIVERING: {
            CODE: 'Delivering',
            NAME: i18n.t('SHIPPING_STATUS.DELIVERING'),
        },
        DELIVERED: {
            CODE: 'Delivered',
            NAME: i18n.t('SHIPPING_STATUS.DELIVERED'),
        },
        RETURNING: {
            CODE: 'Returning',
            NAME: i18n.t('SHIPPING_STATUS.RETURNING'),
        },
        RETURNED: {
            CODE: 'Returned',
            NAME: i18n.t('SHIPPING_STATUS.RETURNED'),
        },
        CANCELED: {
            CODE: 'Canceled',
            NAME: i18n.t('SHIPPING_STATUS.CANCELED'),
        },
        DELIVERED_TO_POSTMAN: {
            CODE: 'DeliveredToPostMan',
            NAME: i18n.t('SHIPPING_STATUS.DELIVERED_TO_POSTMAN'),
        },
    },
    SHIPPING_RATE_DETAIL_TYPE: {
        PRICE: {
            value: 1,
            name: i18n.t('SHIPPING_RATES.TYPE.PRICE'),
        },
        WEIGHT: {
            value: 2,
            name: i18n.t('SHIPPING_RATES.TYPE.WEIGHT'),
        },
    },
    ADDRESS_TYPE: {
        BILLING: 'BILLING',
        SHIPPING: 'SHIPPING',
        BILLING_INFO: 'BILLING_INFO',
    },
    STATUS: {
        ACTIVE: 1,
        INACTIVE: 0,
    },
    MODE: {
        TEST: 0,
        PRODUCTION: 1,
    },
    SEND: {
        NO: 0,
        YES: 1,
    },
    INVENTORY_UPDATE_MODE: {
        ADD: '1',
        SET: '2',
    },
    ROLE: {
        OWNER: {
            CODE: 'OWNER',
            NAME: i18n.t('ACCOUNTS.OWNER'),
        },
        MANAGER: {
            CODE: 'MANAGER',
            NAME: i18n.t('ACCOUNTS.MANAGER'),
        },
        STAFF: {
            CODE: 'STAFF',
            NAME: i18n.t('ACCOUNTS.STAFF'),
        },
    },
    STAFF_STATUS: {
        PENDING: {
            CODE: 'Pending',
            NAME: i18n.t('STAFF_STATUS.PENDING'),
        },
        ACTIVE: {
            CODE: 'Active',
            NAME: i18n.t('STAFF_STATUS.ACTIVE'),
        },
        INACTIVE: {
            CODE: 'Inactive',
            NAME: i18n.t('STAFF_STATUS.INACTIVE'),
        },
    },
    PACKAGE_NAME: {
        PREMIUM: 'PREMIUM',
        STARTER: 'STARTER',
        STANDARD: 'STANDARD',
        STANDARD_LIFETIME: 'STANDARD_LIFETIME',
        BUSINESS_3: 'BUSINESS_3',
        BUSINESS_5: 'BUSINESS_5',
        PERSONAL: 'PERSONAL',
    },
    WHITE_LOGO: 'https://w.ladicdn.com/source/v3/logo_ladisales_neg.svg',
    COLOR_LOGO: 'https://w.ladicdn.com/source/v3/logo_ladisales_origin.svg',
    DOMAIN_CDN: 'https://w.ladicdn.com',
    DOMAIN_STORE_DEFAULT: 'store.ldp.store',
    DOMAIN_CHECKOUT_DEFAULT: 'checkout.ladi.sale',
    URL_CHECKOUT_DEFAULT: 'https://checkout.ladi.sale',
    RESIZE: {
        THUMB: 's150x150',
        SMALL: 's300x300',
        MEDIUM: 's600x600',
    },
    EXTENSION_ALLOW_RESIZES: ['png', 'jpg'],
    ORDER_SOURCES: {
        LP: {
            CODE: 'LADIPAGE',
            NAME: i18n.t('ORDER_SOURCES.LP'),
        },
        LS: {
            CODE: 'LADISALES',
            NAME: i18n.t('ORDER_SOURCES.LS'),
        },
        OTHER: {
            CODE: 'OTHER',
            NAME: i18n.t('ORDER_SOURCES.OTHER'),
        },
    },
    ORDER_ASSIGN_STATUS: {
        ASSIGNED: {
            CODE: true,
            NAME: i18n.t('ORDERS.ASSIGN_STATUS.ASSIGNED'),
        },
        NOT_ASSIGN: {
            CODE: false,
            NAME: i18n.t('ORDERS.ASSIGN_STATUS.NOT_ASSIGN'),
        },
    },
    SOURCE: {
        LS: 'LADISALES',
        LP: 'LADIPAGE',
        OTHER: 'OTHER',
    },
    CURRENCIES: {
        VND: {
            CODE: 'đ',
            SYMBOL: 'VND',
            NAME: i18n.t('CURRENCY.VND'),
        },
        // USD: {
        //     CODE: '$',
        //     SYMBOL: "USD",
        //     NAME: i18n.t('CURRENCY.USD')
        // }
    },
    LANGUAGES: {
        VI: {
            code: 'vi',
            name: i18next.t('LANGUAGES.VN'),
        },
        EN: {
            code: 'en',
            name: i18next.t('LANGUAGES.EN'),
        },
        ID: {
            code: 'id',
            name: i18next.t('LANGUAGES.ID'),
        },
        TL: {
            code: 'tl',
            name: i18next.t('LANGUAGES.TL'),
        },
        KM: {
            code: 'km',
            name: i18next.t('LANGUAGES.KM'),
        },
        LO: {
            code: 'lo',
            name: i18next.t('LANGUAGES.LO'),
        },
        TH: {
            code: 'th',
            name: i18next.t('LANGUAGES.TH'),
        },
        MS: {
            code: 'ms',
            name: i18next.t('LANGUAGES.MS'),
        },
    },
    TAG_COLORS: ['#E7E9EB', '#3C72F9', '#FDE298', '#F4F2FC', '#BEF29E', '#FFF2CB', '#FFC8B5', '#FDE0D0', '#FFE3E3', '#EBE4CF'],
    CANCEL_ORDER_REASONS: [
        i18n.t('ORDERS.CANCEL_ORDER_REASONS.CUSTOMER'),
        i18n.t('ORDERS.CANCEL_ORDER_REASONS.CANNOT_CALL'),
        i18n.t('ORDERS.CANCEL_ORDER_REASONS.ISSUE'),
        i18n.t('ORDERS.CANCEL_ORDER_REASONS.NOT_AVAILABLE'),
        i18n.t('ORDERS.CANCEL_ORDER_REASONS.DUPLICATION'),
        i18n.t('ORDERS.CANCEL_ORDER_REASONS.OTHER'),
    ],
    WEIGHT_UNITS: {
        G: {
            CODE: 'g',
            NAME: 'g',
        },
        KG: {
            CODE: 'kg',
            NAME: 'kg',
        },
    },
    INVENTORY_STATUS: {
        ACTIVE: {
            CODE: 1,
            NAME: i18n.t('INVENTORY_STATUS.ACTIVE'),
        },
        INACTIVE: {
            CODE: 0,
            NAME: i18n.t('INVENTORY_STATUS.INACTIVE'),
        },
    },
    EXPORT_ORDER: {
        ON_PAGE: 1,
        ALL_ORDER: 2,
        SELECTED_ORDER: 3,
    },
    EXPORT_CUSTOMER: {
        ON_PAGE: 1,
        ALL_CUSTOMER: 2,
        SELECTED_CUSTOMER: 3,
    },
    EXPORT_PRODUCT: {
        ON_PAGE: 1,
        ALL_PRODUCT: 2,
        SELECTED_PRODUCT: 3,
    },
    EXPORT_EVENT: {
        ON_PAGE: 1,
        ALL_EVENT: 2,
        SELECTED_EVENT: 3,
    },
    EXPORT_SERVICE: {
        ON_PAGE: 1,
        ALL_SERVICE: 2,
        SELECTED_SERVICE: 3,
    },
    EXPORT_DISCOUNT: {
        ON_PAGE: 1,
        ALL_DISCOUNT: 2,
        SELECTED_DISCOUNT: 3,
    },
    EXPORT_TYPE: {
        ALL: 'all',
    },
    SOCKET: {
        EVENTS: {
            JOIN_ORDER: 'JOIN_ORDER',
            LEAVE_ORDER: 'LEAVE_ORDER',
            JOIN_HISTORY: 'JOIN_HISTORY',
            LEAVE_HISTORY: 'LEAVE_HISTORY',
            ASSIGNED_ORDER: 'ASSIGNED_ORDER',
            NEW_MY_ORDER: 'NEW_MY_ORDER',
            NEW_ORDER: 'NEW_ORDER',
            NEW_CUSTOMER_HISTORY: 'NEW_CUSTOMER_HISTORY',
            UPDATED_CUSTOMER_HISTORY: 'UPDATED_CUSTOMER_HISTORY',
            DELETED_CUSTOMER_HISTORY: 'DELETED_CUSTOMER_HISTORY',
            NEW_ORDER_HISTORY: 'NEW_ORDER_HISTORY',
            UPDATED_ORDER_HISTORY: 'UPDATED_ORDER_HISTORY',
            DELETED_ORDER_HISTORY: 'DELETED_ORDER_HISTORY',
            NEW_SHIPPING_HISTORY: 'NEW_SHIPPING_HISTORY',
            UPDATED_SHIPPING_HISTORY: 'UPDATED_SHIPPING_HISTORY',
            DELETED_SHIPPING_HISTORY: 'DELETED_SHIPPING_HISTORY',
        },
        ROOMS: {
            MY_ORDER: 'MY_ORDER',
            ORDER: 'ORDER',
            HISTORY_ORDER: 'HISTORY_ORDER',
            HISTORY_CUSTOMER: 'HISTORY_CUSTOMER',
            HISTORY_SHIPPING: 'HISTORY_SHIPPING',
        },
    },
    ORDER_ROOM: {
        PENDING_ALL: 'PENDING_ALL',
    },
    HISTORY_FORM: {
        ORDER: 'ORDER',
        CUSTOMER: 'CUSTOMER',
        SHIPPING: 'SHIPPING',
    },
    HISTORY_TYPE: {
        COMMENT: 0,
        HISTORY: 1,
        CANCEL_ORDER: 2,
    },
    INVENTORY_UPDATE_MODE: {
        ADD: '1',
        SET: '2',
    },
    ASSIGN_TYPE: {
        BASIC: '1',
        ADVANCED: '2',
    },
    ASSIGN_BASIC_TYPE: {
        DEFAULT: 1, // Chia đều
        CUSTOM: 2, // Chia custom
    },
    ASSIGN_OTHER_STATUS: {
        DEFAULT: {
            CODE: 'DEFAULT',
            NAME: i18n.t('ASSIGN_OTHER_STATUS.DEFAULT'),
        },
        TO_STAFF: {
            CODE: 'TO_STAFF',
            NAME: i18n.t('ASSIGN_OTHER_STATUS.TO_STAFF'),
        },
        IGNORE_STAFF: {
            CODE: 'IGNORE_STAFF',
            NAME: i18n.t('ASSIGN_OTHER_STATUS.IGNORE_STAFF'),
        },
    },
    STAFF_ONLINE_STATUS: {
        ONLINE: 'online',
        OFFLINE: 'offline',
    },
    ASSIGN_STRATEGY: {
        BASIC: 'Basic',
    },
    ASSIGN_RULE: {
        PRODUCT: {
            CODE: 'Pro',
            NAME: i18n.t('ASSIGN_RULE.PRODUCT'),
        },
        // CUSTOMER: {
        //     CODE: 'Cus',
        //     NAME: i18n.t('ASSIGN_RULE.CUSTOMER')
        // },
        PRODUCT_TAG: {
            CODE: 'ProTag',
            NAME: i18n.t('ASSIGN_RULE.PRODUCT_TAG'),
        },
        // CUSTOMER_TAG: {
        //     CODE: 'CusTag',
        //     NAME: i18n.t('ASSIGN_RULE.CUSTOMER_TAG')
        // },
        URL_PAGE: {
            CODE: 'UPage',
            NAME: i18n.t('ASSIGN_RULE.URL_PAGE'),
        },
        UTM_SOURCE: {
            CODE: 'USou',
            NAME: i18n.t('ASSIGN_RULE.UTM_SOURCE'),
        },
        UTM_MEDIUM: {
            CODE: 'UMed',
            NAME: i18n.t('ASSIGN_RULE.UTM_MEDIUM'),
        },
        UTM_CAMPAIGN: {
            CODE: 'UCam',
            NAME: i18n.t('ASSIGN_RULE.UTM_CAMPAIGN'),
        },
        UTM_TERM: {
            CODE: 'UTer',
            NAME: i18n.t('ASSIGN_RULE.UTM_TERM'),
        },
        UTM_CONTENT: {
            CODE: 'UCon',
            NAME: i18n.t('ASSIGN_RULE.UTM_CONTENT'),
        },
        OTHER: {
            CODE: 'Other',
            NAME: i18n.t('ASSIGN_RULE.OTHER'),
        },
        // MAX: {
        //     CODE: 'MAX',
        //     NAME: i18n.t('ASSIGN_RULE.MAX')
        // }
    },
    OTHER_ASSIGN_PRIORITY: 99,
    IP_TYPE: {
        BLACKLIST: 1,
        SPAM: 2,
    },
    VNPOST: {
        PICK_TYPE: {
            PICKUP: {
                CODE: 1,
                NAME: i18n.t('VNPOST.PICK_TYPE.PICKUP'),
            },
            DROPOFF: {
                CODE: 2,
                NAME: i18n.t('VNPOST.PICK_TYPE.DROPOFF'),
            },
        },
    },
    // TEMPLATE_PRINT: {
    //     PRINT_ORDER: {
    //         CODE: 1,
    //         NAME: i18n.t('TEMPLATE.PRINT_ORDER'),
    //     },
    //     PRINT_SHIPPING: {
    //         CODE: 2,
    //         NAME: i18n.t('TEMPLATE.PRINT_SHIPPING'),
    //     },
    // },
    // TEMPLATE_EMAIL: {
    //     EMAIL_ORDER_PRODUCT_PHYSICAL_SUCCESS: {
    //         CODE: 3,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PRODUCT_PHYSICAL_SUCCESS'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_PRODUCT_SERVICE_SUCCESS: {
    //         CODE: 4,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PRODUCT_SERVICE_SUCCESS'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_PRODUCT_EVENT_SUCCESS: {
    //         CODE: 5,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PRODUCT_EVENT_SUCCESS'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_CREATED: {
    //         CODE: 6,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_CREATED'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_COMPLETE_NOTIFICATION: {
    //         CODE: 7,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_COMPLETE_NOTIFICATION'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_SHIPPING: {
    //         CODE: 8,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_SHIPPING'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_PAYMENT_SUCCESS: {
    //         CODE: 9,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PAYMENT_SUCCESS'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_NOTIFICATION_TO_SHOP: {
    //         CODE: 10,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_NOTIFICATION_TO_SHOP'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_ORDER_CANCEL: {
    //         CODE: 11,
    //         NAME: i18n.t('TEMPLATE.EMAIL_ORDER_CANCEL'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_INFO_TICKET_EVENT: {
    //         CODE: 12,
    //         NAME: i18n.t('TEMPLATE.EMAIL_INFO_TICKET_EVENT'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    //     EMAIL_INFO_TICKET_SERVICE: {
    //         CODE: 13,
    //         NAME: i18n.t('TEMPLATE.EMAIL_INFO_TICKET_SERVICE'),
    //         DESCRIPTION: "Được gửi khi khách hàng đặt hàng thành công"
    //     },
    // },
    TEMPLATE_PRINT: [
        {
            CODE: 1,
            NAME: i18n.t('TEMPLATE.PRINT_ORDER'),
            DESCRIPTION: 'Phiếu xuất đơn hàng bao gồm các thông tin về sản phẩm, hóa đơn và các thông tin chi tiết về đơn hàng đó',
        },
        {
            CODE: 2,
            NAME: i18n.t('TEMPLATE.PRINT_SHIPPING'),
            DESCRIPTION:
                'Phiếu giao hàng bao gồm các thông tin về địa chỉ vận chuyển, phí vận chuyển và các thông tin về người nhận và đơn vị vận chuyển.',
        },
    ],
    TEMPLATE_EMAIL_COURSE: [
        {
            CODE: 17,
            NAME: i18n.t('TEMPLATE.EMAIL_COURSE_PURCHASE_SUCCESS'),
            DESCRIPTION: 'Thông báo mua khóa học thành công',
        },
        {
            CODE: 18,
            NAME: i18n.t('TEMPLATE.EMAIL_COURSE_ACCOUNT_DETAIL'),
            DESCRIPTION: 'Thông báo thông tin tài khoản',
        },
        {
            CODE: 19,
            NAME: i18n.t('TEMPLATE.EMAIL_COURSE_FORGOT_PASSWORD'),
            DESCRIPTION: 'Email quên mật khẩu',
        },
        {
            CODE: 20,
            NAME: i18n.t('TEMPLATE.EMAIL_COURSE_RESET_PASSWORD'),
            DESCRIPTION: 'Email thiết lập lại mật khẩu mới',
        },
    ],
    TEMPLATE_EMAIL: [
        {
            CODE: 3,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PRODUCT_PHYSICAL_SUCCESS'),
            DESCRIPTION: 'Gửi khi khách hàng tạo đơn hàng mua sản phẩm vật lý qua trang thanh toán của sản phẩm vật lý.',
        },
        {
            CODE: 4,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PRODUCT_SERVICE_SUCCESS'),
            DESCRIPTION: 'Gửi khi khách hàng tạo đơn hàng dịch vụ, sản phẩm số qua trang thanh toán của sản phẩm dịch vụ.',
        },
        {
            CODE: 5,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PRODUCT_EVENT_SUCCESS'),
            DESCRIPTION: 'Gửi khi khách hàng tạo đơn hàng mua vé sự kiện qua trang thanh toán của sản phẩm sự kiện.',
        },
        {
            CODE: 6,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_CREATED'),
            DESCRIPTION: 'Gửi khi khách hàng đặt hàng thành công.',
        },
        {
            CODE: 7,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_COMPLETE_NOTIFICATION'),
            DESCRIPTION: 'Gửi cho khi khách hàng hoàn tất các bước đặt hàng, và thanh toán.',
        },
        {
            CODE: 8,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_SHIPPING'),
            DESCRIPTION: 'Gửi cho khách hàng khi đơn hàng được xác nhận vận chuyển.',
        },
        {
            CODE: 9,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_PAYMENT_SUCCESS'),
            DESCRIPTION: 'Gửi cho khách hàng khi đơn hàng xác nhận thanh toán thành công.',
        },
        {
            CODE: 11,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_CANCEL'),
            DESCRIPTION: 'Gửi cho khách hàng khi đơn hàng bị huỷ.',
        },
        {
            CODE: 12,
            NAME: i18n.t('TEMPLATE.EMAIL_INFO_TICKET_EVENT'),
            DESCRIPTION: 'Gửi cho khách hàng đặt đơn hàng khi đơn hàng mua sự kiện thanh toán thành công.',
        },
        {
            CODE: 15,
            NAME: i18n.t('TEMPLATE.EMAIL_INFO_TICKET_EVENT_DETAIL'),
            DESCRIPTION: 'Gửi cho khách hàng được điền trong thông tin vé khi đơn hàng mua sự kiện thanh toán thành công',
        },
        {
            CODE: 13,
            NAME: i18n.t('TEMPLATE.EMAIL_INFO_TICKET_SERVICE'),
            DESCRIPTION: 'Gửi cho khách hàng khi đơn hàng mua dịch vụ thanh toán thành công và dịch vụ có tạo mã.',
        },
        {
            CODE: 14,
            NAME: i18n.t('TEMPLATE.EMAIL_INFO_TICKET_DIGITAL'),
            DESCRIPTION: 'Gửi cho khách hàng khi đơn hàng mua sản phẩm số thanh toán thành công và sản phẩm số có tạo mã.',
        },
        {
            CODE: 10,
            NAME: i18n.t('TEMPLATE.EMAIL_ORDER_NOTIFICATION_TO_SHOP'),
            DESCRIPTION: 'Gửi mail cho các email của chủ shop khi có đơn hàng mới.',
        },
        {
            CODE: 16,
            NAME: i18n.t('TEMPLATE.EMAIL_AUTO_ASSIGN_ORDER'),
            DESCRIPTION: 'Gửi cho nhân viên khi nhân viên đó được Assign đơn hàng',
        },

    ],
    PRODUCT_OPTION_TYPE: {
        TEXT: {
            CODE: 1,
            NAME: i18n.t('PRODUCT_OPTION_TYPE.TEXT'),
        },
        VISUAL_SWATCH: {
            CODE: 2,
            NAME: i18n.t('PRODUCT_OPTION_TYPE.VISUAL_SWATCH'),
        },
    },
    PRODUCT_TYPE: {
        PHYSICAL: {
            CODE: 'Physical',
            NAME: i18n.t('PRODUCT_TYPES.PHYSICAL'),
        },
        EVENT: {
            CODE: 'Event',
            NAME: i18n.t('PRODUCT_TYPES.EVENT'),
        },
        SERVICE: {
            CODE: 'Service',
            NAME: i18n.t('PRODUCT_TYPES.SERVICE'),
        },
        F_B: {
            CODE: 'F_B',
            NAME: i18n.t('PRODUCT_TYPES.F_B'),
        },
        COMBO: {
            CODE: 'COMBO',
            NAME: i18n.t('PRODUCT_TYPES.COMBO'),
        },
        DIGITAL: {
            CODE: 'Digital',
            NAME: 'Sản phẩm số',
        },
        COURSE: {
            CODE: 'Course',
            NAME: 'Khóa học',
        },
    },
    PRODUCT_TAG: {
        TAG: {
            CODE: 'Tag',
            NAME: i18n.t('PRODUCT_TYPES.TAG'),
        },
    },
    PRODUCT_STATUS: {
        ACTIVE: {
            CODE: 'Active',
            NAME: i18n.t('PRODUCT_STATUS.ACTIVE'),
        },
        INACTIVE: {
            CODE: 'Inactive',
            NAME: i18n.t('PRODUCT_STATUS.INACTIVE'),
        },
    },
    VISUAL_SWATCH_TYPE: {
        COLOR: {
            CODE: 1,
        },
        IMAGE: {
            CODE: 2,
        },
    },
    CHECKOUT: {
        EMAIL_STATUS: {
            NOT_SEND: {
                CODE: 0,
                NAME: i18n.t('CHECKOUTS.EMAIL_STATUS.NOT_SEND'),
            },
            SENT: {
                CODE: 1,
                NAME: i18n.t('CHECKOUTS.EMAIL_STATUS.SENT'),
            },
            ALL: {
                CODE: 3,
                NAME: i18n.t('CHECKOUTS.EMAIL_STATUS.ALL'),
            },
        },
        ORDER_STATUS: {
            PENDING: {
                CODE: 0,
                NAME: i18n.t('CHECKOUTS.ORDER_STATUS.PENDING'),
            },
            DONE: {
                CODE: 1,
                NAME: i18n.t('CHECKOUTS.ORDER_STATUS.DONE'),
            },
        },
        STATUS: {
            PENDING: {
                CODE: 'Pending',
                NAME: i18n.t('CHECKOUTS.STATUS.PENDING'),
            },
            ORDERED: {
                CODE: 'Ordered',
                NAME: i18n.t('CHECKOUTS.STATUS.ORDERED'),
            },
            PAID: {
                CODE: 'Paid',
                NAME: i18n.t('CHECKOUTS.STATUS.DONE'),
            },
        },
    },
    TICKET_STATUS: {
        NORMAL: {
            CODE: 'Normal',
            NAME: i18n.t('TICKET_STATUS.NORMAL'),
        },
        LOCKED: {
            CODE: 'Locked',
            NAME: i18n.t('TICKET_STATUS.LOCKED'),
        },
    },
    ZALO_PAY: {
        HMAC_ALGORITHM: {
            HMACMD5: {
                CODE: 'HMACMD5',
                NAME: i18n.t('PAYMENTS.ZALO_PAY.HMAC_ALGORITHM.HMACMD5'),
            },
            HMACSHA1: {
                CODE: 'HMACSHA1',
                NAME: i18n.t('PAYMENTS.ZALO_PAY.HMAC_ALGORITHM.HMACSHA1'),
            },
            HMACSHA256: {
                CODE: 'HMACSHA256',
                NAME: i18n.t('PAYMENTS.ZALO_PAY.HMAC_ALGORITHM.HMACSHA256'),
            },
            HMACSHA384: {
                CODE: 'HMACSHA384',
                NAME: i18n.t('PAYMENTS.ZALO_PAY.HMAC_ALGORITHM.HMACSHA384'),
            },
            HMACSHA512: {
                CODE: 'HMACSHA512',
                NAME: i18n.t('PAYMENTS.ZALO_PAY.HMAC_ALGORITHM.HMACSHA512'),
            },
        },
    },
    PAYMENT_PARTNERS: {
        COD: {
            CODE: 'COD',
            NAME: i18n.t('PAYMENT_PARTNERS.COD'),
        },
        BANK: {
            CODE: 'BANK',
            NAME: i18n.t('PAYMENT_PARTNERS.BANK'),
        },
        ZALO_PAY: {
            CODE: 'ZALO_PAY',
            NAME: i18n.t('PAYMENT_PARTNERS.ZALO_PAY'),
        },
        VN_PAY: {
            CODE: 'VN_PAY',
            NAME: i18n.t('PAYMENT_PARTNERS.VN_PAY'),
        },
        MOMO: {
            CODE: 'MOMO',
            NAME: i18n.t('PAYMENT_PARTNERS.MOMO'),
        },
        ALE_PAY: {
            CODE: 'ALE_PAY',
            NAME: i18n.t('PAYMENT_PARTNERS.ALE_PAY'),
        },
        VNPT_EPAY: {
            CODE: 'VNPT_EPAY',
            NAME: i18n.t('PAYMENT_PARTNERS.VNPT_EPAY'),
        },
        SHOPEE_PAY: {
            CODE: 'SHOPEE_PAY',
            NAME: i18n.t('PAYMENT_PARTNERS.SHOPEE_PAY'),
        },
        PAYPAL: {
            CODE: 'PAYPAL',
            NAME: i18n.t('PAYMENT_PARTNERS.PAYPAL'),
        },
        STRIPE: {
            CODE: 'STRIPE',
            NAME: i18n.t('PAYMENT_PARTNERS.STRIPE'),
        },
        APPOTA: {
            CODE: 'APPOTA',
            NAME: i18n.t('PAYMENT_PARTNERS.APPOTA'),
        },
        FINAN: {
            CODE: 'FINAN',
            NAME: i18n.t('PAYMENT_PARTNERS.FINAN'),
        },
    },
    // PACKAGE_QUANTITY_UNIT: ["Ngày", "Tháng", "Quý", "Năm", "Lần"],
    API_CONTENT_TYPE: {
        APPLICATION_JSON: {
            CODE: 'APPLICATION_JSON',
            NAME: 'application/json',
        },
        URL_ENCODED: {
            CODE: 'URL_ENCODED',
            NAME: 'x-www-form-urlencoded',
        },
        MULTIPART_FORM_DATA: {
            CODE: 'MULTIPART_FORM_DATA',
            NAME: 'multipart/form-data',
        },
    },
    GOOGLE_SHEET_URL: 'https://docs.google.com/spreadsheets/d/{{sheet_id}}/edit?gid={{work_sheet_id}}',
    WEB_HOOK_TYPE: {
        GOOGLE_SHEET: {
            CODE: 'GOOGLE_SHEET',
            NAME: i18n.t('WEB_HOOK.GOOGLE_SHEET'),
        },
        API: {
            CODE: 'API',
            NAME: i18n.t('WEB_HOOK.LB_API_URL'),
        },
    },
    VIETTEL_POST_ORDER_PAYMENT: {
        1: {
            CODE: 1,
            NAME: i18n.t('SHIPPINGS.NO_CHARGES'),
        },
        2: {
            CODE: 2,
            NAME: i18n.t('SHIPPINGS.COLLECT_EXPRESS_FEE_AND_PRICE_OF_GOODS'),
        },
        3: {
            CODE: 3,
            NAME: i18n.t('SHIPPINGS.COLLECT_PRICE_OF_GOODS'),
        },
        4: {
            CODE: 4,
            NAME: i18n.t('SHIPPINGS.COLLECT_EXPRESS_FEE'),
        },
    },
    LIST_PARTNER_ACTION: {
        SHIPPING: 'SHIPPING',
        SETTING: 'SETTING',
    },
    PAYMENT_ACTION_TYPE: {
        USER: 'User',
        PARTNER: 'Payment',
    },
    PAYMENT_EXPIRED_TIME_VA_DEFAULT: 10,
    FORM_TYPE: {
        GOOGLE_SHEET: {
            CODE: 'GOOGLE_SHEET',
            NAME: 'Google Sheet',
            DESCRIPTION: 'Lưu thông tin khách hàng từ LadiSales về Google Sheets.',
        },
        CONVERTKIT: {
            CODE: 'CONVERTKIT',
            NAME: 'Convertkit',
            DESCRIPTION: 'Lưu thông tin khách hàng từ LadiSales về ConvertKit.',
        },
        GET_RESPONSE: {
            CODE: 'GET_RESPONSE',
            NAME: 'Get Response',
            DESCRIPTION: 'Lưu thông tin khách hàng từ LadiSales về GetResponse.',
        },
        HUBSPOT: {
            CODE: 'HUBSPOT',
            NAME: 'Hubspot',
            DESCRIPTION: 'Lưu thông tin khách hàng từ LadiSales về HubSpot.',
        },
        ACTIVE_CAMPAIGN: {
            CODE: 'ACTIVE_CAMPAIGN',
            NAME: 'Active Campaign',
            DESCRIPTION: 'Lưu thông tin khách hàng từ LadiSales về Active Campaign.',
        },
        INFUSION_SOFT_LADI: {
            CODE: 'INFUSION_SOFT_LADI',
            NAME: 'Infusion Soft',
            DESCRIPTION: 'Lưu thông tin khách hàng từ LadiSales về InfusionSoft.',
        },
        MAIL_CHIMP: {
            CODE: 'MAIL_CHIMP',
            NAME: 'Mail Chimp',
            DESCRIPTION: 'Lưu thông tin khách hàng từ LadiSales về MailChimp.',
        },
        HARAVAN: {
            CODE: 'HARAVAN',
            NAME: 'Haravan',
            DESCRIPTION: 'Đồng bộ đơn hàng từ nền tảng Haravan về LadiSales.',
        },
        SAPO: {
            CODE: 'SAPO',
            NAME: 'Sapo',
            DESCRIPTION: 'Đồng bộ đơn hàng từ nền tảng Sapo về LadiSales.',
        },
        SHOPIFY: {
            CODE: 'SHOPIFY',
            NAME: 'Shopify',
            DESCRIPTION: 'Đồng bộ đơn hàng từ nền tảng Shopify về LadiSales.',
        },
        WORDPRESS: {
            CODE: 'WORDPRESS',
            NAME: 'Wordpress',
            DESCRIPTION: 'Đồng bộ đơn hàng từ nền tảng Wordpress về LadiSales.    ',
        },
        KIOTVIET: {
            CODE: 'KIOTVIET',
            NAME: 'KiotViet',
            DESCRIPTION: 'Đồng bộ đơn hàng từ nền tảng KiotViet về LadiSales.',
        },
        NHANH: {
            CODE: 'NHANH',
            NAME: 'Nhanh.vn',
            DESCRIPTION: 'Đồng bộ đơn hàng từ nền tảng Nhanh.vn về LadiSales.',
        },
    },
    DISPLAY_TYPE: {
        DEFAULT: {
            CODE: 0,
            NAME: 'Mặc định',
        },
        BUNDLE: {
            CODE: 1,
            NAME: 'Bundle',
        },
    },
    LIMIT_LOAD_FORM_ACCOUNT: 100,
    TICKET_CREATION_FORM: {
        AUTO: {
            CODE: 1,
            NAME: i18n.t('TICKET_CREATION_FORM.AUTO'),
        },
        IMPORT: {
            CODE: 2,
            NAME: i18n.t('TICKET_CREATION_FORM.IMPORT'),
        },
    },
    SERVICE_TICKET_CREATION_FORM: {
        AUTO: {
            CODE: 1,
            NAME: i18n.t('TICKET_CREATION_FORM.AUTO'),
        },
        IMPORT: {
            CODE: 2,
            NAME: i18n.t('TICKET_CREATION_FORM.IMPORT'),
        },
        DEFAULT: {
            CODE: 0,
            NAME: 'Không tạo mã',
        },
    },
    PAYMENT_METHODS: {
        // BANK: {
        //   TYPE: {
        //     BK: {
        //       CODE: "BK",
        //       NAME: i18n.t("PAYMENT_METHODS.SN_BANK"),
        //     }
        //   }
        // },
        // COD: {
        //   TYPE: {
        //     COD: {
        //       CODE: "COD",
        //       NAME: i18n.t("PAYMENT_METHODS.SN_CODE"),
        //     }
        //   }
        // },
        VNPT_EPAY: {
            TYPE: {
                QR_CODE_ZALO_PAY: {
                    CODE: 'ZALO',
                    NAME: i18n.t('PAYMENT_METHODS.QR_CODE_ZALO_PAY'),
                },
                QR_CODE_MOMO: {
                    CODE: 'MOMO',
                    NAME: i18n.t('PAYMENT_METHODS.QR_CODE_MOMO'),
                },
                QR_CODE_MOCA: {
                    CODE: 'MOCA',
                    NAME: i18n.t('PAYMENT_METHODS.QR_CODE_MOCA'),
                },
                ATM: {
                    CODE: 'DC',
                    NAME: i18n.t('PAYMENT_METHODS.BANK'),
                },
                CREDIT: {
                    CODE: 'IC',
                    NAME: i18n.t('PAYMENT_METHODS.CREDIT'),
                },
                VA: {
                    CODE: 'VA',
                    NAME: i18n.t('PAYMENT_METHODS.VA'),
                },
                SHOPEE_PAY: {
                    CODE: 'SHPP',
                    NAME: i18n.t('PAYMENT_METHODS.QR_CODE_SHPP'),
                },
                VIETEL_PAY: {
                    CODE: 'VTTP',
                    NAME: i18n.t('PAYMENT_METHODS.QR_CODE_VTTP'),
                },
            },
        },
        APPOTA: {
            TYPE: {
                ATM: {
                    CODE: 'ATM',
                    NAME: 'Thanh toán qua iBanking/thẻ ATM',
                },
                CC: {
                    CODE: 'CC',
                    NAME: 'Thanh toán qua thẻ Visa/Master Card/JCB',
                },
                EWALLET: {
                    CODE: 'EWALLET',
                    NAME: 'Thanh toán qua ví điện tử',
                },
                VA: {
                    CODE: 'VA',
                    NAME: 'Thanh toán chuyển khoản qua tài khoản chuyên thu(VA)',
                },
                MM: {
                    CODE: 'MM',
                    NAME: 'Mobile Money',
                },
                // BNPL: {
                //   CODE: "BNPL",
                //   NAME: "Thanh toán mua trước trả sau",
                // },
                QR_CODE: {
                    CODE: 'QRCODE',
                    NAME: 'Thanh toán qua QR Code động',
                },
            },
        },
        ZALO_PAY: {
            TYPE: {
                APP: {
                    CODE: 'zalopayapp',
                    NAME: i18n.t('PAYMENT_METHODS.APP'),
                },
                BANK: {
                    CODE: 'bank_code',
                    NAME: i18n.t('PAYMENT_METHODS.BANK'),
                },
                CREDIT: {
                    CODE: 'cc',
                    NAME: i18n.t('PAYMENT_METHODS.CREDIT'),
                },
            },
        },
        VN_PAY: {
            TYPE: {
                QR_CODE: {
                    CODE: 'VNPAYQR',
                    NAME: i18n.t('PAYMENT_METHODS.QR_CODE'),
                },
                BANK: {
                    CODE: 'bank_code',
                    NAME: i18n.t('PAYMENT_METHODS.BANK'),
                },
                CREDIT: {
                    CODE: 'INTCARD',
                    NAME: i18n.t('PAYMENT_METHODS.CREDIT'),
                },
            },
        },
        ALE_PAY: {
            TYPE: {
                // QR_CODE: {
                //     CODE: 'QR_CODE',
                //     NAME: i18n.t('PAYMENT_METHODS.QR_CODE'),
                // },
                VA: {
                    CODE: 'VA',
                    NAME: i18n.t('PAYMENT_METHODS.ALE_PAY_VA'),
                },
                VIET_QR: {
                    CODE: 'VIET_QR',
                    NAME: i18n.t('PAYMENT_METHODS.VIET_QR'),
                },
                ATM: {
                    CODE: 'ATM',
                    NAME: i18n.t('PAYMENT_METHODS.BANK'),
                },
                CREDIT: {
                    CODE: 'VISA',
                    NAME: i18n.t('PAYMENT_METHODS.CREDIT'),
                },
                INSTALLMENT: {
                    CODE: 'INSTALLMENT',
                    NAME: i18n.t('PAYMENT_METHODS.INSTALLMENT'),
                },
            },
        },
    },
    SN_PAYMENT_METHODS: [
        {
            CODE: 'BK',
            NAME: i18n.t('PAYMENT_METHODS.SN_BANK'),
        },
        {
            CODE: 'COD',
            NAME: i18n.t('PAYMENT_METHODS.SN_COD'),
        },
        {
            CODE: 'ZALO',
            NAME: i18n.t('PAYMENT_METHODS.SN_ZALO'),
        },
        {
            CODE: 'MOMO',
            NAME: i18n.t('PAYMENT_METHODS.SN_MOMO'),
        },
        {
            CODE: 'MOCA',
            NAME: i18n.t('PAYMENT_METHODS.SN_MOCA'),
        },
        {
            CODE: 'DC',
            NAME: i18n.t('PAYMENT_METHODS.SN_DC'),
        },
        {
            CODE: 'IC',
            NAME: i18n.t('PAYMENT_METHODS.SN_IC'),
        },
        {
            CODE: 'VA',
            NAME: i18n.t('PAYMENT_METHODS.SN_VA'),
        },
        {
            CODE: 'zalopayapp',
            NAME: i18n.t('PAYMENT_METHODS.SN_ZALOPAY_APP'),
        },
        {
            CODE: 'bank_code',
            NAME: i18n.t('PAYMENT_METHODS.SN_BANK'),
        },
        {
            CODE: 'cc',
            NAME: i18n.t('PAYMENT_METHODS.SN_CC'),
        },
        {
            CODE: 'VNPAYQR',
            NAME: i18n.t('PAYMENT_METHODS.SN_VNPAYQR'),
        },
        {
            CODE: 'bank_code',
            NAME: i18n.t('PAYMENT_METHODS.SN_BANK_CODE'),
        },
        {
            CODE: 'INTCARD',
            NAME: i18n.t('PAYMENT_METHODS.SN_INCART'),
        },
        {
            CODE: 'QR_CODE',
            NAME: i18n.t('PAYMENT_METHODS.SN_QR_CODE'),
        },
        {
            CODE: 'ATM',
            NAME: i18n.t('PAYMENT_METHODS.SN_ATM'),
        },
        {
            CODE: 'VISA',
            NAME: i18n.t('PAYMENT_METHODS.SN_VISA'),
        },
        {
            CODE: 'INSTALLMENT',
            NAME: i18n.t('PAYMENT_METHODS.SN_INSTALLMENT'),
        },
        {
            CODE: 'SPP',
            NAME: i18n.t('PAYMENT_METHODS.SN_SPP'),
        },
        {
            CODE: 'PAYPAL',
            NAME: i18n.t('PAYMENT_METHODS.PAYPAL'),
        },
        {
            CODE: 'VIET_QR',
            NAME: i18n.t('PAYMENT_METHODS.VIET_QR'),
        },
    ],

    SN_PAYMENT_APPOTA_METHODS: [
        {
            CODE: 'ATM',
            NAME: 'Thanh toán qua iBanking/thẻ ATM',
        },
        {
            CODE: 'CC',
            NAME: 'Thanh toán qua thẻ Visa/Master Card/JCB',
        },
        {
            CODE: 'EWALLET',
            NAME: 'Thanh toán qua ví điện tử',
        },
        {
            CODE: 'VA',
            NAME: 'Thanh toán chuyển khoản qua tài khoản chuyên thu(VA)',
        },
        {
            CODE: 'MM',
            NAME: 'Mobile Money',
        },
        {
            CODE: 'QRCODE',
            NAME: 'Thanh toán qua QR Code động',
        },
    ],

    TICKET_SEAT_STATUS: {
        ACTIVE: 1,
        INACTIVE: 0,
        CANCELED: 2,
    },
    CUSTOM_FIELD: {
        DATA_TYPES: {
            NUMBER: {
                CODE: 'NUMBER',
                NAME: i18n.t('CUSTOM_FIELD.NUMBER'),
            },
            DATE: {
                CODE: 'DATE',
                NAME: i18n.t('CUSTOM_FIELD.DATE'),
            },
            INPUT_TEXT: {
                CODE: 'INPUT_TEXT',
                NAME: i18n.t('CUSTOM_FIELD.INPUT_TEXT'),
            },
            TEXT_AREA: {
                CODE: 'TEXT_AREA',
                NAME: i18n.t('CUSTOM_FIELD.TEXT_AREA'),
            },
            DROPDOWN: {
                CODE: 'DROPDOWN',
                NAME: i18n.t('CUSTOM_FIELD.DROPDOWN'),
            },
        },
        GROUP_TYPES: {
            CUSTOMER: {
                CODE: 'CUSTOMER',
                NAME: i18n.t('CUSTOM_FIELD.CUSTOMER'),
            },
            ORDER: {
                CODE: 'ORDER',
                NAME: i18n.t('CUSTOM_FIELD.ORDER'),
            },
            PRODUCT: {
                CODE: 'PRODUCT',
                NAME: i18n.t('CUSTOM_FIELD.PRODUCT'),
            },
        },
    },
    PAGE_CHECKOUT: {
        PRODUCTS: {
            CODE: 'product',
            NAME: i18n.t('PAGE_CHECKOUT.LIST_PRODUCT'),
        },
        TAGS: {
            CODE: 'tag',
            NAME: i18n.t('PAGE_CHECKOUT.LIST_TAG'),
        },
        EMBED: {
            CODE: 'embed',
            NAME: 'Embed',
        },
        SINGLE_PRODUCT: {
            CODE: 'single_product',
            NAME: 'Trang thanh toán đơn',
        },
        STORE: {
            CODE: 'Store',
            NAME: 'Cửa hàng',
        },
    },
    PAGE_CHECKOUT_NAME: {
        product: i18n.t('PAGE_CHECKOUT.LIST_PRODUCT'),
        tag: i18n.t('PAGE_CHECKOUT.LIST_TAG'),
    },
    MANIPULATION_ORDER: {
        RESET: {
            CODE: 'RESET',
            NAME: i18n.t('ORDERS.REORDER'),
        },
        CANCEL_RESET: {
            CODE: 'CANCEL_RESET',
            NAME: i18n.t('ORDERS.CANCEL_AND_REORDER'),
        },
    },
    PRODUCT_TYPE_CONFIG: {
        PHYSICAL: {
            CODE: 'Physical',
            NAME: 'Mặc định',
        },
        F_B: {
            CODE: 'F_B',
            NAME: 'F&B',
        },
        COMBO: {
            CODE: 'COMBO',
            NAME: 'Combo',
        },
    },

    TAG_SELECTED: {
        TAG_PRODUCT: {
            CODE: 'PRODUCT',
            NAME: 'Sản phẩm',
        },
        TAG_CUSTOMER: {
            CODE: 'CUSTOMER',
            NAME: 'Khách hàng',
        },
        TAG_ORDER: {
            CODE: 'ORDER',
            NAME: 'Đơn hàng',
        },
    },
    DISCOUNT_LIST_STATUS: [
        {
            name: i18n.t('DISCOUNTS.STATUS.ACTIVE'),
            value: 'Active',
        },
        {
            name: i18n.t('DISCOUNTS.STATUS.EXPIRED'),
            value: 'Expired',
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: '',
        },
    ],
    DISCOUNT_LIST_USE_STATUS: [
        {
            name: i18n.t('DISCOUNTS.USED_STATUS.USED'),
            value: 1,
        },
        {
            name: i18n.t('DISCOUNTS.USED_STATUS.NOT_USED'),
            value: 0,
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: '',
        },
    ],
    LIST_RULE_TYPE: [
        {
            name: i18n.t('DISCOUNTS.RULE_TYPE.CODE'),
            value: 1,
        },
        {
            name: i18n.t('DISCOUNTS.RULE_TYPE.PROMOTION'),
            value: 2,
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: '',
        },
    ],
    UPLOAD_FILE: {
        MAX_SIZE: {
            VIDEO: 24117248,
            IMAGE: 5242880,
            DOCUMENT: 24117248,
        },
        NOT_ACCEPT: ['bat', 'exe'],
        IMAGE: ['jpeg', 'gif', 'svg'],
        VIDEO: ['mp4', 'avi', 'wmv', '3gp', 'flv'],
        FILE: ['csv', 'txt', 'pdf', 'doc', 'docx', 'xlsx'],
        AUDIO: ['mp3'],
        TYPE: {
            IMAGE: 'IMAGE',
            VIDEO: 'VIDEO',
            AUDIO: 'AUDIO',
            DOCUMENT: 'DOCUMENT',
        },
        METHOD: {
            DESKTOP: 'DESKTOP',
            URL_OTHER: 'URL_OTHER',
        },
    },
    LIST_CTA: [
        {
            CLASS_ICON: 'cta-add',
            NAME: 'Chọn mua',
            CODE: 0,
        },
        {
            CLASS_ICON: 'cta-cart',
            NAME: 'Đặt hàng',
            CODE: 1,
        },
        {
            CLASS_ICON: 'cta-cart',
            NAME: 'Mua vé',
            CODE: 2,
        },
        {
            CLASS_ICON: 'cta-calendar',
            NAME: 'Đặt lịch hẹn',
            CODE: 3,
        },
        {
            CLASS_ICON: 'cta-add-member',
            NAME: 'Đăng ký',
            CODE: 4,
        },
        {
            CLASS_ICON: 'cta-download',
            NAME: 'Tải xuống',
            CODE: 5,
        },
        {
            CLASS_ICON: 'cta-discount-coupon',
            NAME: 'Nhận ưu đãi',
            CODE: 6,
        },
        {
            CLASS_ICON: 'cta-tag',
            NAME: 'Nhận báo giá',
            CODE: 7,
        },
    ],
    LIST_COLOR_PICKER: ['#1C00C2', '#7B25E9', '#E3863C', '#CD472F', '#739931', '#925F25', '#4D4D4D', '#30A16F', '#002333'],
    LOCATIONS: [
        {
            value: 0,
            name: 'Vị trí top',
        },
        {
            value: 1,
            name: 'Vị trí navbar tab cửa hàng',
        },
        {
            value: 4,
            name: 'Vị trí navbar tab khách hàng',
        },
        {
            value: 5,
            name: 'Vị trí navbar tab vận chuyển',
        },
        {
            value: 6,
            name: 'Vị trí navbar tab thanh toán',
        },
        {
            value: 2,
            name: 'Vị trí bottom',
        },
        {
            value: 3,
            name: 'Vị trí trên cùng giỏ hàng',
        },
    ],
    LOCATIONS_CHECKIN: [
        {
            value: 7,
            name: 'Vị trí trên cùng giỏ hàng',
        },
        {
            value: 8,
            name: 'Vị trí dưới cùng giỏ hàng',
        },
    ],
    SCREEN_PAGE_CHECKOUT_SDK: [
        {
            code: 'CART',
            name: 'Giỏ hàng có sản phẩm',
        },
        {
            code: 'CART_EMPTY',
            name: 'Giỏ hàng trống',
        },
        {
            code: 'PAYMENT_PAGE',
            name: 'Trang thanh toán',
        },
        {
            code: 'BUTTON_BUY_NOW',
            name: 'Nút mua',
        },
        {
            code: 'BUTTON_ADD_TO_CART',
            name: 'Nút thêm vào giỏ hàng',
        },
    ],
    LIST_FONT_FAMILIES: [
        { name: 'Roboto', value: '"Roboto", sans-serif' },
        { name: 'Open Sans', value: '"Open Sans", sans-serif' },
        { name: 'Source Sans Pro', value: '"Source Sans Pro", sans-serif' },
        { name: 'Montserrat', value: '"Montserrat", sans-serif' },
        { name: 'Quicksand', value: '"Quicksand", sans-serif' },
        { name: 'Roboto Slab', value: '"Roboto Slab", serif' },
        { name: 'Tinos', value: '"Tinos", serif' },
        { name: 'Oswald', value: '"Oswald", sans-serif' },
        { name: 'Muli', value: '"Muli", sans-serif' },
        { name: 'Sriracha', value: '"Sriracha", cursive' },
        { name: 'Philosopher', value: '"Philosopher", sans-serif' },
        { name: 'Niramit', value: '"Niramit", sans-serif' },
        { name: 'Paytone One', value: '"Paytone One", sans-serif' },
        { name: 'Jura', value: '"Jura", sans-serif' },
        { name: 'Judson', value: '"Judson", serif' },
        { name: 'Yeseva One', value: '"Yeseva One", cursive' },
        { name: 'Baloo Bhaina', value: '"Baloo Bhaina", cursive' },
        { name: 'Taviraj', value: '"Taviraj", serif' },
        { name: 'Prata', value: '"Prata", serif' },
        { name: 'Trirong', value: '"Trirong", serif' },
        { name: 'Lobster', value: '"Lobster", cursive' },
        { name: 'Itim', value: '"Itim", cursive' },
        { name: 'Chonburi', value: '"Chonburi", cursive' },
        { name: 'Arima Madurai', value: '"Arima Madurai", cursive' },
        { name: 'Bungee', value: '"Bungee", cursive' },
        { name: 'Bungee Inline', value: '"Bungee Inline", cursive' },
        { name: 'Bungee Outline', value: '"Bungee Outline", cursive' },
        { name: 'Bungee Shade', value: '"Bungee Shade", cursive' },
        { name: 'Cormorant Upright', value: '"Cormorant Upright", serif' },
        { name: 'Athiti', value: '"Athiti", sans-serif' },
        { name: 'Pattaya', value: '"Pattaya", sans-serif' },
        { name: 'Merriweather', value: '"Merriweather", serif' },
        { name: 'Hepta Slab', value: '"Hepta Slab", serif' },
        { name: 'Crimson Pro', value: '"Crimson Pro", serif' },
        { name: 'Playfair Display', value: '"Playfair Display", serif' },
        { name: 'Noto Serif', value: '"Noto Serif", serif' },
        { name: 'Dosis', value: '"Dosis", sans-serif' },
        { name: 'Anton', value: '"Anton", sans-serif' },
        { name: 'Josefin Sans', value: '"Josefin Sans", sans-serif' },
        { name: 'Dancing Script', value: '"Dancing Script", cursive' },
        { name: 'Exo', value: '"Exo", sans-serif' },
        { name: 'EB Garamond', value: '"EB Garamond", serif' },
        { name: 'Play', value: '"Play", sans-serif' },
        { name: 'Maven Pro', value: '"Maven Pro", sans-serif' },
        { name: 'Barlow Condensed', value: '"Barlow Condensed", sans-serif' },
        { name: 'Alfa Slab One', value: '"Alfa Slab One", cursive' },
        { name: 'Rokkitt', value: '"Rokkitt", serif' },
        { name: 'Bahianita', value: '"Bahianita", cursive' },
        { name: 'VT323', value: '"VT323", monospace' },
        { name: 'Literata', value: '"Literata", serif' },
        { name: 'K2D', value: '"K2D", sans-serif' },
        { name: 'Thasadith', value: '"Thasadith", sans-serif' },
    ],
    EVENT_TYPE: {
        ONLINE: 'Online',
        OFFLINE: 'Offline',
    },
    LIST_CHANELS: [
        {
            key: 1,
            name: 'Messenger',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-messenger.svg',
            placeholder: 'https://m.me/fanpage_id',
        },
        {
            key: 2,
            name: 'Facebook',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-facebook.svg',
            placeholder: 'https://example.com',
        },
        {
            key: 3,
            name: 'Zalo',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-zalo.svg',
            placeholder: 'https://zalo.me/phone-number',
        },
        {
            key: 4,
            name: 'Instagram',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-instagram.svg',
            placeholder: 'https://www.instagram.com/user_id',
        },
        {
            key: 5,
            name: 'Gmail',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-gmail.svg',
            placeholder: 'support@yourstore.com',
        },
        {
            key: 6,
            name: 'Telegram',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-telegram.svg',
            placeholder: 'https://t.me/user_name',
        },
        {
            key: 7,
            name: 'Skype',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-logo-color-skype.svg',
            placeholder: 'skype://user-name',
        },
        {
            key: 8,
            name: 'Phone',
            image_url: 'https://w.ladicdn.com/ladiui/icons/ldicon-colored-call.svg',
            placeholder: '0321715486',
        },
    ],
    LIST_COMMUNICATION: {
        YOUTUBE: {
            type: 'Youtube',
            name: 'Youtube',
        },
        // {
        //   type: "VIDEO",
        //   name: "Video",
        // },
        VIMEO: {
            type: 'Vimeo',
            name: 'Vimeo',
        },
        LADIVIDEO_STREAM: {
            type: 'LadiVideoStream',
            name: 'Video riêng',
        },
    },
    DOMAIN_TYPE: [
        {
            CODE: 'PAGE_CHECKOUT_PRODUCT',
            NAME: 'Trang thanh toán và sản phẩm',
        },
        {
            CODE: 'PAGE_STORE',
            NAME: 'Trang cửa hàng',
        },
        {
            CODE: 'PAGE_COURSE',
            NAME: 'Trang khóa học',
        },
    ],
    LIST_STATUS_VERIFY: [
        {
            name: 'Chưa xác minh',
            value: 0,
        },
        {
            name: 'Đã xác minh',
            value: 1,
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: null,
        },
    ],
    LIST_STATUS_PAID: [
        {
            name: 'Miễn phí',
            value: 'NOT_PAID',
        },
        {
            name: 'Trả phí',
            value: 'PAID',
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: 'ALL',
        },
    ],
    LIST_STATUS_ACTIVE: [
        {
            name: 'Đang hoạt động',
            value: 0,
        },
        {
            name: 'Đã vô hiệu',
            value: 1,
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: null,
        },
    ],
    LIST_STATUS_LEARN: [
        {
            name: 'Hoàn thành',
            value: 'COMPLETED',
        },
        {
            name: 'Đang học',
            value: 'NOT_COMPLETED',
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: 'ALL',
        },
    ],
    LIST_STATUS_LOCK: [
        {
            name: 'Kích hoạt',
            value: 0,
        },
        {
            name: 'Vô hiệu',
            value: 1,
        },
        {
            name: i18n.t('COMMON.ALL'),
            value: null,
        },
    ],
    WHITE_LIST_EMAIL: [
        'demo@ladipage.com',
        'codebaseplus@gmail.com',
        'asktinh@ladipage.vn',
        'info@nguyendactinh.vn',
        'nanapham0412@gmail.com',
        'content@ladipage.vn',
        'thanhbinh101287@gmail.com',
        'trungdoan610@gmail.com',
        'doanvanphuc.it@gmail.com',
        'tientruong0804@gmail.com',
        'marketing@ladipage.vn',
        'hotro@ladipage.vn',
        'duyennapa1998@gmail.com',
        'nguyenthanhhue261098@gmail.com',
        'mohoang10041996@gmail.com',
        'hantran1202@gmail.com',
        'hiennguyen120997@gmail.com',
        'hantt201096@gmail.com',
        'anhtranquynh.hanu@gmail.com',
        'linhpd110197@gmail.com',
        'nguyenthituyetmm2111@gmail.com',
        'hhoang.nov.13@gmail.com',
        'hoanghamho99@gmail.com',
        'high5hanoi@gmail.com',
        'baphuc.chu@gmail.com',
        'maintn2202@gmail.com',
        'dungnguyen23051998@gmail.com',
        'phamduyphuc92@gmail.com',
        'ladisales_standard_lifetime@gmail.com',
        'booksandlife16@gmail.com',
    ],
    LIST_BANK_AUTO_PAYMENT: ['ACB', 'Sacombank'],
    LIST_BANK_CAN_CONNECT_API: ['MB'],
    LIST_DISCOUNT_TYPE: [
        {
            name: 'Giảm giá đơn hàng',
            value: 'ORDER',
        },
        {
            name: 'Giảm giá vận chuyển',
            value: 'SHIPPING',
        },
    ],

    CONDITION_TYPE: {
        SELECTED: 'SELECTED',
        INPUT: 'INPUT',
        MULTISELECT: 'MULTISELECT',
        MULTISELECT_SEARCH: 'MULTISELECT-SEARCH',
    },
    ORRDER_CONDITION_FILEDS: [
        {
            value: 'status',
            name: i18n.t('ORDERS.ORDER_STATUS'),
            type: 'SELECTED',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [
                {
                    name: i18n.t('ORDER_STATUS.OPEN'),
                    value: 'Open',
                },
                {
                    name: i18n.t('ORDER_STATUS.CANCELED'),
                    value: 'Canceled',
                },
            ],
        },
        {
            value: 'payment_status',
            name: i18n.t('ORDERS.PAYMENT_STATUS'),
            type: 'MULTISELECT',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [
                {
                    value: 'Success',
                    name: i18n.t('PAYMENT_STATUS.SUCCESS'),
                },
                {
                    value: 'Pending',
                    name: i18n.t('PAYMENT_STATUS.PENDING'),
                },
            ],
        },
        {
            value: 'payment_method',
            name: i18n.t('ORDERS.CONDITION_TITLE.PAYMENT_METHOD'),
            type: 'MULTISELECT',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [
                {
                    value: 'COD',
                    name: i18n.t('PAYMENT_PARTNERS.COD'),
                },
                {
                    value: 'BANK',
                    name: i18n.t('PAYMENT_PARTNERS.BANK'),
                },
                {
                    value: 'ZALO_PAY',
                    name: i18n.t('PAYMENT_PARTNERS.ZALO_PAY'),
                },
                {
                    value: 'VN_PAY',
                    name: i18n.t('PAYMENT_PARTNERS.VN_PAY'),
                },
                {
                    value: 'MOMO',
                    name: i18n.t('PAYMENT_PARTNERS.MOMO'),
                },
                {
                    value: 'ALE_PAY',
                    name: i18n.t('PAYMENT_PARTNERS.ALE_PAY'),
                },
                {
                    value: 'VNPT_EPAY',
                    name: i18n.t('PAYMENT_PARTNERS.VNPT_EPAY'),
                },
                {
                    value: 'SHOPEE_PAY',
                    name: i18n.t('PAYMENT_PARTNERS.SHOPEE_PAY'),
                },
                {
                    value: 'PAYPAL',
                    name: i18n.t('PAYMENT_PARTNERS.PAYPAL'),
                },
                {
                    value: 'STRIPE',
                    name: i18n.t('PAYMENT_PARTNERS.STRIPE'),
                },
                {
                    value: 'APPOTA',
                    name: i18n.t('PAYMENT_PARTNERS.APPOTA'),
                },
            ],
        },
        {
            value: 'payment_shipping_status',
            name: i18n.t('SHIPPINGS.SHIPPING_STATUS'),
            type: 'MULTISELECT',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [
                {
                    value: 'Pending',
                    name: i18n.t('SHIPPING_STATUS.PENDING'),
                },
                {
                    value: 'ReadyToPick',
                    name: i18n.t('SHIPPING_STATUS.READY_TO_PICK'),
                },
                {
                    value: 'Delivering',
                    name: i18n.t('SHIPPING_STATUS.DELIVERING'),
                },
                {
                    value: 'Delivered',
                    name: i18n.t('SHIPPING_STATUS.DELIVERED'),
                },
                {
                    value: 'Returning',
                    name: i18n.t('SHIPPING_STATUS.RETURNING'),
                },
                {
                    value: 'Returned',
                    name: i18n.t('SHIPPING_STATUS.RETURNED'),
                },
                {
                    value: 'Canceled',
                    name: i18n.t('SHIPPING_STATUS.CANCELED'),
                },
                {
                    value: 'DeliveredToPostMan',
                    name: i18n.t('SHIPPING_STATUS.DELIVERED_TO_POSTMAN'),
                },
            ],
        },
        {
            value: 'product_type',
            name: i18n.t('ORDERS.PRODUCT_TYPE'),
            type: 'MULTISELECT',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [
                {
                    value: 'Physical',
                    name: i18n.t('PRODUCT_TYPES.PHYSICAL'),
                },
                {
                    value: 'Event',
                    name: i18n.t('PRODUCT_TYPES.EVENT'),
                },
                {
                    value: 'Service',
                    name: i18n.t('PRODUCT_TYPES.SERVICE'),
                },
                {
                    value: 'F_B',
                    name: i18n.t('PRODUCT_TYPES.F_B'),
                },
                {
                    value: 'COMBO',
                    name: i18n.t('PRODUCT_TYPES.COMBO'),
                },
                {
                    value: 'Digital',
                    name: 'Sản phẩm số',
                },
                {
                    value: 'Course',
                    name: 'Khóa học',
                },
            ],
        },
        {
            value: 'tags',
            name: i18n.t('ORDERS.TAG'),
            type: 'MULTISELECT',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                // {
                //     CODE: 'OTHER',
                //     NAME: i18n.t('OPERATORS.OTHER'),
                // },
            ],
            fields: [],
        },
        {
            value: 'product_ids',
            name: i18n.t('ORDERS.CONDITION_TITLE.PRODUCT_SELECT'),
            type: 'MULTISELECT-SEARCH',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [],
        },
        {
            value: 'creator',
            name: i18n.t('ORDERS.CREATOR'),
            type: 'SELECTED',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [],
        },
        {
            value: 'staff',
            name: i18n.t('ORDERS.ASSIGNEE'),
            type: 'SELECTED',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [],
        },
        // {
        //     value: 'page_checkout_ids',
        //     name: i18n.t('ORDERS.CONDITION_TITLE.PAGE_CHECKOUT'),
        //     type: 'SELECTED',
        //     operators: [
        //         {
        //             CODE: 'EQUAL',
        //             NAME: i18n.t('OPERATORS.EQUAL'),
        //         },
        //         {
        //             CODE: 'OTHER',
        //             NAME: i18n.t('OPERATORS.OTHER'),
        //         },
        //     ],
        //     fields: [],
        // },
        {
            value: 'spam',
            name: i18n.t('ORDERS.TAB_SPAM'),
            type: 'SELECTED',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [
                {
                    value: 1,
                    name: i18n.t('ORDER_SPAM.SPAM'),
                },
                {
                    value: 0,
                    name: i18n.t('ORDER_SPAM.NOT_SPAM'),
                },
            ],
        },
        {
            value: 'utm_medium',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_MEDIUM'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'utm_page',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_PAGE'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'utm_source',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_SOURCE'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'utm_campaign',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_CAMPAIGN'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'utm_term',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_TERM'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'utm_content',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_CONTENT'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'ip',
            name: i18n.t('ORDERS.CONDITION_TITLE.IP'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'msg_id',
            name: i18n.t('ORDERS.CONDITION_TITLE.MSG_ID'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'ref',
            name: i18n.t('ORDERS.CONDITION_TITLE.REF'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'ad_id',
            name: i18n.t('ORDERS.CONDITION_TITLE.AD_ID'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
        {
            value: 'fb_id',
            name: i18n.t('ORDERS.CONDITION_TITLE.FB_ID'),
            type: 'INPUT',
            operators: [
                {
                    CODE: 'LIKE',
                    NAME: i18n.t('OPERATORS.LIKE'),
                },
                {
                    CODE: 'NOT_LIKE',
                    NAME: i18n.t('OPERATORS.NOT_LIKE'),
                },
            ],
            fields: [],
        },
    ],
    OPERATORS: {
        GREATER_THAN: {
            CODE: 'GREATER_THAN',
            NAME: i18n.t('OPERATORS.GREATER_THAN'),
        },
        EQUAL: {
            CODE: 'EQUAL',
            NAME: i18n.t('OPERATORS.EQUAL'),
        },
        OTHER: {
            CODE: 'OTHER',
            NAME: i18n.t('OPERATORS.OTHER'),
        },
        LESS_THAN: {
            CODE: 'LESS_THAN',
            NAME: i18n.t('OPERATORS.LESS_THAN'),
        },
        LIKE: {
            CODE: 'LIKE',
            NAME: i18n.t('OPERATORS.LIKE'),
        },
        NOT_LIKE: {
            CODE: 'NOT_LIKE',
            NAME: i18n.t('OPERATORS.NOT_LIKE'),
        },
        SWITCH_AND_OR: {
            AND: 'AND',
            OR: 'OR',
        },
    },
    CONDITION_SAVE_TYPE: {
        NEW: {
            value: 'NEW',
            name: i18n.t('ACTIONS.CONDITION_NEW'),
        },
        UPDATE: {
            value: 'UPDATE',
            name: i18n.t('ACTIONS.CONDITION_UPDATE'),
        },
    },
    ORDER_COLUMN: [
        {
            value: 'customer_first_name',
            name: i18n.t('ORDERS.CUSTOMER'),
            isSelected: true,
            position: 1,
        },
        {
            value: 'customer_phone',
            name: i18n.t('ORDERS.PHONE'),
            isSelected: true,
            position: 2,
        },
        {
            value: 'created_at',
            name: i18n.t('ORDERS.ORDERED_AT'),
            isSelected: true,
            position: 3,
        },
        // {
        //     value: 'payment_status',
        //     name: i18n.t('ORDERS.STATUS'),
        //     isSelected: true,
        //     position: 4,
        // },
        {
            value: 'payment_method',
            name: i18n.t('ORDERS.PAYMENT'),
            isSelected: true,
            position: 5,
        },
        {
            value: 'shipping_status',
            name: i18n.t('ORDERS.LB_SHIPPING'),
            isSelected: true,
            position: 6,
        },
        {
            value: 'total',
            name: i18n.t('ORDERS.TOTAL'),
            isSelected: true,
            position: 7,
        },
        {
            value: 'assignee_id',
            name: i18n.t('ORDERS.ASSIGNEE'),
            isSelected: true,
            position: 8,
        },
        {
            value: 'product_type',
            name: i18n.t('ORDERS.PRODUCT_TYPE'),
            isSelected: false,
            position: 9,
        },
        {
            value: 'creator_id',
            name: i18n.t('ORDERS.CREATOR'),
            isSelected: false,
            position: 10,
        },
        {
            value: 'url_page',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_PAGE'),
            isSelected: false,
            position: 12,
        },
        {
            value: 'utm_source',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_SOURCE'),
            isSelected: false,
            position: 13,
        },
        {
            value: 'utm_medium',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_MEDIUM'),
            isSelected: false,
            position: 14,
        },
        {
            value: 'utm_campaign',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_CAMPAIGN'),
            isSelected: false,
            position: 15,
        },
        {
            value: 'utm_term',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_TERM'),
            isSelected: false,
            position: 16,
        },
        {
            value: 'utm_content',
            name: i18n.t('ORDERS.CONDITION_TITLE.UTM_CONTENT'),
            isSelected: false,
            position: 17,
        },
        {
            value: 'ref',
            name: i18n.t('ORDERS.CONDITION_TITLE.REF'),
            isSelected: false,
            position: 18,
        },
        {
            value: 'source',
            name: i18n.t('ORDERS.SOURCE'),
            isSelected: false,
            position: 19,
        },
    ],
    LIST_PRODUCT_TYPE: [
        {
            CODE: '',
            NAME: 'Tất cả sản phẩm',
        },
        {
            CODE: 'Physical',
            NAME: i18n.t('PRODUCT_TYPES.PHYSICAL'),
        },
        {
            CODE: 'Event',
            NAME: i18n.t('PRODUCT_TYPES.EVENT'),
        },
        {
            CODE: 'Service',
            NAME: i18n.t('PRODUCT_TYPES.SERVICE'),
        },
        {
            CODE: 'F_B',
            NAME: i18n.t('PRODUCT_TYPES.F_B'),
        },
        {
            CODE: 'COMBO',
            NAME: i18n.t('PRODUCT_TYPES.COMBO'),
        },
        {
            CODE: 'Digital',
            NAME: 'Sản phẩm số',
        },
        // {
        //     CODE: 'Course',
        //     NAME: 'Khóa học',
        // },
    ],
    SHPPING_TYPES: [
        {
            type: 1,
            name: i18n.t('ORDERS.FREE_SHIPPING'),
        },
        {
            type: 2,
            name: i18n.t('ORDERS.SHIPPING_CUSTOM'),
        },
    ],
    ORDER_COUPON_TYPES: [
        {
            value: 0,
            name: i18n.t('ORDERS.SHIPPING_CUSTOM'),
        },
        {
            value: 1,
            name: i18n.t('DISCOUNTS.COUPON_USE'),
        },
    ],

    // ORDER_FILTER_TYPE: {
    //     DRAFT: 'DRAFT',
    //     PAYMENT_PENDING: 'PAYMENT_PENDING',
    //     SHIPPING_PENDING: 'SHIPPING_PENDING',
    //     SPAM: 'SPAM',
    //     ALL: 'ALL',
    //     DUPLICATION: 'DUPLICATION',
    //     SEARCH_ALL: 'SEARCH_ALL',
    // },
    ORDER_FILTER_DEFAULT: [
        {
            type: 'ALL',
            name: i18n.t('ORDERS.TAB_ALL'),
        },
        {
            type: 'DRAFT',
            name: i18n.t('ORDERS.TAB_DRAFT'),
        },
        {
            type: 'SHIPPING_PENDING',
            name: i18n.t('ORDERS.TAB_SHIPPING_PENDING'),
        },
        {
            type: 'PAYMENT_PENDING',
            name: i18n.t('ORDERS.TAB_PAYMENT_PENDING'),
        },
        {
            type: 'SPAM',
            name: i18n.t('ORDERS.TAB_SPAM'),
        },
    ],
    ORDER_EDIT_OTHER_ACTION: [
        {
            type: 'ORDER_CANCEL',
            name: i18n.t('ORDERS.CANCEL_ORDER'),
            icon_class: 'ldicon-close',
        },
        {
            type: 'ORDER_RE_ORDER',
            name: i18n.t('ACTIONS.REORDER'),
            icon_class: 'ldicon-reload',
        },
        {
            type: 'ORDER_RESENT_MAIL',
            name: i18n.t('ACTIONS.RESENT_MAIL'),
            icon_class: 'ldicon-reload',
        },
        {
            type: 'ORDER_SPAM',
            name: i18n.t('ORDERS.MARK_SPAM'),
            icon_class: 'ldicon-post',
        },
        {
            type: 'ORDER_SPAM_CANCEL',
            name: i18n.t('ORDERS.REMOVE_MARK_SPAM'),
            icon_class: 'ldicon-post',
        },
    ],
    PRODUCT_CONDITION_FILEDS: [
        // {
        //     value: 'status',
        //     name: i18n.t('ORDERS.ORDER_STATUS'),
        //     type: 'SELECTED',
        //     operators: [
        //         {
        //             CODE: 'EQUAL',
        //             NAME: i18n.t('OPERATORS.EQUAL'),
        //         },
        //         {
        //             CODE: 'OTHER',
        //             NAME: i18n.t('OPERATORS.OTHER'),
        //         },
        //     ],
        //     fields: [
        //         {
        //             name: i18n.t('ORDER_STATUS.OPEN'),
        //             value: 'Open',
        //         },
        //         {
        //             name: i18n.t('ORDER_STATUS.CANCELED'),
        //             value: 'Canceled',
        //         },
        //     ],
        // },
        {
            value: 'tags',
            name: i18n.t('ORDERS.TAG'),
            type: 'MULTISELECT',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [],
        },
        {
            value: 'product_category',
            name: i18n.t('MENU.CATEGORY'),
            type: 'MULTISELECT',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                {
                    CODE: 'OTHER',
                    NAME: i18n.t('OPERATORS.OTHER'),
                },
            ],
            fields: [],
        },
    ],
    SHIPPING_CONDITION_FILEDS: [
        {
            value: 'status',
            name: i18n.t('SHIPPINGS.SHIPPING_STATUS'),
            type: 'SELECTED',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                // {
                //     CODE: 'OTHER',
                //     NAME: i18n.t('OPERATORS.OTHER'),
                // },
            ],
            fields: [
                {
                    value: 'Pending',
                    name: i18n.t('SHIPPING_STATUS.PENDING'),
                },
                {
                    value: 'ReadyToPick',
                    name: i18n.t('SHIPPING_STATUS.READY_TO_PICK'),
                },
                {
                    value: 'Delivering',
                    name: i18n.t('SHIPPING_STATUS.DELIVERING'),
                },
                {
                    value: 'Delivered',
                    name: i18n.t('SHIPPING_STATUS.DELIVERED'),
                },
                {
                    value: 'Returning',
                    name: i18n.t('SHIPPING_STATUS.RETURNING'),
                },
                {
                    value: 'Returned',
                    name: i18n.t('SHIPPING_STATUS.RETURNED'),
                },
                {
                    value: 'Canceled',
                    name: i18n.t('SHIPPING_STATUS.CANCELED'),
                },
                {
                    value: 'DeliveredToPostMan',
                    name: i18n.t('SHIPPING_STATUS.DELIVERED_TO_POSTMAN'),
                },
            ],
        },
        {
            value: 'partner',
            name: i18n.t('SHIPPINGS.PARTNER_TITLE'),
            type: 'SELECTED',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                // {
                //     CODE: 'OTHER',
                //     NAME: i18n.t('OPERATORS.OTHER'),
                // },
            ],
            fields: [
                {
                    value: 'GHN',
                    name: i18n.t('SHIPPING_PARTNERS.GHN'),
                },
                {
                    value: 'GHTK',
                    name: i18n.t('SHIPPING_PARTNERS.GHTK'),
                },
                {
                    value: 'VNPOST',
                    name: i18n.t('SHIPPING_PARTNERS.VNPOST'),
                },
                {
                    value: 'VTPOST',
                    name: i18n.t('SHIPPING_PARTNERS.VIETTEL_POST'),
                },
                {
                    value: 'OTHER',
                    name: i18n.t('SHIPPING_PARTNERS.OTHER'),
                },
            ],
        },

        {
            value: 'payment',
            name: i18n.t('SHIPPINGS.SHIPPING_PAYMENT_STATUS'),
            type: 'SELECTED',
            operators: [
                {
                    CODE: 'EQUAL',
                    NAME: i18n.t('OPERATORS.EQUAL'),
                },
                // {
                //     CODE: 'OTHER',
                //     NAME: i18n.t('OPERATORS.OTHER'),
                // },
            ],
            fields: [
                {
                    value: 'Pending',
                    name: i18n.t('SHIPPING_PAYMENT_STATUS.PENDING'),
                },
                {
                    value: 'Paid',
                    name: i18n.t('SHIPPING_PAYMENT_STATUS.PAID'),
                },
                {
                    value: 'Received',
                    name: i18n.t('SHIPPING_PAYMENT_STATUS.RECEIVED'),
                },
            ],
        },
    ],
    FILTER_TYPE: {
        SHIPPING: 'SHIPPING',
        ORDER: 'ORDER',
        PRODUCT: 'PRODUCT',
    },
    CHECKOUT_CONFIG_TYPE: {
        CUSTOMIZE: 'Customize',
        SHARED: 'Shared',
    },
    PRODUCT_LAYOUT_TYPE: {
        Carousel: 'Carousel',
        Grid: 'Grid',
    },
    timeRedirectPath: [
        {
            value: 5,
            name: '5 giây',
        },
        {
            value: 10,
            name: '10 giây',
        },
        {
            value: 15,
            name: '15 giây',
        },
        {
            value: 20,
            name: '20 giây',
        },
        {
            value: 0,
            name: 'Chuyển trực tiếp',
        },
        {
            value: 100,
            name: 'Chuyển sau khi thanh toán thành công',
        },
    ],
    LADIPAGE_URL: {
        ORDER_LIST: `/e-commerce?ecommerce_tab=order_list`,
        ORDER_UPDATE: (id) => `/e-commerce?ecommerce_tab=order_update&order_id=${id}`,
        ORDER_CREATE: `/e-commerce?ecommerce_tab=order_create`,
        CHECKOUTS: `/e-commerce?ecommerce_tab=checkouts`,
        SHIPPING: `/e-commerce?ecommerce_tab=shippings`,
        SHIPPING_UPDATE: (id) => `/e-commerce?ecommerce_tab=shipping_update&shipping_id=${id}`,
        ORDER_TAG: `/e-commerce?ecommerce_tab=order_tag`,
        ORDER_CUSTOM_FIELD: `/e-commerce?ecommerce_tab=order_custom_field`,

        PRODUCT_LIST: `/e-commerce?ecommerce_tab=product_list`,
        PRODUCT_UPDATE: (id, productType) => `/e-commerce?ecommerce_tab=product_update&product_id=${id}&product_type=${productType}`,
        PRODUCT_INVENTORY: `/e-commerce?ecommerce_tab=product_inventory`,
        PRODUCT_CATEGORY: `/e-commerce?ecommerce_tab=product_category`,
        PRODUCT_CATEGORY_CREATE: `/e-commerce?ecommerce_tab=product_category_create`,
        PRODUCT_CATEGORY_UPDATE: (id) => `/e-commerce?ecommerce_tab=product_category_update&product_category_id=${id}`,
        PRODUCT_REVIEW: `/e-commerce?ecommerce_tab=product_review`,
        PRODUCT_TAG: `/e-commerce?ecommerce_tab=product_tag`,
        PRODUCT_CUSTOM_FIELD: `/e-commerce?ecommerce_tab=product_custom_field`,

        PAGE_CHECKOUT_LIST: `/e-commerce?ecommerce_tab=store_design`,
        PAGE_CHECKOUT_UPDATE: (id) => `/e-commerce/builder/${id}`,

        CUSTOMER_LIST: `/e-commerce?ecommerce_tab=customer_list`,
        CUSTOMER_TAG: `/e-commerce?ecommerce_tab=customer_tag`,
        CUSTOMER_CUSTOM_FIELD: `/e-commerce?ecommerce_tab=customer_custom_field`,

        DISCOUNT: `/e-commerce?ecommerce_tab=discounts`,

        REPORT: `/e-commerce?ecommerce_tab=reports`,

        SETTING_GENERAL: `/e-commerce?ecommerce_tab=setting_general`,
        SETTING_PAYMENT: `/e-commerce?ecommerce_tab=setting_payment`,
        SETTING_PAYMENT_GATEWAY: `/e-commerce?ecommerce_tab=setting_paymnet_gateway`,
        SETTING_DOMAIN: `/e-commerce?ecommerce_tab=setting_domain`,
        SETTING_SHIPPING: `/e-commerce?ecommerce_tab=setting_shipping`,

        COURSE_UPDATE: (id) => `/e-commerce?ecommerce_tab=courses_update&product_id=${id}`,

        COURSE_CATEGORY_CREATE: `/e-commerce?ecommerce_tab=course_category`,
    },
    PAGE_CHECKOUT_TYPES: [
        {
            CODE: '',
            NAME: 'Tất cả',
        },
        {
            CODE: 'tag',
            NAME: 'Trang thanh toán theo tag',
        },
        {
            CODE: 'product',
            NAME: 'Trang thanh toán nhiều sản phẩm',
        },
        {
            CODE: 'single_product',
            NAME: 'Trang thanh toán một sản phẩm',
        },
    ],
    PAGE_CHECKOUT_TYPE: {
        PRODUCT: 'product',
        TAG: 'tag',
        EMBED: 'embed',
        COURSE: 'Course',
        CATEGORY: 'Category',
        STORE: 'Store',
        SINGLE_PRODUCT: 'single_product',
    },
    ROUTER_TICKET_CHECKIN: ['/checkin', '/check-qrcode', '/checkin/info-handle'],
    INTEGRATE_CONFIG: ['HARAVAN', 'NHANH', 'SAPO', 'WORDPRESS', 'KIOTVIET', 'SHOPIFY'],
    PAGE_CHECKOUT_SECTION_TYPE: {
        CART: 'CART',
        SETUP_FOOTER: 'SETUP_FOOTER',
        PAGE_HOME_CONFIG: 'PAGE_HOME_CONFIG',
        WIDGET_CUSTOMER: 'WIDGET_CUSTOMER',
        WIDGET_PAYMENT: 'WIDGET_PAYMENT',
        WIDGET_SHIPPING: 'WIDGET_SHIPPING',
        THANK_PAGE: 'THANK_PAGE',
    },

    TINY_MCE: {
        "current": "f67zu01nk1z6ojj346hfn9don9x5oq9roghoetakynpz8igr",
        "mimi.pham01234a@gmail.com": "gdds0kl2vkrgsudv8aok7745gf2p3puaf6me5heo97ew41zc",
        "mimi.pham01234b@gmail.com": "f67zu01nk1z6ojj346hfn9don9x5oq9roghoetakynpz8igr",
        "mimi.pham01234c@gmail.com": "o01c9wd0c5wsuzx4t6vhjli998hu9sv8nk27qf5my9lvnxq2",
        "mimi.pham01234e@gmail.com": "o07afmamkcf08op5ywb2um5xnx8fc0921dg1dgzojqndrmjw",
        "mimipham1307@gmail.com": "s4dkedt63w8v77rihvzbjbneyu1940i6gp9ycynskfwwo47c"
    },
    SEPAY_BANK: ["ICB", "VCB", "BIDV", "VBA", "OCB", "MB", "TCB", "ACB", "VPB", "TPB", "STB", "HDB", "EIB", "MSB", "SHBVN", "ABB", "KLB", "PBVN"],
    BANK_WEBHOOK: 'https://api.checkout.ladisales.com/1.0/webhook/payment-status/',
    GOOGLE_FONT_API_KEY: 'AIzaSyCKqsj3RGqiVlcBTBXzEpoWPkF7Ru0tydg',
    MATERIAL_ICONS: [
        "home", "favorite", "settings", "alarm", "star", "face", "account_circle", "shopping_cart",
        "search", "menu", "check", "close", "delete", "edit", "info", "warning", "add", "remove",
        "account_box", "done", "favorite_border", "lock", "lock_open", "phone", "email", "cloud",
        "camera", "build", "flight", "directions_car", "map", "location_on", "photo", "play_arrow",
        "pause", "stop", "volume_up", "volume_off", "skip_next", "skip_previous", "school",
        "work", "pets", "child_friendly", "wifi", "bluetooth", "battery_full", "battery_alert", "access_alarm",
        "alarm_on", "brightness_high", "brightness_low", "keyboard_arrow_up", "keyboard_arrow_down",
        "keyboard_arrow_left", "keyboard_arrow_right", "arrow_back", "arrow_forward", "arrow_upward", "arrow_downward",
        "thumb_up", "thumb_down", "access_time", "today", "calendar_today", "event", "event_note", "attach_file",
        "attach_money", "language", "translate", "group", "people", "person", "person_add", "person_outline",
        "public", "security", "shopping_basket", "store", "credit_card", "payment", "visibility", "visibility_off",
        "check_circle", "cancel", "help", "help_outline", "contact_mail", "contact_phone", "add_circle", "add_box",
        "remove_circle", "create", "playlist_add", "queue", "sync", "update", "account_balance", "account_balance_wallet", "extension", "gavel", "hourglass_empty", "hourglass_full", "settings_input_antenna",
        "settings_input_component", "settings_input_composite", "settings_input_hdmi", "settings_input_svideo",
        "settings_power", "settings_overscan", "settings_remote", "settings_voice", "power", "usb", "folder", "folder_open",
        "folder_shared", "description", "article", "assessment", "assignment", "assignment_ind", "book", "bookmark",
        "bookmarks", "chrome_reader_mode", "class", "library_books", "library_add", "library_music", "list_alt",
        "note", "notes", "subject", "text_snippet", "dashboard", "bar_chart", "insert_chart", "insert_chart_outlined",
        "insights", "analytics", "monetization_on", "trending_up", "trending_down", "trending_flat", "expand_less",
        "expand_more", "refresh", "sync_alt", "autorenew", "rotate_left", "rotate_right", "build_circle", "task_alt",
        "verified", "published_with_changes", "save", "save_alt", "print", "share", "attach_email", "forum", "comment",
        "emoji_emotions", "emoji_flags", "emoji_food_beverage", "emoji_nature", "emoji_objects", "emoji_people",
        "emoji_symbols", "emoji_transportation", "cake", "mood", "mood_bad", "sports", "sports_baseball", "sports_basketball",
        "sports_cricket", "sports_esports", "sports_football", "sports_golf", "sports_handball", "sports_hockey", "sports_kabaddi",
        "sports_mma", "sports_motorsports", "sports_rugby", "sports_soccer", "sports_tennis", "sports_volleyball", "clean_hands",
        "social_distance", "public_off", "deck", "fireplace", "grass", "water_damage", "escalator_warning", "family_restroom",
        "sick", "coronavirus", "masks", "air", "circle", "check_box", "check_box_outline_blank", "radio_button_checked",
        "radio_button_unchecked", "indeterminate_check_box", "star_border", "star_half", "star_rate", "thumbs_up_down",
        "donut_large", "donut_small", "local_florist", "local_gas_station", "local_grocery_store", "local_hospital", "local_hotel",
        "local_laundry_service", "local_library", "local_mall", "local_movies", "local_offer", "local_parking", "local_pharmacy",
        "local_phone", "local_pizza", "local_play", "local_police", "local_post_office", "local_see",
        "local_shipping", "local_taxi", "local_activity", "local_airport", "local_atm", "local_bar", "local_cafe", "local_car_wash",
        "local_convenience_store", "local_dining", "local_drink", "local_fire_department", "local_hospital",
        "local_library", "local_laundry_service", "local_pharmacy", "local_shipping", "local_taxi", "place", "map", "navigation",
        "near_me", "person_pin", "person_pin_circle", "pin_drop", "directions", "directions_bike", "directions_boat",
        "directions_bus", "directions_car", "directions_railway", "directions_run", "directions_subway", "directions_transit",
        "directions_walk", "satellite", "store_mall_directory", "traffic", "train", "tram", "transfer_within_a_station",
        "transit_enterexit", "trip_origin", "zoom_in", "zoom_out"
    ],
    MENU_CONFIG_TYPES: {
        VERTICAL: 'VERTICAL',
        HORIZONTAL: 'HORIZONTAL',
    },
    DOMAIN_COURSE_DEFAULT: 'course.ladi.site'
};
