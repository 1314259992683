import React, { useEffect, useState, useRef, useMemo } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { appLocalStorage } from '../../../localforage';

import appConfig from '../../../config/app';
import baseHelper from '../../../helpers/BaseHelper';
import orderDefined from '../OrderDefined';

import DropDownNew from '../../../components/DropDownNew';
import { OrderProducts } from './OrderProducts';

import shippingRateActions from '../../../redux/futures/shipping_rate/actions';

import * as discountTypes from '../../../redux/futures/discount/types';
import discountActions from '../../../redux/futures/discount/actions';

import * as orderTypes from '../../../redux/futures/order/types';
import orderActions from '../../../redux/futures/order/actions';

import * as customerTypes from '../../../redux/futures/customer/types';
import customerActions from '../../../redux/futures/customer/actions';

import * as printTypes from '../../../redux/futures/print/types';
import printActions from '../../../redux/futures/print/actions';

import orderHistoryActions from '../../../redux/futures/order_history/actions';

import checkoutConfigActions from '../../../redux/futures/checkout_config/actions';

import PopoverCoupon from './components/PopoverCoupon';
import PopoverShipping from './components/PopoverShipping';
import { OrderCustomer } from './components/OrderCustomer';
import { OrderTags } from './components/OrderTags';
import ModalOrderPayment from './components/ModalOrderPayment';
import LoadingScene from '../../../components/LoadingScene';
import PopperCheckout from './components/PopperCheckout';
import Dropdown from '../../../components/Dropdown';
import History from '../../../components/History';
import PanelCustomField from '../../../components/PanelCustomField';
import ShippingDelivery from '../OrderEdit/components/ShippingDelivery';
import Header from './Header';
import { OrderPaymentInfo } from './OrderPaymentInfo';
import ModalCancel from '../components/ModalCancel';
import ModalConfirmCancel from '../components/ModalConfirmCancel';
import ModalConfirmResendMail from '../components/ModalConfirmResendMail';
import ConfirmModal from '../../../components/ConfirmModal';
// import appConfig from '../../../config/app';

// eslint-disable-next-line max-lines-per-function
function OrderCreateV2(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const { state } = useLocation();
    //ref
    const productsRef = useRef();
    const customerRef = useRef();
    const panelCustomFieldRef = useRef();
    const tagsRef = useRef();

    const storeReducer = useSelector((state) => state.store);
    const orderReducer = useSelector((state) => state.order);
    const orderHistoryReducer = useSelector((state) => state.order);
    const variantReducer = useSelector((state) => state.variant);
    const customerReducer = useSelector((state) => state.customer);
    const discountReducer = useSelector((state) => state.discount);
    const orderTagReducer = useSelector((state) => state.orderTag);
    const paymentReducer = useSelector((state) => state.payment);
    const shippingRateReducer = useSelector((state) => state.shippingRate);
    const printReducer = useSelector((state) => state.print);

    const { match } = props;

    const staffs = useMemo(() => {
        return storeReducer.userInfo ? storeReducer.userInfo.staffs : [];
    }, [storeReducer.userInfo]);

    const shippingMethods = useMemo(() => {
        return shippingRateReducer.shipping_rates || [];
    }, [shippingRateReducer.shipping_rates]);

    const orderLoading = useMemo(() => {
        return orderReducer.loading || false;
    }, [orderReducer.loading]);

    const [assign, setAssign] = useState({});
    const [variantSelected, setVariantSelected] = useState([]);
    const [customerSelected, setCustomerSelected] = useState({});
    const [customerShipping, setCustomerShipping] = useState({});
    const [customerBilling, setCustomerBilling] = useState({});

    const [isShowModalPayment, setShowModalPayment] = useState(false);
    const [isShowModalShipping, setShowModalShipping] = useState(false);
    const [isShowModalCancel, setShowModalCalcel] = useState(false);
    const [isShowModalConfirmCancel, setShowModalConfirmCancel] = useState(false);
    const [isShowModalResendMail, setShowModalResendMail] = useState(false);

    const [selectDiscount, setSelectDiscount] = useState({});
    const [shippingFee, setShippingFee] = useState({});
    const [discountFee, setDiscountFee] = useState({});
    const [paymentSelected, setPaymentSelected] = useState({});

    const [emailNotification, setEmailNotification] = useState(false);
    const [isEditableOrder, setEditOrder] = useState(false);
    const [isGenerateCheckout, setGenerateCheckout] = useState('');
    const [checkoutLink, setCheckoutLink] = useState('');
    const [orderData, setOrderData] = useState({});
    const [isCanceled, setCancel] = useState(false);
    const [restockAll, setRestockAll] = useState(false);

    const [isShowOrderCF, setShowOrderCF] = useState(false);
    const [isShowCusCF, setShowCusCF] = useState(false);

    const [payment, setPayment] = useState({
        status: appConfig.PAYMENT_STATUS.PENDING.CODE,
        method: '',
        type: '',
    });
    const [orderNote, setOrderNote] = useState('');

    let isFirstRender = true;

    //check orderid
    useEffect(() => {
        if (match && match.params && match.params.orderID) {
            dispatch(orderActions.show(match.params.orderID));
            dispatch(checkoutConfigActions.list());
        } else {
            setEditOrder(true);
        }
    }, [match.params.orderID]);

    useEffect(() => {
        if (state && state.order) {
            initOrder(state.order, true);
        }
    }, [state]);

    // Effect call api
    useEffect(() => {
        if (discountReducer.action == discountTypes.VALIDATE_DISCOUNT) {
            if (discountReducer.status) {
                let discount_fee = discountReducer.discount_fee;
                let variants = discountReducer.variants;
                if (selectDiscount.code)
                    setSelectDiscount((pre) => {
                        let tg = { ...pre };
                        tg.value = discount_fee;
                        return tg;
                    });

                const _variantSelected = variantSelected.map((item) => {
                    let variant = variants.find((_item) => _item.variant_id == item.variant_id);
                    if (variant) {
                        if (item.discount) {
                            variant.discount = item.discount;
                        }
                        item = variant;
                    }
                    return item;
                });
                setVariantSelected(_variantSelected);
            } else {
                if (!isFirstRender) {
                    window.LadiUI.showErrorMessage('Thông báo', discountReducer.message, 'OK');
                } else {
                    isFirstRender = false;
                }
                setSelectDiscount({});
            }
        }
    }, [discountReducer.action]);

    useEffect(() => {
        if (printReducer.action == printTypes.PRINT_ORDER) {
            if (printReducer.status) {
                baseHelper.print(printReducer.source);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', printReducer.message, 'OK');
            }
        }
    }, [printReducer.action]);

    useEffect(() => {
        if (orderReducer.action == orderTypes.CREATE_ORDER) {
            if (orderReducer.status) {
                window.LadiUI.toastCustom('success', '', orderReducer.message);

                setTimeout(() => {
                    history.replace(`/orders/${orderReducer.order_id}`);
                    // dispatch(orderActions.show(orderReducer.order_id));
                }, 100);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (
            [
                orderTypes.UPDATE_ORDER_ADDRESS,
                // orderTypes.SHIPPING_UPDATE_ORDER_ADDRESS,
                orderTypes.UPDATE_ORDER,
                orderTypes.PARTIAL_UPDATE_ORDER,
                orderTypes.UPDATE_ORDER_CUSTOMER_INFO,
                orderTypes.MARK_AS_PAID,
            ].includes(orderReducer.action)
        ) {
            if (orderData && orderData.order_id) dispatch(orderActions.reload(orderData.order_id));
            if (orderReducer.status) {
                window.LadiUI.toastCustom('success', '', orderReducer.message);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (orderReducer.action == orderTypes.GENERATE_CHECKOUT_ORDER) {
            if (orderReducer.status) {
                const checkoutLink = `${baseHelper.domainToUrl(storeReducer.userInfo.default_domain)}/checkout/${orderReducer.order.checkout_token
                    }`;
                setGenerateCheckout(true);
                setCheckoutLink(checkoutLink);

                baseHelper.copyToClipboard(checkoutLink);
                window.LadiUI.toastCustom('success', '', orderReducer.message);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (orderReducer.action == orderTypes.UPDATE_SPAMS) {
            if (orderReducer.status) {
                if (orderData && orderData.order_id) dispatch(orderActions.reload(orderData.order_id));
                window.LadiUI.toastCustom('success', '', orderReducer.message);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (orderReducer.action == orderTypes.FULLFILL_ORDER) {
            if (orderReducer.status) {
                dispatch(orderActions.reload(orderData.order_id));
                setShowModalShipping(false);
                window.LadiUI.toastCustom('success', '', orderReducer.message);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (orderReducer.action == orderTypes.CANCEL_ORDER) {
            if (orderReducer.status) {
                setShowModalCalcel(false);
                window.LadiUI.toastCustom('success', '', orderReducer.message);
                if (orderData.order_id) {
                    dispatch(orderActions.reload(orderData.order_id));
                }
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (orderReducer.action == orderTypes.RESEND_MAIL) {
            if (orderReducer.status) {
                setShowModalResendMail(false);
                window.LadiUI.toastCustom('success', '', orderReducer.message);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (orderReducer.action == orderTypes.SHOW_ORDER || orderReducer.action == orderTypes.RELOAD_ORDER) {
            if (orderReducer.status) {
                // nếu là đơn hàng không được hiển thị thì k làm gì cả
                if (!orderReducer || orderReducer.order_not_show) {
                    return;
                }

                // Nếu order chưa hủy + nháp
                const order = orderReducer.order || {};
                initOrder(order);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }

        if (orderReducer.action == orderTypes.ASSIGN_ORDER) {
            if (orderReducer.status) {
                // nếu là đơn hàng không được hiển thị thì k làm gì cả
                let staff = staffs.find((item) => item.ladi_uid == orderReducer.assigneeID);
                setAssign(staff);
            } else {
                window.LadiUI.showErrorMessage(t('ORDERS.MSG_CONFIRM_CANCEL_ORDER_TITLE'), orderReducer.message, 'OK');
            }
        }
    }, [orderReducer.action]);

    useEffect(() => {
        if (shippingFee && shippingFee.shipping_rate_detail_id && shippingFee.shipping_rate_detail_id > 1) {
            let shipping = shippingMethods.find((item) => item.shipping_rate_detail_id == shippingFee.shipping_rate_detail_id);
            if (!shipping) setShippingFee({});
        }
    }, [shippingMethods]);

    // Effect check value
    useEffect(() => {
        if (orderData.assignee_id) {
            let staff = staffs.find((item) => item.ladi_uid == orderData.assignee_id);
            setAssign(staff);
        }
    }, [staffs, orderData]);

    useEffect(() => {
        if (isEditableOrder && variantSelected.length > 0 && customerShipping.country_code) {
            let weight = 0;
            let total = getTotal(true);
            for (let variant of variantSelected) {
                weight += variant.weight * variant.quantity;
            }

            let dataSend = {
                weight,
                total,
                shipping_address: {
                    country_code: customerShipping.country_code,
                    state_id: customerShipping.state_id,
                    district_id: customerShipping.district_id,
                },
            };

            dispatch(shippingRateActions.getAvailable(dataSend));
        }
    }, [variantSelected, customerShipping, selectDiscount]);

    useEffect(() => {
        const getData = setTimeout(() => {
            validateDiscount();
        }, 500);

        return () => clearTimeout(getData);
    }, [selectDiscount, variantSelected, customerSelected]);

    const initOrder = (order, isGetCus = false) => {
        setOrderData(order);
        const editable =
            order.order_id && order.status != appConfig.ORDER_STATUS.CANCELED.CODE && order.is_draft == appConfig.STATUS.ACTIVE;

        if (editable) setEditOrder(true);
        else setEditOrder(false);

        if (order.customer_id && (orderReducer.action == orderTypes.SHOW_ORDER || isGetCus)) {
            dispatch(customerActions.show(order.customer_id));
        }

        setGenerateCheckout(order.is_generate_checkout);
        if (order.is_generate_checkout) {
            let checkoutLink = `${baseHelper.domainToUrl(storeReducer.userInfo.default_domain)}/checkout/${order.checkout_token}`;

            if (order.source == 'LADISALES' && order.url_page != '') {
                checkoutLink = `${baseHelper.getDomainName(order.url_page)}/checkout/${order.checkout_token}`;
            }

            setCheckoutLink(checkoutLink);
        }
        if (order.status == appConfig.ORDER_STATUS.CANCELED.CODE) {
            setCancel(true);
        } else setCancel(false);

        if (order.restock_all == appConfig.STATUS.ACTIVE) setRestockAll(true);
        else setRestockAll(false);

        if (order.note) setOrderNote(order.note);

        if (order.order_details) {
            const variants = order.order_details.map((item) => {
                const variant = {
                    options: item.options,
                    src: item.src,
                    name: item.product_name || item.product_name_full,
                    price: item.price,
                    product_id: item.product_id,
                    quantity: item.quantity,
                    total: item.total,
                    variant_id: item.product_variant_id,
                    product_variant_id: item.product_variant_id,
                    product_type: item.product_type,
                    weight: item.weight,
                    max_buy: item.max_buy,
                    min_buy: item.min_buy,
                    ticket_creation_form: item.ticket_creation_form,
                    package_quantity_unit: item.package_quantity_unit,
                };

                if (item.discount_type) {
                    variant.discount = {
                        fee: item.discount_fee,
                        note: item.discount_note,
                        type: item.discount_type,
                        value: item.discount_value,
                        maximum_discount: item.discount_maximum,
                    };
                }

                return variant;
            });

            setVariantSelected(variants);
        }

        let billingAddress = baseHelper.isEmpty(order)
            ? {}
            : {
                first_name: order.billing_first_name,
                last_name: order.billing_last_name,
                company: order.billing_company,
                phone: order.billing_phone,
                address: order.billing_address,
                apartment: order.billing_apartment,
                state: order.billing_state,
                country_code: order.billing_country_code,
                country_name: order.billing_country_name,
                state_id: order.billing_state_id,
                state_name: order.billing_state_name,
                district_id: order.billing_district_id,
                district_name: order.billing_district_name,
                ward_id: order.billing_ward_id,
                ward_name: order.billing_ward_name,
                postal_code: order.billing_postal_code,
            };

        // setCustomerBilling(billingAddress);

        if (!baseHelper.isEmpty(order)) {
            let billingAddress = {
                first_name: order.billing_first_name,
                last_name: order.billing_last_name,
                company: order.billing_company,
                phone: order.billing_phone,
                address: order.billing_address,
                apartment: order.billing_apartment,
                state: order.billing_state,
                country_code: order.billing_country_code,
                country_name: order.billing_country_name,
                state_id: order.billing_state_id,
                state_name: order.billing_state_name,
                district_id: order.billing_district_id,
                district_name: order.billing_district_name,
                ward_id: order.billing_ward_id,
                ward_name: order.billing_ward_name,
                postal_code: order.billing_postal_code,
                billing_tax_code: order.billing_tax_code,
            };

            if (order.billing_company) billingAddress.first_name = order.billing_company;
            if (order.billing_company_address) {
                billingAddress.address = order.billing_company_address;
                billingAddress.country_code = '';
                billingAddress.country_name = '';
                billingAddress.state_id = '';
                billingAddress.state_name = '';
                billingAddress.district_id = '';
                billingAddress.district_name = '';
                billingAddress.ward_id = '';
                billingAddress.ward_name = '';
            }

            setCustomerBilling(billingAddress);
            // if(order.billing_company) billingAddress.
        }

        const shippingAddress = baseHelper.isEmpty(order)
            ? {}
            : {
                first_name: order.shipping_first_name,
                last_name: order.shipping_last_name,
                company: order.shipping_company,
                phone: order.shipping_phone,
                address: order.shipping_address,
                apartment: order.shipping_apartment,
                state: order.shipping_state,
                country_code: order.shipping_country_code,
                country_name: order.shipping_country_name,
                state_id: order.shipping_state_id,
                state_name: order.shipping_state_name,
                district_id: order.shipping_district_id,
                district_name: order.shipping_district_name,
                ward_id: order.shipping_ward_id,
                ward_name: order.shipping_ward_name,
                postal_code: order.shipping_postal_code,
                note: order.shipping_note,
            };

        setCustomerShipping(shippingAddress);

        const selectDiscount = {
            type: order.discount_code ? appConfig.DISCOUNT.TYPE.FIXED.value : order.discount_type || appConfig.DISCOUNT.TYPE.FIXED.value,
            value: order.discount_fee,
            note: order.discount_note,
            code: order.discount_code,
            maximum: order.discount_maximum,
        };

        setSelectDiscount(selectDiscount);

        const selectShipping = {
            shipping_rate_detail_id: order.shipping_rate_detail_id,
            type: order.shipping_rate_type || appConfig.SHIPPING_TYPE.FREE,
            name: order.shipping_rate_name,
            fee: order.shipping_fee,
        };

        setShippingFee(selectShipping);
    };

    const validateDiscount = async () => {
        if (isEditableOrder && variantSelected.length > 0) {
            const variantMap = variantSelected.map((item) => {
                return {
                    product_variant_id: item.product_variant_id || item.variant_id,
                    quantity: item.quantity,
                };
            });

            let dataSend = {
                customer_id: customerSelected.customer_id,
                discount_code: selectDiscount.code,
                variants: variantMap,
            };
            if (orderData && orderData.order_id) dataSend.order_id = orderData.order_id;

            const oldData = await appLocalStorage.getItem(appConfig.LOCAL_FORAGE_PROMOTION_DATA);
            if (JSON.stringify(oldData) == JSON.stringify(dataSend)) {
                return;
            }
            await appLocalStorage.setItem(appConfig.LOCAL_FORAGE_PROMOTION_DATA, dataSend);
            dispatch(discountActions.validateDiscount(dataSend));
        } else {
            await appLocalStorage.setItem(appConfig.LOCAL_FORAGE_PROMOTION_DATA, []);
        }
    };

    const getSubTotal = () => {
        let total = 0;
        for (let variant of variantSelected) {
            let price = variant.price;
            if (variant.discount) {
                const discountFee = baseHelper.calculateDiscountFee(
                    variant.price,
                    variant.discount.type,
                    variant.discount.value,
                    variant.discount.maximum_discount
                );
                price = price - discountFee;
            }
            total += price * variant.quantity;
        }

        return total;
    };
    const getTotal = (isGetShipping = false) => {
        let subTotal = 0;
        let total = 0;
        for (let variant of variantSelected) {
            let price = variant.price;
            if (variant && variant.discount) {
                const discountFee = baseHelper.calculateDiscountFee(
                    variant.price,
                    variant.discount.type,
                    variant.discount.value,
                    variant.discount.maximum_discount
                );
                price = price - discountFee;
            }
            subTotal += price * variant.quantity;
        }

        total = subTotal;

        if (selectDiscount && selectDiscount.value > 0) {
            let discountValue = baseHelper.calculateDiscountFee(subTotal, selectDiscount.type, selectDiscount.value);
            total -= discountValue;
        }
        if (orderData.discount_shipping_value) {
            total -= orderData.discount_shipping_value;
        }

        if (!isGetShipping && shippingFee && shippingFee.fee) {
            total += Number(shippingFee.fee);
        }

        return total;
    };

    const removeDiscount = () => {
        setSelectDiscount({});
    };

    const applyDiscount = (discount) => {
        setSelectDiscount(discount || {});
    };
    const removeShipping = () => {
        setShippingFee({});
    };

    const applyShipping = (shipping) => {
        setShippingFee(shipping);
    };

    const VariantUpdate = (data) => {
        setVariantSelected(data);
    };

    const validateBeforeSubmit = (hasPayment = false) => {
        if (variantSelected.length <= 0) {
            window.LadiUI.toastCustom('danger', '', t('ORDERS.SELECT_AT_LEAST_ONE_PRODUCT'));
            return false;
        }

        if (!customerSelected.customer_id) {
            window.LadiUI.toastCustom('danger', '', t('ORDERS.SELECT_AT_LEAST_ONE_CUSTOMER'));
            return false;
        }

        if (hasPayment) {
            if (!paymentSelected.method) {
                window.LadiUI.toastCustom('danger', '', t('ORDERS.ORDER_PAYMENT_EMPTY'));
                return false;
            }
        }

        return true;
    };

    const createDraft = (e) => {
        // e.preventdefault();

        if (!validateBeforeSubmit()) {
            return;
        }
        submit(appConfig.STATUS.ACTIVE);
    };

    const openModalConfirmCreateOrder = (paymentStatus) => {
        if (!validateBeforeSubmit()) {
            return;
        }

        setShowModalPayment(true);
        setPayment((pre) => {
            let tg = { ...pre };
            tg.status = paymentStatus;
            return tg;
        });
    };

    const submit = (isDraft = appConfig.STATUS.INACTIVE) => {
        if (isDraft == appConfig.STATUS.INACTIVE) {
            if (!paymentSelected.method) {
                window.LadiUI.toastCustom('danger', '', t('ORDERS.ORDER_PAYMENT_EMPTY'));
                return;
            }
        }
        let productCustomField = [];
        if (panelCustomFieldRef && panelCustomFieldRef.current) {
            productCustomField = panelCustomFieldRef.current.getData();
        }
        const tags = tagsRef.current.getData();
        let selectDiscountTg = { ...selectDiscount };
        if (selectDiscountTg.code) delete selectDiscountTg.value;

        const order = {
            customer_id: customerSelected.customer_id,
            billing_address: {
                ...customerBilling,
            },
            shipping_address: {
                ...customerShipping,
            },
            variants: variantSelected,
            discount: selectDiscountTg,
            note: orderNote,
            custom_fields: productCustomField,
            shipping_rate_type: shippingFee.type,
            shipping_rate_detail_id: shippingFee.shipping_rate_detail_id,
            shipping_rate_name: shippingFee.name,
            shipping_fee: shippingFee.fee,
            // tax_fee,
            is_draft: isDraft,
            tags,
            assignee_id: assign.ladi_uid,
            email_notification: emailNotification,
            is_auto_shipping: paymentSelected.is_auto_shipping || false,
        };

        let _paymentSelected = paymentSelected;

        if (isDraft == appConfig.STATUS.INACTIVE) {
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE) {
                _paymentSelected.type = 'SPP';
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
                _paymentSelected.type = 'BK';
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.MOMO.CODE) {
                _paymentSelected.type = 'MO';
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.COD.CODE) {
                _paymentSelected.type = _paymentSelected.method;
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE) {
                _paymentSelected.type = 'PAYPAL';
            }
            order.payment = {
                method: _paymentSelected.method,
                type: _paymentSelected.type,
                status: payment.status,
            };
        } else {
            order.payment = {
                method: '',
                type: '',
                status: payment.status,
            };
        }

        dispatch(orderActions.create(order));
    };

    const partialSubmit = () => {
        if (orderData.status == appConfig.ORDER_STATUS.CANCELED.CODE) {
            window.LadiUI.toastCustom('danger', '', t('ORDERS.ORDER_CANCEL_ACTION'));
            return;
        }
        const tags = tagsRef.current.getData();
        let custom_fields = [];
        if (panelCustomFieldRef && panelCustomFieldRef.current) custom_fields = panelCustomFieldRef.current.getData();

        const order = {
            order_id: orderData.order_id,
            tags,
            custom_fields,
            note: orderNote,
        };

        dispatch(orderActions.partialUpdate(order));
    };

    const fullSubmit = (hasPayment = false) => {
        if (!validateBeforeSubmit(hasPayment)) {
            return;
        }

        const tags = tagsRef.current.getData();
        let custom_fields = [];
        if (panelCustomFieldRef && panelCustomFieldRef.current) custom_fields = panelCustomFieldRef.current.getData();

        let selectDiscountTg = { ...selectDiscount };
        if (selectDiscountTg.code) delete selectDiscountTg.value;

        const order = {
            order_id: orderData.order_id,
            customer_id: customerSelected.customer_id,
            billing_address: {
                ...customerBilling,
            },
            shipping_address: {
                ...customerShipping,
            },
            variants: variantSelected,
            discount: selectDiscountTg,
            note: orderNote,
            custom_fields,

            shipping_rate_type: shippingFee.type,
            shipping_rate_detail_id: shippingFee.shipping_rate_detail_id,
            shipping_rate_name: shippingFee.name,
            shipping_fee: shippingFee.fee,
            // tax_fee,
            is_draft: appConfig.STATUS.INACTIVE,
            tags,
            email_notification: emailNotification,
            // status: this.props.order.order.status,
            is_auto_shipping: paymentSelected.is_auto_shipping || false,
        };

        let _paymentSelected = paymentSelected;
        if (hasPayment) {
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE) {
                _paymentSelected.type = 'SPP';
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
                _paymentSelected.type = 'BK';
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.MOMO.CODE) {
                _paymentSelected.type = 'MO';
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.COD.CODE) {
                _paymentSelected.type = _paymentSelected.method;
            }
            if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE) {
                _paymentSelected.type = 'PAYPAL';
            }
            order.payment = {
                method: _paymentSelected.method,
                type: _paymentSelected.type,
                status: payment.status,
            };
        }

        // this.props.updateOrder(order);
        dispatch(orderActions.update(order));
    };

    const markAsPaid = () => {
        // const {
        //     order: { order_id: orderID },
        // } = this.props.order;

        const orderID = orderData.order_id;

        if (!paymentSelected.method) {
            window.LadiUI.toastCustom('danger', '', t('ORDERS.ORDER_PAYMENT_EMPTY'));
            return false;
        }

        // let errors = this.paymentRef.validate();

        // if (errors.length > 0) {
        //     const errorMessage = errors.join('<br/>');
        //     window.LadiUI.toastCustom('danger', '', errorMessage);

        //     return;
        // }

        const is_auto_shipping = paymentSelected.is_auto_shipping || false;

        let payment_type = paymentSelected.type;

        if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE) {
            payment_type = 'SSP';
        }
        if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
            payment_type = 'BK';
        }
        if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.COD.CODE) {
            payment_type = paymentSelected.method;
        }
        if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.MOMO.CODE) {
            payment_type = 'MO';
        }
        if (paymentSelected.method == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE) {
            payment_type = 'PAYPAL';
        }
        // this.props.markAsPaid(orderID, paymentSelected.method, payment_type, is_auto_shipping);

        dispatch(orderActions.markAsPaid(orderID, paymentSelected.method, payment_type, is_auto_shipping));
    };

    const getLinkCheckout = () => {
        let orderID = orderData.order_id;
        dispatch(orderActions.generateCheckout(orderID));
    };

    const actionOrther = (item) => {
        switch (item.type) {
            case 'ORDER_CANCEL':
                setShowModalCalcel(true);
                break;
            case 'ORDER_RE_ORDER':
                setShowModalConfirmCancel(true);
                break;
            case 'ORDER_RESENT_MAIL':
                setShowModalResendMail(true);
                break;
            case 'ORDER_SPAM':
                dispatch(
                    orderActions.updateSpams({
                        order_ids: [orderData.order_id],
                        mark_spam: appConfig.STATUS.ACTIVE,
                    })
                );
                break;
            case 'ORDER_SPAM_CANCEL':
                dispatch(
                    orderActions.updateSpams({
                        order_ids: [orderData.order_id],
                        mark_spam: appConfig.STATUS.INACTIVE,
                    })
                );
                break;

            default:
                break;
        }
    };

    // const copyToClipboard = (text) => {
    //     navigator.clipboard.writeText(text);
    //     window.LadiUI.toastCustom('success', '', t('COMMON.COPIED'));
    // };

    const onChangeAddress = (address, addressType, fromShippingForm) => {
        let data;
        if (addressType == appConfig.ADDRESS_TYPE.BILLING) {
            setCustomerShipping((pre) => {
                let tg = { ...pre, ...address };
                return tg;
            });
            data = {
                order_id: orderData.order_id,
                billing_address: {
                    ...address,
                },
            };
        } else if (addressType == appConfig.ADDRESS_TYPE.BILLING_INFO) {
            setCustomerBilling((pre) => {
                let tg = { ...pre, ...address };
                return tg;
            });
            data = {
                order_id: orderData.order_id,
                billing_info: {
                    ...address,
                },
            };
        } else {
            setCustomerShipping((pre) => {
                let tg = { ...pre, ...address };
                return tg;
            });

            data = {
                order_id: orderData.order_id,
                shipping_address: {
                    ...address,
                },
            };
        }
        if (!fromShippingForm) {
            dispatch(orderActions.updateAddress(data));
        } else {
            dispatch(orderActions.updateShippingAddress(data));
        }
    };

    const cancelOrders = (reason) => {
        dispatch(orderActions.cancel(orderData.order_id, reason));
    };

    const confirmOrderCancel = (manipulation) => {
        if (manipulation == appConfig.MANIPULATION_ORDER.CANCEL_RESET.CODE) {
            cancelOrders('Xoá khi copy');
        }
        let orderCoppy = { ...orderData };
        delete orderCoppy.order_id;
        setShowModalConfirmCancel(false);
        history.push({
            pathname: '/orders/create',
            state: { order: orderCoppy },
        });

        // setOrderData(orderCoppy);
        // setEditOrder(true);
    };

    const confirmResendMail = (manipulation) => {
        resendMail();
        setShowModalResendMail(false);
    };

    const resendMail = () => {
        dispatch(orderActions.resendMail(orderData.order_id));
    };

    const handelAssign = (item) => {
        setAssign(item);
        dispatch(orderActions.assignToOther(orderData.order_id, item.ladi_uid));
    };

    const handelAssignOrder = (item) => {
        // setAssign(item);
        dispatch(orderActions.assign(orderData.order_id));
    };

    if (orderReducer && orderReducer.order_not_show) {
        return (
            <ConfirmModal
                id='confirm-order-not-show'
                className='visible'
                content={orderReducer.message}
                title={t('ORDER_NOT_SHOW_TITLE')}
                hasFooter={false}
                isCustomFooter={true}
                footer={
                    <button className={`ladiui btn btn-primary btn-sm`} onClick={() => history.push('/orders')}>
                        {'OK'}
                    </button>
                }
            />
        );
    }

    return (
        <div className='order-create-v2'>
            {orderLoading && <LoadingScene blur={true} />}
            <Header
                isGenerateCheckout={isGenerateCheckout}
                variantSelected={variantSelected}
                customerSelected={customerSelected}
                getLinkCheckout={getLinkCheckout}
                checkoutLink={checkoutLink}
                isEditableOrder={isEditableOrder}
                actionOrther={actionOrther}
                orderData={orderData}
                fullSubmit={fullSubmit}
                partialSubmit={partialSubmit}
                createDraft={createDraft}
                setShowModalCalcel={setShowModalCalcel}
            />
            <div className='order-create'>
                <div className='page-content ladiui content-max-width'>
                    <div className='content'>
                        {/* hiển thị khi update  */}
                        {orderData && orderData.order_id && orderData.reference_no ? (
                            <div className='ladiui label-18 display-flex-center gap-8'>
                                {`${t('ORDERS.SLIDEBAR_TITLE')} ${storeReducer.userInfo.currentStore.order_prefix}${orderData.reference_no
                                    }`}

                                {orderData.restock_all == appConfig.STATUS.ACTIVE && (
                                    <div className={`ladiui tag restock-all`}>{t('ORDERS.RESTOCK_ALL')}</div>
                                )}

                                {orderData.status == appConfig.ORDER_STATUS.CANCELED.CODE && (
                                    <div className={`ladiui tag order-canceled`}>{t('ORDERS.CANCEL')}</div>
                                )}
                                {/* trạng thái giao hàng  */}
                                <div
                                    className={`ladiui tag shipping-${orderData.shipping_status
                                        ? orderData.shipping_status.toLowerCase()
                                        : appConfig.SHIPPING_STATUS.PENDING.CODE.toLowerCase()
                                        }`}
                                >
                                    {orderDefined.getShippingStatus(orderData.shipping_status)}
                                </div>

                                {/* Trạng thái thanh toán  */}
                                <div
                                    className={`ladiui tag payment-${orderData.payment_status
                                        ? orderData.payment_status.toLowerCase()
                                        : appConfig.PAYMENT_STATUS.PENDING.CODE.toLowerCase()
                                        }`}
                                >
                                    {orderDefined.getPaymentStatus(orderData.payment_status)}
                                </div>
                            </div>
                        ) : (
                            <label className='ladiui label-18'> {t('ORDERS.ORDER_CREATE_LABEL')}</label>
                        )}

                        <div className='order-assign-store display-flex-center gap-12 mt-12'>
                            {orderData && orderData.order_id && orderData.created_at && (
                                <div>
                                    {`${t('ORDERS.CREATED_AT')} ${baseHelper.formatDateToStr(
                                        orderData.created_at,
                                        appConfig.DEFAULT_FORMAT_DATE_TIME
                                    )}`}
                                </div>
                            )}

                            <div className='order-assign display-flex-center'>
                                <span className='ladiui text-14 text-primary'>{t('ORDERS.ASSIGN_BY')}</span>

                                {/* {assign && assign.ladi_uid ? ( */}
                                <DropDownNew
                                    id='order_assign'
                                    data={staffs}
                                    onSelectItem={(item) => {
                                        handelAssign(item);
                                    }}
                                    currentKey={assign.ladi_uid}
                                    currentValue={assign.name || 'Chọn nhân viên xử lý'}
                                    _key={'ladi_uid'}
                                    _value={'name'}
                                    isShowBlockInputSearch={false}
                                />
                                {/* ) : (
                                    <div className='ladiui btn btn-primary btn-sm' onClick={handelAssignOrder}>
                                        Nhận đơn
                                    </div>
                                )} */}
                            </div>
                        </div>
                        <div className='order-info'>
                            <div className='order-product-payment'>
                                <OrderProducts
                                    ref={productsRef}
                                    variantSelected={variantSelected}
                                    setVariantSelected={VariantUpdate}
                                    isEditableOrder={isEditableOrder}
                                    orderData={orderData}
                                    setShowModalShipping={setShowModalShipping}
                                />
                                <OrderPaymentInfo
                                    variantSelected={variantSelected}
                                    removeShipping={removeShipping}
                                    applyShipping={applyShipping}
                                    shippingMethods={shippingMethods}
                                    shippingFee={shippingFee}
                                    isEditableOrder={isEditableOrder}
                                    orderData={orderData}
                                    removeDiscount={removeDiscount}
                                    applyDiscount={applyDiscount}
                                    selectDiscount={selectDiscount}
                                    customerSelected={customerSelected}
                                    openModalConfirmCreateOrder={openModalConfirmCreateOrder}
                                    getSubTotal={getSubTotal}
                                    getTotal={getTotal}
                                    isCanceled={isCanceled}
                                    restockAll={restockAll}
                                />
                                {orderData && orderData.order_id &&
                                    <div className='order-payment mt-20 ladiui container-place'>
                                        <div className='ladiui flex'>
                                            <input
                                                type='checkbox'
                                                checked={orderData.accept_policy}
                                                disabled
                                                className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                            />
                                            <span>Xác nhận đồng ý với chính sách và điều khoản sử dụng</span>
                                        </div>
                                    </div>
                                }
                                {/* hiển thị khi update  */}
                                {orderData && orderData.order_id && (
                                    <div className={`order-historys mt-20 ${orderData && orderData.order_id ? '' : 'hide'}`}>
                                        <div className='ladiui label-18 mb-20 '> {t('COMMON.ACTIVE')}</div>
                                        <History type={appConfig.HISTORY_FORM.ORDER} orderID={orderData.order_id} />
                                    </div>
                                )}
                            </div>
                            <div className='order-customer-info-tags'>
                                <OrderCustomer
                                    ref={customerRef}
                                    customerInfo={customerSelected}
                                    setCustomerInfo={setCustomerSelected}
                                    customerShipping={customerShipping}
                                    setCustomerShipping={setCustomerShipping}
                                    customerBilling={customerBilling}
                                    setCustomerBilling={setCustomerBilling}
                                    orderData={orderData}
                                    isEditableOrder={isEditableOrder}
                                />
                                <div className='order-tags mt-20 ladiui container-place'>
                                    <OrderTags ref={tagsRef} orderTag={orderData.tags || []} orderData={orderData} />
                                </div>
                                <div className='order-note mt-20 ladiui container-place'>
                                    <div className='ladiui label-18 pb-12'>{t('ORDERS.ORDER_NOTE_LABEL')}</div>
                                    <textarea
                                        className='order-note-value'
                                        placeholder={t('ORDERS.ORDER_NOTE_LABEL')}
                                        value={orderNote}
                                        onChange={(e) => setOrderNote(e.target.value)}
                                    />

                                    {/* hiển thị khi update  */}
                                    {/* {orderData && orderData.order_id && ( */}
                                    <div className='order-custom-fields'>
                                        <div
                                            className='ladiui label-12 pointer text-upper flex-center-between'
                                            onClick={() => setShowOrderCF(!isShowOrderCF)}
                                        >
                                            <span> {t('CUSTOM_FIELD.LABEL_ORDER_UPDATE')}</span>
                                            <i className='ladiui icon-new new-ldicon-arrow-down' />
                                        </div>

                                        <PanelCustomField
                                            ref={panelCustomFieldRef}
                                            customFields={orderData.custom_fields || []}
                                            groupType={appConfig.CUSTOM_FIELD.GROUP_TYPES.ORDER.CODE}
                                            actionType={appConfig.FORM_MODE.EDIT}
                                            orderID={orderData.order_id}
                                            isShowOrderCF={isShowOrderCF}
                                        />
                                    </div>
                                    {/* )} */}
                                </div>
                                {/* hiển thị khi update  */}
                                {orderData && orderData.order_id && (
                                    <div className='order-tracking mt-20 ladiui container-place'>
                                        <div className='more-info block-tracking-info'>
                                            {Object.values(orderDefined.ORDER_TRACKING_LIST).map((item, index) => {
                                                if (!orderData[item.field]) {
                                                    return (
                                                        <div key={index} className='item-tracking'>
                                                            <span className='ladiui text-14'>
                                                                <i className='ladi-icon icon-copy' />
                                                                <b>{item.name}:</b>
                                                            </span>
                                                        </div>
                                                    );
                                                }
                                                return (
                                                    <div
                                                        key={index}
                                                        className='item-tracking'
                                                        data-tooltip={orderData[item.field] ? orderData[item.field] : ''}
                                                        data-tooltip-position={orderData[item.field] ? 'top' : 'hide'}
                                                    >
                                                        <span className='ladiui text-14'>
                                                            <i
                                                                onClick={() =>
                                                                    baseHelper.copyToClipboard(
                                                                        orderData[item.field] ? orderData[item.field] : ''
                                                                    )
                                                                }
                                                                className='ladi-icon icon-copy'
                                                            />
                                                            <b>{item.name}:</b>
                                                        </span>

                                                        <span className='show-content'>
                                                            {orderData[item.field] ? orderData[item.field] : ''}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalOrderPayment
                visible={isShowModalPayment}
                hideModal={() => setShowModalPayment(false)}
                isPendingPaymentStatus={payment.status == appConfig.PAYMENT_STATUS.PENDING.CODE}
                total={getTotal()}
                submit={submit}
                fullSubmit={fullSubmit}
                isEditableOrder={isEditableOrder}
                paymentSelected={paymentSelected}
                setPaymentSelected={setPaymentSelected}
                setEmailNotification={setEmailNotification}
                emailNotification={emailNotification}
                orderData={orderData}
                markAsPaid={markAsPaid}
            />

            {
                isShowModalShipping && (
                    <ShippingDelivery
                        orderID={orderData.order_id}
                        shippingAddress={customerShipping}
                        addresses={customerSelected.addresses}
                        onChangeAddress={onChangeAddress}
                        isShowModalShipping={isShowModalShipping}
                        hideModalShipping={() => setShowModalShipping(false)}
                        weight={orderData.weight}
                        total={orderData.total}
                        note={orderData.shipping_note}
                        cod_amount={orderData.payment_status == appConfig.PAYMENT_STATUS.PENDING.CODE ? orderData.total : 0}
                        variantSelected={variantSelected}
                    />
                )
            }

            {
                isShowModalCancel && (
                    <ModalCancel
                        visible={isShowModalCancel}
                        onOk={cancelOrders}
                        onCancel={() => setShowModalCalcel(false)}
                        isLoading={orderLoading}
                    />
                )
            }
            {
                isShowModalConfirmCancel && (
                    <ModalConfirmCancel
                        visible={isShowModalConfirmCancel}
                        onOk={confirmOrderCancel}
                        onCancel={() => setShowModalConfirmCancel(false)}
                        isLoading={orderLoading}
                    />
                )
            }
            {
                isShowModalResendMail && (
                    <ModalConfirmResendMail
                        visible={isShowModalResendMail}
                        onOk={confirmResendMail}
                        onCancel={() => setShowModalResendMail(false)}
                        isLoading={orderLoading}
                    />
                )
            }
        </div >
    );
}

export default OrderCreateV2;
