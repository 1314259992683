import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Input from '../../../components/Input';

import courseAction from '../../../redux/futures/course/actions';
import * as courseTypes from '../../../redux/futures/course/types';

import DropdownCheckboxSearch from '../../../components/DropdownCheckboxSearch';

import { map, compact, includes } from 'lodash';

import appConfig from '../../../config/app';
import Dropdown from '../../../components/Dropdown';

// eslint-disable-next-line max-lines-per-function
function ModalChangeStudent(props) {
    const { t, onClose, isShow, selectedCustomer, onChangeSelectCustomerInput, productId } = props;
    const wrapperRef = useRef();
    const dispatch = useDispatch();

    const courseReducer = useSelector((state) => state.course);

    const inputsRef = new Set();

    let [dataStudent, setDataStudent] = useState({
        customer_id: '',
        first_name: '',
        email: '',
        phone: '',
    });



    useEffect(() => {
        if (courseReducer.action == courseTypes.UPDATE_CUSTOMER) {
            if (courseReducer.status) {
                onClose(false);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
    }, [courseReducer]);

    useEffect(() => {
        setDataStudent(selectedCustomer);
    }, [selectedCustomer]);

    const eventCancel = () => {
        onClose(false);
    };

    const onChangeDataStudent = (event) => {
        let { name, value } = event.target;
        setDataStudent({
            ...dataStudent,
            [name]: value,
        });
    };

    const handleAddStudent = () => {
        let errors = [];
        const _inputsRef = compact(Array.from(inputsRef));
        map(_inputsRef, (ref) => {
            if (ref.validate) {
                const error = ref.validate();
                if (error.length > 0) {
                    errors = errors.concat(error);
                }
            }
        });

        errors = compact(errors);

        if (errors.length > 0) {
            const errorMessage = errors.join('<br/>');
            window.LadiUI.toastCustom('danger', '', errorMessage);

            return;
        }

        let payload = {};
        payload = {
            customer_id: dataStudent.customer_id,
            first_name: dataStudent.first_name ? dataStudent.first_name : '',
            email: dataStudent.email ? dataStudent.email : '',
            phone: dataStudent.phone ? dataStudent.phone : '',
        };
        dispatch(courseAction.updateCustomer(payload));

    };

    let isLoadingCreateCustomer =
        courseReducer.loading && includes([courseTypes.CREATE_CUSTOMER, courseTypes.CREATE_MANY_CUSTOMERS], courseReducer.waiting);
    console.log('selectedCustomer ', selectedCustomer)
    return (
        <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-add-student'>
            <div className='ladiui modal-dialog modal-dialog-centered w-600' ref={wrapperRef}>
                <div className='ladiui modal-content '>
                    <div className='ladiui modal-header '>
                        <h2 className='ladiui modal-title '>Thay đổi thông tin học viên</h2>
                        <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                            <i className='ladiui icon icon-ldp-close'></i>
                        </button>
                    </div>
                    <div className='ladiui modal-body' style={{ position: 'initial' }}>


                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Họ và tên</label>
                            <Input
                                name='first_name'
                                validationName={'tên tài khoản'}
                                placeholder={'Nhập tên tài khoản'}
                                value={dataStudent.first_name}
                                onChange={(e) => onChangeDataStudent(e)}
                                ref={(ref) => inputsRef.add(ref)}
                                validations={{ isRequired: true }}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Email học viên</label>
                            <Input
                                name='email'
                                // validationName={'email'}
                                // placeholder={'Nhập email'}
                                value={dataStudent.email}
                                // onChange={(e) => onChangeDataStudent(e)}
                                // ref={(ref) => inputsRef.add(ref)}
                                // validations={{ isRequired: true }}
                                disabled={true}
                            />
                        </div>
                        <div className='ladiui form-group'>
                            <label className='ladiui-label'>Số điện thoại</label>
                            <Input
                                type='number'
                                name='phone'
                                validationName={'số điện thoại'}
                                placeholder={'Nhập số điện thoại'}
                                value={dataStudent.phone}
                                onChange={(e) => onChangeDataStudent(e)}
                                ref={(ref) => inputsRef.add(ref)}
                                validations={{ isRequired: true }}
                            />
                        </div>


                    </div>

                    <div className='ladiui modal-footer'>
                        <div className='ladiui footer-modal-btn'>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className='ladiui btn btn-secondary'
                                onClick={eventCancel}
                            >
                                {t('ACTIONS.REMOVE')}
                            </button>
                            <button
                                type='button'
                                data-dismiss='modal'
                                data-target='add-product'
                                className={`ladiui btn btn-primary ${isLoadingCreateCustomer ? 'loader' : ''}`}
                                onClick={() => handleAddStudent()}
                            >
                                {t('ACTIONS.SAVE')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withTranslation()(ModalChangeStudent);
