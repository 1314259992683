/** @format */

import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { find, compact, map, includes, remove } from 'lodash';
import Modal from '../../../components/Modal';
import appConfig from '../../../config/app';
import { useTranslation } from 'react-i18next';
import produce from 'immer';
import Input from '../../../components/Input';
import ConfirmModal from '../../../components/ConfirmModal';
import baseHelper from '../../../helpers/BaseHelper';

import apiActions from '../../../redux/futures/api/actions';

import settingActions from '../../../redux/futures/setting/actions';
import * as settingTypes from '../../../redux/futures/setting/types';

import bankHubActions from '../../../redux/futures/bank_hub/actions';
import * as bankHubTypes from '../../../redux/futures/bank_hub/types';

import paymentActions from '../../../redux/futures/payment/actions';
import * as paymentTypes from '../../../redux/futures/payment/types';
import { deprecationHandler } from 'moment';
import app from '../../../config/app';

import ModalVnptEpay from './ModalPayment/ModalVnptEpay';
import ModalZaloPay from './ModalPayment/ModalZaloPay';
import ModalMomoPay from './ModalPayment/ModalMomoPay';
import ModalVnPay from './ModalPayment/ModalVnPay';
import ModalAlePay from './ModalPayment/ModalAlePay';
import ModalShopeePay from './ModalPayment/ModalShopeePay';
import ModalPaypal from './ModalPayment/ModalPaypal';
import ModalStripe from './ModalPayment/ModalStripe';
import ModalCod from './ModalPayment/ModalCod';
import ModalBank from './ModalPayment/ModalBank';
import ModalFinan from './ModalPayment/ModalFinan';

import ModalAppotaPay from './ModalPayment/ModalAppotaPay';

import ItemPayment from './ModalPayment/ItemPayment';
import ModalSettingRecieveNotificationBank from './ModalPayment/ModalSettingRecieveNotificationBank';
import ModalOTP from './ModalPayment/ModalOTP';
import ModalNotifyConnectSuccessfulApi from './ModalPayment/ModalNotifyConnectSuccessfulApi';
import ModalDisconnectNotificationBank from './ModalPayment/ModalDisconnectNotificationBank';
import { config } from 'localforage';

PageSettingPayment.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
};

export default function PageSettingPayment(props) {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const queryStrings = new URLSearchParams(location.search);

    const settingReducer = useSelector((state) => state.setting);
    const bankHubReducer = useSelector((state) => state.bankHub);
    const paymentReducer = useSelector((state) => state.payment);
    const [requestBankHub, setRequestBankHub] = useState({});
    const dispatch = useDispatch();
    const storeReducer = useSelector((state) => state.store);

    const currentStore = storeReducer.userInfo.currentStore;

    const initZaloPayData = {
        method_name: t('PAYMENTS.ZALO_PAY.DEFAULT_METHOD_NAME'),
        app_id: '',
        app_user: '',
        key_1: '',
        key_2: '',
        rsa_public_key: '',
        payment_guide: '',
        active_payment_methods: map(appConfig.PAYMENT_METHODS.ZALO_PAY.TYPE, (item) => item.CODE),
        active_payment_methods_note: {},
    };

    const initVNPayData = {
        method_name: t('PAYMENTS.VN_PAY.DEFAULT_METHOD_NAME'),
        tmn_code: '',
        secret_key: '',
        payment_guide: '',
        active_payment_methods: map(appConfig.PAYMENT_METHODS.VN_PAY.TYPE, (item) => item.CODE),
        active_payment_methods_note: {},
    };

    const initMomoData = {
        method_name: t('PAYMENTS.MOMO.DEFAULT_METHOD_NAME'),
        partner_code: '',
        access_key: '',
        secret_key: '',
        payment_guide: '',
        active_payment_methods: [],
    };

    const initAlePayData = {
        method_name: t('PAYMENTS.ALE_PAY.DEFAULT_METHOD_NAME'),
        token_key: '',
        checksum_key: '',
        encrypt_key: '',
        payment_guide: '',
        active_payment_methods: map(appConfig.PAYMENT_METHODS.ALE_PAY.TYPE, (item) => item.CODE),
        active_payment_methods_note: {},
    };

    const initVNPTEPayData = {
        method_name: t('PAYMENTS.VNPT_E_PAY.DEFAULT_METHOD_NAME'),
        merchant_id: '',
        merchant_key: '',
        payment_guide: '',
        expired_time: appConfig.PAYMENT_EXPIRED_TIME_VA_DEFAULT,
        active_payment_methods: map(appConfig.PAYMENT_METHODS.VNPT_EPAY.TYPE, (item) => item.CODE),
        active_payment_methods_note: {},
    };

    const initAPPOTAPayData = {
        method_name: t('PAYMENTS.APPOTA_PAY.DEFAULT_METHOD_NAME'),
        partner_code: '',
        expired_time: appConfig.PAYMENT_EXPIRED_TIME_VA_DEFAULT,
        api_key: '',
        secret_key: '',
        active_payment_methods: map(appConfig.PAYMENT_METHODS.APPOTA.TYPE, (item) => item.CODE),
        active_payment_methods_note: {},
        account_name: '',
    };

    const initShopeePayData = {
        method_name: t('PAYMENTS.SHOPEE_PAY.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        merchant_ext_id: '',
        store_ext_id: '',
        pc_client_id: '',
        pc_secret_key: '',
        mb_client_id: '',
        mb_secret_key: '',
    };

    const initPayPalData = {
        method_name: t('PAYMENTS.PAYPAL.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        client_id: '',
        client_secret: '',
    };

    const initStripeData = {
        method_name: t('PAYMENTS.STRIPE.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        secret_key: '',
        public_key: '',
    };

    const initCodData = {
        method_name: t('PAYMENTS.COD.DEFAULT_METHOD_NAME'),
        payment_guide: t('PAYMENTS.COD.DEFAULT_PAYMENT_GUIDE'),
    };
    const initBankData = {
        method_name: t('PAYMENTS.BANK.DEFAULT_METHOD_NAME'),
        banks: [
            {
                accountNumber: '',
                accountName: '',
                transferContentPrefix: '',
                transactionContent: '',
                template_id: 'compact',
                bank: {},
                autoConfirmPayment: false,
            },
        ],
        payment_guide: '',
    };

    const initFinanPayData = {
        method_name: t('PAYMENTS.FINAN.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        secret_key: '',
    };

    const [zaloPayAccount, setZaloPayAccount] = useState(initZaloPayData);
    const [zaloPayMode, setZaloPayMode] = useState(appConfig.MODE.TEST);
    const [isShowZaloPaySetting, setIsShowZaloPaySetting] = useState(false);

    const [vnPayAccount, setVnPayAccount] = useState(initVNPayData);
    const [vnPayMode, setVnPayMode] = useState(appConfig.MODE.TEST);
    const [isShowVnPaySetting, setIsShowVnPaySetting] = useState(false);

    const [selectedGateway, setSelectedGateway] = useState({
        name: '',
        code: '',
    });

    const [momoMode, setMomoMode] = useState(appConfig.MODE.TEST);
    const [isShowMomoSetting, setIsShowMomoSetting] = useState(false);
    const [momoAccount, setMomoAccount] = useState(initMomoData);

    const [alePayMode, setAlePayMode] = useState(appConfig.MODE.TEST);
    const [isShowAlePaySetting, setIsShowAlePaySetting] = useState(false);
    const [alePayAccount, setAlePayAccount] = useState(initAlePayData);

    const [codMode, setCodMode] = useState(appConfig.MODE.TEST);
    const [isShowCodSetting, setIsShowCodSetting] = useState(false);
    const [codAccount, setCodAccount] = useState(initCodData);

    const [bankMode, setBankMode] = useState(appConfig.MODE.TEST);
    const [isShowBankSetting, setIsShowBankSetting] = useState(false);
    const [bankAccount, setBankAccount] = useState(initBankData);
    const [listBankHubs, setListBankHubs] = useState([]);

    const [vnptEPayAccount, setVNPTEPayAccount] = useState(initVNPTEPayData);
    const [vnptEPayMode, setVNPTEPayMode] = useState(appConfig.MODE.TEST);
    const [isShowVNPTEPaySetting, setIsShowVNPTEPaySetting] = useState(false);

    const [appotaPayAccount, setAPPOTAPayAccount] = useState(initAPPOTAPayData);
    const [appotaPayMode, setAPPOTAPayMode] = useState(appConfig.MODE.TEST);
    const [isShowAPPOTAPaySetting, setIsShowAPPOTAPaySetting] = useState(false);

    const [shopeePayAccount, setShopeePayAccount] = useState(initShopeePayData);
    const [shopeePayMode, setShopeePayMode] = useState(appConfig.MODE.TEST);
    const [isShowShopeePaySetting, setIsShowShopeePaySetting] = useState(false);

    const [payPalAccount, setPayPalAccount] = useState(initPayPalData);
    const [payPalMode, setPaypalModal] = useState(appConfig.MODE.TEST);
    const [isShowPayPalSetting, setIsShowPayPalSetting] = useState(false);

    const [stripeAccount, setStripeAccount] = useState(initStripeData);
    const [stripeMode, setStripeModal] = useState(appConfig.MODE.TEST);
    const [isShowStripeSetting, setIsShowStripeSetting] = useState(false);

    const [finanPayAccount, setFinanPayAccount] = useState(initFinanPayData);
    const [finanPayMode, setFinanPayMode] = useState(appConfig.MODE.TEST);
    const [isShowFinanPaySetting, setIsShowFinanPaySetting] = useState(false);

    const [isLoadedListGateway, setIsLoadedListGateway] = useState(false);
    const [isShowSettingNotification, setIsShowSettingNotification] = useState(false);
    const [isShowModalOTP, setIsShowModalOTP] = useState(false);
    const [isShowModalNotifyConnectSuccessfulApi, setIsShowModalNotifyConnectSuccessfulApi] = useState(false);
    const [isShowModalDisconnectNotificationBank, setIsShowModalDisconnectNotificationBank] = useState(false);

    //DATA UPDATE
    const [isShowBtnSaveData, setIsShowBtnSaveData] = useState(false);
    const [showBtnPayment, setShowBtnPayment] = useState('');
    const [dataUpdateVNPTEpay, setDataUpdateVNPTEpay] = useState({
        method_name: t('PAYMENTS.VNPT_E_PAY.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        expired_time: appConfig.PAYMENT_EXPIRED_TIME_VA_DEFAULT,
        active_payment_methods: '',
        active_payment_methods_note: {},
    });
    const [dataUpdateZaloPay, setDataUpdateZaloPay] = useState({
        payment_guide: '',
        active_payment_methods: '',
        active_payment_methods_note: {},
    });
    const [dataUpdateMomo, setDataUpdateMomo] = useState({
        method_name: t('PAYMENTS.MOMO.DEFAULT_METHOD_NAME'),
        payment_guide: '',
    });
    const [dataUpdateVNPay, setDataUpdateVNPay] = useState({
        method_name: t('PAYMENTS.VN_PAY.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        active_payment_methods: '',
        active_payment_methods_note: {},
    });
    const [dataUpdateAlePay, setDataUpdateAlePay] = useState({
        method_name: t('PAYMENTS.ALE_PAY.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        active_payment_methods: '',
        active_payment_methods_note: {},
    });
    const [dataUpdateShopeePay, setDataUpdateShopeePay] = useState({
        method_name: t('PAYMENTS.SHOPEE_PAY.DEFAULT_METHOD_NAME'),
        payment_guide: '',
    });
    const [dataUpdateCod, setDataUpdateCod] = useState({
        method_name: t('PAYMENTS.COD.DEFAULT_METHOD_NAME'),
        description: t('PAYMENTS.COD.DEFAULT_METHOD_DESCRIPTION'),
        payment_guide: '',
    });
    const [dataUpdateBank, setDataUpdateBank] = useState({
        method_name: t('PAYMENTS.BANK.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        banks: [
            {
                accountNumber: '',
                accountName: '',
                transferContentPrefix: '',
                transactionContent: '',
                template_id: 'compact',
                bank: {},
                autoConfirmPayment: false,
                bank_webhook_url: false
            },
        ],
    });
    const [dataUpdatePayPal, setDataUpdatePayPal] = useState({
        method_name: t('PAYMENTS.PAYPAL.DEFAULT_METHOD_NAME'),
        payment_guide: '',
    });
    const [dataUpdateStripe, setDataUpdateStripe] = useState({
        method_name: t('PAYMENTS.STRIPE.DEFAULT_METHOD_NAME'),
        payment_guide: '',
    });

    const [dataUpdateFinan, setDataUpdateFinan] = useState({
        method_name: t('PAYMENTS.FINAN.DEFAULT_METHOD_NAME'),
        payment_guide: '',
        method_name: '',
    });

    const [dataUpdateAPPOTAPay, setDataUpdateAPPOTAPay] = useState({
        method_name: t('PAYMENTS.APPOTA_PAY.DEFAULT_METHOD_NAME'),
        expired_time: appConfig.PAYMENT_EXPIRED_TIME_VA_DEFAULT,
        payment_guide: '',
        active_payment_methods: '',
        active_payment_methods_note: {},
        account_name: '',
        method_name: '',
    });

    // Ref
    const zaloPayRef = new Set();
    const vnPayRef = new Set();
    const momoRef = new Set();
    const alePayRef = new Set();
    const codRef = new Set();
    const bankRef = new Set();
    const vnptEPayRef = new Set();
    const shopeePayRef = new Set();
    const paypalRef = new Set();
    const stripeRef = new Set();
    const appotaPayRef = new Set();
    const finanRef = new Set();

    useEffect(() => {

        if (bankHubReducer.action == bankHubTypes.LIST_BANK_HUB) {
            if (bankHubReducer.status) {

                if (bankHubReducer.banks && bankHubReducer.banks.length > 0) {
                    // console.log('dataUpdateBanks =', dataUpdateBank);
                    setListBankHubs(bankHubReducer.banks || []);
                    for (let i = 0; i < bankHubReducer.banks.length; i++) {
                        if (bankHubReducer.banks[i].bank_api_connected == '1') {
                            if (dataUpdateBank && dataUpdateBank.banks.length > 0 && dataUpdateBank.banks[0].bank.code == "MB" && dataUpdateBank.banks[0].autoConfirmPayment) {
                                setDataUpdateBank(produce(dataUpdateBank, (draft) => {
                                    draft.banks[0].accountName = bankHubReducer.banks[i].account_holder_name;
                                    draft.banks[0].accountNumber = bankHubReducer.banks[i].account_number;
                                }));
                            }
                            if (bankAccount && bankAccount.banks.length > 0 && bankAccount.banks[0].bank.code == "MB" && bankAccount.banks[0].autoConfirmPayment) {
                                setBankAccount(
                                    produce(bankAccount, (draft) => {
                                        draft.banks[0].accountName = bankHubReducer.banks[i].account_holder_name;
                                        draft.banks[0].accountNumber = bankHubReducer.banks[i].account_number;
                                    })
                                );

                            }
                        }
                    }
                }

            }
        }



    }, [bankHubReducer]);


    useEffect(() => {
        dispatch(paymentActions.listGateways(true));

        if (queryStrings.get('is_viet_qr')) {
            setIsShowBankSetting(true);
        }
        if (queryStrings.get('selected_method')) {
            switch (queryStrings.get('selected_method')) {
                case appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE:
                    setIsShowZaloPaySetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.MOMO.CODE:
                    setIsShowMomoSetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.VN_PAY.CODE:
                    setIsShowVnPaySetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE:
                    setIsShowAlePaySetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.COD.CODE:
                    setIsShowCodSetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.BANK.CODE:
                    setIsShowBankSetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE:
                    setIsShowVNPTEPaySetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE:
                    setIsShowShopeePaySetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.PAYPAL.CODE:
                    setIsShowPayPalSetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.STRIPE.CODE:
                    setIsShowStripeSetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.APPOTA.CODE:
                    setIsShowAPPOTAPaySetting(true);
                    break;
                case appConfig.PAYMENT_PARTNERS.FINAN.CODE:
                    setIsShowFinanPaySetting(true);
                    break;
            }
        }
    }, []);

    useEffect(() => {
        if (isShowBankSetting) {
            dispatch(apiActions.list());
            if (dataUpdateBank && dataUpdateBank.banks.length > 0 && dataUpdateBank.banks[0].bank.code == "MB") {
                dispatch(bankHubActions.listBankHub({}));
            }
        }
    }, [isShowBankSetting]);

    useEffect(() => {
        window.LadiUI.init();
        window.LadiUI.customInit();
    });

    useEffect(() => {

        if (
            settingReducer.action === settingTypes.SETTING_PAYMENT_CONNECT ||
            settingReducer.action === settingTypes.SETTING_PAYMENT_DISCONNECT
        ) {
            if (settingReducer.status) {
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                window.LadiUI.closeModal('confirm-disconnect-payment');

                switch (selectedGateway.code) {
                    case appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE:
                        setIsShowZaloPaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.MOMO.CODE:
                        setIsShowMomoSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.VN_PAY.CODE:
                        setIsShowVnPaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE:
                        setIsShowAlePaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.COD.CODE:
                        setIsShowCodSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.BANK.CODE:
                        setIsShowBankSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE:
                        setIsShowVNPTEPaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE:
                        setIsShowShopeePaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.PAYPAL.CODE:
                        setIsShowPayPalSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.STRIPE.CODE:
                        setIsShowStripeSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.FINAN.CODE:
                        setIsShowFinanPaySetting(false);
                        break;
                }

                dispatch(paymentActions.listGateways(true));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }

        if (settingReducer.action == settingTypes.SETTING_PAYMENT_INACTIVE) {
            if (settingReducer.status) {
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                window.LadiUI.closeModal('confirm-inactive-payment');
                dispatch(paymentActions.listGateways(true));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }

        if (settingReducer.action == settingTypes.SETTING_PAYMENT_ACTIVE) {
            if (settingReducer.status) {
                window.LadiUI.toastCustom('success', '', settingReducer.message);
                dispatch(paymentActions.listGateways(true));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }

        if (settingReducer.action == settingTypes.SETTING_PAYMENT_GATEWAY) {
            if (settingReducer.status) {
                switch (selectedGateway.code) {
                    case appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE:
                        setIsShowZaloPaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.MOMO.CODE:
                        setIsShowMomoSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.VN_PAY.CODE:
                        setIsShowVnPaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE:
                        setIsShowAlePaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.COD.CODE:
                        setIsShowCodSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.BANK.CODE:
                        setIsShowBankSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE:
                        setIsShowVNPTEPaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE:
                        setIsShowShopeePaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.PAYPAL.CODE:
                        setIsShowPayPalSetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.STRIPE.CODE:
                        setIsShowStripeSetting(false);
                    case appConfig.PAYMENT_PARTNERS.APPOTA.CODE:
                        setIsShowAPPOTAPaySetting(false);
                        break;
                    case appConfig.PAYMENT_PARTNERS.FINAN.CODE:
                        setIsShowFinanPaySetting(false);
                        break;
                }

                dispatch(paymentActions.listGateways(true));
                setIsShowBtnSaveData(false);
                window.LadiUI.toastCustom('success', '', settingReducer.message);
            } else {
                window.LadiUI.showErrorMessage('Thông báo', settingReducer.message, 'OK');
            }
        }
    }, [settingReducer]);

    useEffect(() => {
        if (paymentReducer.action == paymentTypes.PAYMENT_LIST_GATEWAYS) {
            setIsLoadedListGateway(true);
            const paymentGatewayConfiguredVNPTEpay = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE);
            const paymentGatewayConfiguredZaloPay = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE);
            const paymentGatewayConfiguredMomo = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.MOMO.CODE);
            const paymentGatewayConfiguredVNPay = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.VN_PAY.CODE);
            const paymentGatewayConfiguredAlePay = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE);
            const paymentGatewayConfiguredCode = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.COD.CODE);
            const paymentGatewayConfiguredBank = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.BANK.CODE);
            const paymentGatewayConfiguredShoppePay = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE);
            const paymentGatewayConfiguredPayPal = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.PAYPAL.CODE);
            const paymentGatewayConfiguredStripe = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.STRIPE.CODE);
            const paymentGatewayConfiguredAPPOTAPay = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.APPOTA.CODE);

            const paymentGatewayConfiguredFinan = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.FINAN.CODE);

            if (paymentGatewayConfiguredVNPTEpay && paymentGatewayConfiguredVNPTEpay.config) {
                setDataUpdateVNPTEpay({
                    method_name: paymentGatewayConfiguredVNPTEpay.config.method_name || t('PAYMENTS.VNPT_E_PAY.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredVNPTEpay.config.payment_guide,
                    expired_time: paymentGatewayConfiguredVNPTEpay.config.expired_time,
                    active_payment_methods: paymentGatewayConfiguredVNPTEpay.config.active_payment_methods,
                    active_payment_methods_note: paymentGatewayConfiguredVNPTEpay.config.active_payment_methods_note || {},
                    description: paymentGatewayConfiguredVNPTEpay.description || t('PAYMENTS.VNPT_E_PAY.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredZaloPay && paymentGatewayConfiguredZaloPay.config) {
                setDataUpdateZaloPay({
                    method_name: paymentGatewayConfiguredZaloPay.config.method_name || t('PAYMENTS.ZALO_PAY.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredZaloPay.config.payment_guide,
                    active_payment_methods: paymentGatewayConfiguredZaloPay.config.active_payment_methods,
                    active_payment_methods_note: paymentGatewayConfiguredZaloPay.config.active_payment_methods_note || {},
                    description: paymentGatewayConfiguredZaloPay.description || t('PAYMENTS.ZALO_PAY.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredMomo && paymentGatewayConfiguredMomo.config) {
                setDataUpdateMomo({
                    method_name: paymentGatewayConfiguredMomo.config.method_name || t('PAYMENTS.MOMO.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredMomo.config.payment_guide,
                    description: paymentGatewayConfiguredMomo.description || t('PAYMENTS.MOMO.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredVNPay && paymentGatewayConfiguredVNPay.config) {
                setDataUpdateVNPay({
                    method_name: paymentGatewayConfiguredVNPay.config.method_name || t('PAYMENTS.VN_PAY.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredVNPay.config.payment_guide,
                    active_payment_methods: paymentGatewayConfiguredVNPay.config.active_payment_methods,
                    active_payment_methods_note: paymentGatewayConfiguredVNPay.config.active_payment_methods_note || {},
                    description: paymentGatewayConfiguredVNPay.description || t('PAYMENTS.VN_PAY.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredAlePay && paymentGatewayConfiguredAlePay.config) {
                setDataUpdateAlePay({
                    method_name: paymentGatewayConfiguredAlePay.config.method_name || t('PAYMENTS.ALE_PAY.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredAlePay.config.payment_guide,
                    active_payment_methods: paymentGatewayConfiguredAlePay.config.active_payment_methods,
                    active_payment_methods_note: paymentGatewayConfiguredAlePay.config.active_payment_methods_note || {},
                    description: paymentGatewayConfiguredAlePay.description || t('PAYMENTS.ALE_PAY.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredCode && paymentGatewayConfiguredCode.config) {
                setDataUpdateCod({
                    method_name: paymentGatewayConfiguredCode.config.method_name || t('PAYMENTS.COD.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredCode.config.payment_guide,
                    description: paymentGatewayConfiguredCode.description || t('PAYMENTS.COD.DEFAULT_METHOD_DESCRIPTION'),
                });
            }
            if (paymentGatewayConfiguredBank && paymentGatewayConfiguredBank.config) {
                setDataUpdateBank({
                    method_name: paymentGatewayConfiguredBank.config.method_name || t('PAYMENTS.BANK.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredBank.config.payment_guide,
                    banks: paymentGatewayConfiguredBank.config.banks || [
                        {
                            accountNumber: '',
                            accountName: '',
                            transferContentPrefix: '',
                            transactionContent: '',
                            template_id: 'compact',
                            bank: {},
                            autoConfirmPayment: false,
                        },
                    ],
                    description: paymentGatewayConfiguredBank.description || t('PAYMENTS.BANK_DESCRIPTION')
                });
            }
            if (paymentGatewayConfiguredShoppePay && paymentGatewayConfiguredShoppePay.config) {
                setDataUpdateShopeePay({
                    method_name: paymentGatewayConfiguredShoppePay.config.method_name || t('PAYMENTS.SHOPEE_PAY.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredShoppePay.config.payment_guide,
                    description: paymentGatewayConfiguredShoppePay.description || t('PAYMENTS.SHOPEE_PAY.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredPayPal && paymentGatewayConfiguredPayPal.config) {
                setDataUpdatePayPal({
                    method_name: paymentGatewayConfiguredPayPal.config.method_name || t('PAYMENTS.PAYPAL.DEFAULT_METHOD_NAME'),
                    payment_guide: paymentGatewayConfiguredPayPal.config.payment_guide,
                    description: paymentGatewayConfiguredPayPal.description || t('PAYMENTS.PAYPAL.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredStripe && paymentGatewayConfiguredStripe.config) {
                setDataUpdateStripe({
                    payment_guide: paymentGatewayConfiguredStripe.config.payment_guide,
                    method_name: paymentGatewayConfiguredStripe.config.method_name,
                    description: paymentGatewayConfiguredStripe.description || t('PAYMENTS.STRIPE.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredFinan && paymentGatewayConfiguredFinan.config) {
                setDataUpdateFinan({
                    payment_guide: paymentGatewayConfiguredFinan.config.payment_guide,
                    method_name: paymentGatewayConfiguredFinan.config.method_name,
                    description: paymentGatewayConfiguredFinan.description || t('PAYMENTS.FINAN.LB_DESCRIPTION_CONFIGURED')
                });
            }
            if (paymentGatewayConfiguredAPPOTAPay && paymentGatewayConfiguredAPPOTAPay.config) {
                setDataUpdateAPPOTAPay({
                    expired_time: paymentGatewayConfiguredAPPOTAPay.config.expired_time,
                    payment_guide: paymentGatewayConfiguredAPPOTAPay.config.payment_guide,
                    active_payment_methods: paymentGatewayConfiguredAPPOTAPay.config.active_payment_methods,
                    active_payment_methods_note: paymentGatewayConfiguredAPPOTAPay.config.active_payment_methods_note || {},
                    account_name: paymentGatewayConfiguredAPPOTAPay.config.account_name,
                    method_name: paymentGatewayConfiguredAPPOTAPay.config.method_name,
                    description: paymentGatewayConfiguredAPPOTAPay.description || t('PAYMENTS.APPOTA_PAY.LB_DESCRIPTION_CONFIGURED')
                });
            }
        }
    }, [paymentReducer]);

    const onChangeZaloPayInput = (event) => {
        setZaloPayAccount(
            produce(zaloPayAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeVnPayInput = (event) => {
        setVnPayAccount(
            produce(vnPayAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeMomoInput = (event) => {
        setMomoAccount(
            produce(momoAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeAlePayInput = (event) => {
        setAlePayAccount(
            produce(alePayAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeShopeePayInput = (event) => {
        setShopeePayAccount(
            produce(shopeePayAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangePaypalInput = (event) => {
        setPayPalAccount(
            produce(payPalAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeStripeInput = (event) => {
        setStripeAccount(
            produce(stripeAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeFinanInput = (event) => {
        setFinanPayAccount(
            produce(finanPayAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeCodInput = (event) => {
        setCodAccount(
            produce(codAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeBankInput = (event) => {
        setBankAccount(
            produce(bankAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeBankDataInput = (event, index) => {
        let { name, value } = event.target;
        const regex = /^[a-zA-Z0-9]{0,10}$/;
        if (name == 'transferContentPrefix') {
            if (regex.test(value)) {
                setBankAccount(
                    produce(bankAccount, (draft) => {
                        draft.banks[index][name] = value.toUpperCase();
                    })
                );
            } else {
                window.LadiUI.toastCustom('danger', '', 'Tiền tố không chứa các ký tự đặc biệt, tối đa 10 ký tự!');
            }
        } else {
            if (name == 'autoConfirmPayment') {
                value = event.target.checked;
            }
            // console.log(' autoConfirmPayment ', value, '-listBankHubs:', listBankHubs.length, '-', bankAccount.banks);
            if (value && listBankHubs.length > 0) {
                if (bankAccount && bankAccount.banks.length > 0 && bankAccount.banks[0].bank.code == "MB") {
                    // console.log(" procedure ")
                    setBankAccount(
                        produce(bankAccount, (draft) => {
                            draft.banks[index].accountName = listBankHubs[0].account_holder_name;
                            draft.banks[index].accountNumber = listBankHubs[0].account_number;
                            draft.banks[index][name] = value;
                        })
                    );

                }
            } else {
                setBankAccount(
                    produce(bankAccount, (draft) => {
                        draft.banks[index][name] = value;
                    })
                );
            }
        }
    };

    const onChangeBankWebhook = (urlWebhook) => {
        setDataUpdateBank(
            produce(dataUpdateBank, (draft) => {
                draft.banks[0].bank_webhook_url = urlWebhook;
            })
        );
    }

    const onChangeBankDataUpdateInput = (event, index) => {
        let { name, value } = event.target;
        const regex = /^[a-zA-Z0-9]{0,10}$/;
        if (name == 'transferContentPrefix') {
            if (regex.test(value)) {
                setDataUpdateBank(
                    produce(dataUpdateBank, (draft) => {
                        draft.banks[index][name] = value.toUpperCase();
                    })
                );
            } else {
                window.LadiUI.toastCustom('danger', '', 'Tiền tố không chứa các ký tự đặc biệt, tối đa 10 ký tự!');
            }
        } else {
            if (name == 'autoConfirmPayment') {
                value = event.target.checked;
            }
            // console.log(' autoConfirmPayment 222 ', value);
            if (value && listBankHubs.length > 0) {
                if (dataUpdateBank && dataUpdateBank.banks.length > 0 && dataUpdateBank.banks[0].bank.code == "MB") {
                    setDataUpdateBank(produce(dataUpdateBank, (draft) => {
                        draft.banks[index].accountName = listBankHubs[0].account_holder_name;
                        draft.banks[index].accountNumber = listBankHubs[0].account_number;
                        draft.banks[index][name] = value;
                    }));
                }
            } else {
                setDataUpdateBank(
                    produce(dataUpdateBank, (draft) => {
                        draft.banks[index][name] = value;
                    })
                );
            }
        }
        setIsShowBtnSaveData(true);
        setShowBtnPayment('BANK');
    };

    const selectedDataBank = (bankItem, index) => {
        let _bankItem = produce(bankAccount, (draft) => {
            draft.banks[index].bank = { ...bankItem };
            draft.banks[index].autoConfirmPayment = false;
        });

        setBankAccount(_bankItem);
        if (bankItem.code == "MB") {
            dispatch(bankHubActions.listBankHub({}));
        }
    };
    useEffect(() => {
        // console.log('bankAccount = ', dataUpdateBank)
        if (dataUpdateBank && dataUpdateBank.banks.length > 0 && dataUpdateBank.banks[0].bank.code == "MB") {
            dispatch(bankHubActions.listBankHub({}));
        }
    }, [dataUpdateBank]);


    const selectedDataUpdateBank = (bankItem, index) => {
        let _bankItem = produce(dataUpdateBank, (draft) => {
            draft.banks[index].bank = { ...bankItem };
            draft.banks[index].autoConfirmPayment = false;
        });

        setDataUpdateBank(_bankItem);
        setIsShowBtnSaveData(true);
        setShowBtnPayment('BANK');
        // console.log(bankItem)
        if (bankItem.code == "MB") {
            dispatch(bankHubActions.listBankHub({}));
        }
    };

    const onChangeVNPTEPayInput = (event) => {
        const initVNPTEPayData = {
            method_name: t('PAYMENTS.VNPT_E_PAY.DEFAULT_METHOD_NAME'),
            merchant_id: '',
            merchant_key: '',
            payment_guide: '',
            expired_time: appConfig.PAYMENT_EXPIRED_TIME_VA_DEFAULT,
        };

        setVNPTEPayAccount(
            produce(vnptEPayAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const onChangeAPPOTAPayInput = (event) => {
        const initAPPOTAPayData = {
            method_name: t('PAYMENTS.APPOTA_PAY.DEFAULT_METHOD_NAME'),
            merchant_id: '',
            merchant_key: '',
            payment_guide: '',
            expired_time: appConfig.PAYMENT_EXPIRED_TIME_VA_DEFAULT,
        };

        setAPPOTAPayAccount(
            produce(appotaPayAccount, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
    };

    const handleCheckActivePaymentVNPTEPay = (event) => {
        const { value } = event.target;

        setVNPTEPayAccount(
            produce(vnptEPayAccount, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                const active_payment_methods_note = draft.active_payment_methods_note;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
    };

    const handleCheckActivePaymentAPPOTAPay = (event) => {
        const { value } = event.target;

        setAPPOTAPayAccount(
            produce(appotaPayAccount, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                const active_payment_methods_note = draft.active_payment_methods_note;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
    };

    const handleCheckActivePaymentNoteVNPTPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        setVNPTEPayAccount(
            produce(vnptEPayAccount, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
    };

    const handleCheckActivePaymentNoteAPPOTAPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        setAPPOTAPayAccount(
            produce(appotaPayAccount, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
    };

    const handleChangeAccountNameAPPOTAPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        let nameUpperCase = value.toUpperCase();

        setAPPOTAPayAccount({
            ...appotaPayAccount,
            account_name: nameUpperCase,
        });
    };

    const handleCheckActivePaymentZaloPay = (event) => {
        const { value } = event.target;

        setZaloPayAccount(
            produce(zaloPayAccount, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                const active_payment_methods_note = draft.active_payment_methods_note;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
    };

    const handleCheckActivePaymentNoteZaloPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        setZaloPayAccount(
            produce(zaloPayAccount, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
    };

    const handleCheckActivePaymentVNPay = (event) => {
        const { value } = event.target;

        setVnPayAccount(
            produce(vnPayAccount, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                const active_payment_methods_note = draft.active_payment_methods_note;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
    };

    const handleCheckActivePaymentNoteVNPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        setVnPayAccount(
            produce(vnPayAccount, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
    };

    const handleCheckActivePaymentALEPay = (event) => {
        const { value } = event.target;

        setAlePayAccount(
            produce(alePayAccount, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                const active_payment_methods_note = draft.active_payment_methods_note;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
    };

    const handleCheckActivePaymentNoteALEPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;
        setAlePayAccount(
            produce(alePayAccount, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
    };

    const isPaymentGatewayConfigured = (gatewayCode) => {
        return find(paymentReducer.gateways, (item) => item.code === gatewayCode);
    };

    const connect = (paymentGateway) => {
        let errors = [];
        let currentInputsRef = null;
        setSelectedGateway({ code: paymentGateway });
        let isFree = checkPkgFree();
        // console.log('connect =  , ', isFree);
        switch (paymentGateway) {
            case appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE:
                currentInputsRef = zaloPayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.MOMO.CODE:
                currentInputsRef = momoRef;
                break;
            case appConfig.PAYMENT_PARTNERS.VN_PAY.CODE:
                currentInputsRef = vnPayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE:
                currentInputsRef = alePayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.COD.CODE:
                currentInputsRef = codRef;
                break;
            case appConfig.PAYMENT_PARTNERS.BANK.CODE:
                currentInputsRef = bankRef;
                break;
            case appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE:
                currentInputsRef = vnptEPayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE:
                currentInputsRef = shopeePayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.PAYPAL.CODE:
                currentInputsRef = paypalRef;
                break;
            case appConfig.PAYMENT_PARTNERS.STRIPE.CODE:
                currentInputsRef = stripeRef;
                break;
            case appConfig.PAYMENT_PARTNERS.APPOTA.CODE:
                currentInputsRef = appotaPayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.FINAN.CODE:
                currentInputsRef = finanRef;
                break;
        }

        if (currentInputsRef) {
            const inputsRef = compact(Array.from(currentInputsRef));

            map(inputsRef, (ref) => {
                if (ref.validate) {
                    errors = errors.concat(ref.validate());
                }
            });

            errors = compact(errors);

            if (errors.length > 0) {
                const errorMessage = errors.join('<br/>');
                window.LadiUI.toastCustom('danger', '', errorMessage);
                return;
            }
        }

        const data = {
            payment_gateway_code: paymentGateway,
        };

        //validate paymentMethod empty
        let errorPaymentMethodEmpty = false;
        switch (paymentGateway) {
            case appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE:
                if (zaloPayAccount.active_payment_methods.length <= 0) {
                    errorPaymentMethodEmpty = true;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.VN_PAY.CODE:
                if (vnPayAccount.active_payment_methods.length <= 0) {
                    errorPaymentMethodEmpty = true;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE:
                if (vnptEPayAccount.active_payment_methods.length <= 0) {
                    errorPaymentMethodEmpty = true;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE:
                if (alePayAccount.active_payment_methods.length <= 0) {
                    errorPaymentMethodEmpty = true;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.APPOTA.CODE:
                if (appotaPayAccount.active_payment_methods.length <= 0) {
                    errorPaymentMethodEmpty = true;
                }
                if (appotaPayAccount.active_payment_methods.includes('VA')) {
                    let regex = /^[a-zA-Z\s]+$/;
                    if (appotaPayAccount.account_name == '') {
                        window.LadiUI.toastCustom('danger', '', 'Tên người nhận chuyển khoản là bắt buộc!');
                        return;
                    }

                    if (!regex.test(appotaPayAccount.account_name)) {
                        window.LadiUI.toastCustom('danger', '', 'Vui lòng nhập đúng định dạng người nhận chuyển khoản!');
                        return;
                    }
                }
                break;
        }

        if (errorPaymentMethodEmpty) {
            window.LadiUI.toastCustom('danger', '', t('VALIDATION.REQUIRED_PAYMENT_METHOD'));
            return;
        }

        switch (paymentGateway) {
            case appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE:
                data.zalo_pay_account = produce(zaloPayAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = zaloPayMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.VN_PAY.CODE:
                data.vn_pay_account = produce(vnPayAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = vnPayMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.MOMO.CODE:
                data.momo_account = produce(momoAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = momoMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE:
                data.ale_pay_account = produce(alePayAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = alePayMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.COD.CODE:
                data.cod_account = produce(codAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = codMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.BANK.CODE:
                data.bank_account = produce(bankAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = bankMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE:
                data.vnpt_epay_account = produce(vnptEPayAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = bankMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE:
                data.shopee_pay_account = produce(shopeePayAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = shopeePayMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.PAYPAL.CODE:
                data.paypal_account = produce(payPalAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = payPalMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.STRIPE.CODE:
                data.stripe_account = produce(stripeAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = stripeMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.FINAN.CODE:
                data.gateway_account = produce(finanPayAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = finanPayMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
            case appConfig.PAYMENT_PARTNERS.APPOTA.CODE:
                data.appota_account = produce(appotaPayAccount, (draft) => { });

                if (baseHelper.isDevelopEnv()) {
                    data.mode = appotaPayMode;
                } else {
                    data.mode = appConfig.MODE.PRODUCTION;
                }
                break;
        }
        if (isFree && paymentGateway == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
            const banks = bankAccount.banks;
            for (let i = 0; i < banks.length; i++) {
                // console.log(" banks ", banks[i].autoConfirmPayment);
                if (banks[i].autoConfirmPayment) {
                    // window.LadiUI.toastCustom('danger', '', 'Chức năng thanh toán tự động chỉ dành cho gói Standard trở lên. Vui lòng nâng cấp để sử dụng');
                    window.LadiUI.showModal('warning-auto-payment');
                    return;
                }
            }

        }
        dispatch(settingActions.paymentConnect(data));
    };

    const active = (selectedGateway) => {
        setSelectedGateway(selectedGateway);
        dispatch(settingActions.paymentActive(selectedGateway.code, selectedGateway.payment_gateway_store_config_id));
    };

    const openDisconnectModal = (selectedGateway) => {
        setSelectedGateway(selectedGateway);
        // console.log('selectedGateway = ', selectedGateway, '- lisBankHub', listBankHubs);
        // if (bankItem.code == "MB") {
        //     dispatch(bankHubActions.listBankHub({}));
        // }
        // return;
        const banks = selectedGateway.config.banks;
        for (let i = 0; i < banks.length; i++) {
            const bankItems = banks[i];
            if (bankItems.autoConfirmPayment && bankItems.bank.code == 'MB' && listBankHubs.length > 0) {
                window.LadiUI.showModal('warning-delete-payment');
                return;
            }
        }
        window.LadiUI.showModal('confirm-disconnect-payment');
    };

    const openInactiveModal = (selectedGateway) => {
        setSelectedGateway(selectedGateway);
        window.LadiUI.showModal('confirm-inactive-payment');
    };

    //VNPT
    const onchangeDataUpdateVNPTEPay = (event) => {
        setDataUpdateVNPTEpay(
            produce(dataUpdateVNPTEpay, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('VNPT_EPAY');
    };

    //APPOTA
    const onchangeDataUpdateAPPOTAPay = (event) => {
        setDataUpdateAPPOTAPay(
            produce(dataUpdateAPPOTAPay, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('APPOTA');
    };

    const handleUpdateActivePaymentVNPTEPay = (e) => {
        const value = e.target.value;

        setDataUpdateVNPTEpay(
            produce(dataUpdateVNPTEpay, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                let active_payment_methods_note = draft.active_payment_methods_note;

                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                    // active_payment_methods_note[value] = '';
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('VNPT_EPAY');
    };

    const handleUpdateActivePaymentAPPOTAPay = (e) => {
        const value = e.target.value;

        setDataUpdateAPPOTAPay(
            produce(dataUpdateAPPOTAPay, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                let active_payment_methods_note = draft.active_payment_methods_note;

                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                    // active_payment_methods_note[value] = '';
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('APPOTA');
    };

    const handleUpdateActivePaymentNoteVNPTEPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;
        setDataUpdateVNPTEpay(
            produce(dataUpdateVNPTEpay, (draft) => {
                if (draft.active_payment_methods_note) {
                    //     const active_payment_methods_note = draft.active_payment_methods_note;
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('VNPT_EPAY');
    };

    //APPOTA
    const handleUpdateActivePaymentNoteAPPOTAPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;
        setDataUpdateAPPOTAPay(
            produce(dataUpdateAPPOTAPay, (draft) => {
                if (draft.active_payment_methods_note) {
                    //     const active_payment_methods_note = draft.active_payment_methods_note;
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('APPOTA');
    };

    const handleUpdateAccountNameAPPOTAPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        let valueUpperCase = value.toUpperCase();
        setDataUpdateAPPOTAPay({
            ...dataUpdateAPPOTAPay,
            account_name: valueUpperCase,
        });
        setIsShowBtnSaveData(true);
        setShowBtnPayment('APPOTA');
    };

    const handleUpdateMethodNameAPPOTAPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;
        setDataUpdateAPPOTAPay({
            ...dataUpdateAPPOTAPay,
            method_name: value,
        });
        setIsShowBtnSaveData(true);
        setShowBtnPayment('APPOTA');
    };

    //ZALOPAY

    const onchangeDataUpdateZaloPay = (event) => {
        setDataUpdateZaloPay(
            produce(dataUpdateZaloPay, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('ZALO_PAY');
    };

    const handleUpdateActivePaymentZaloPay = (e) => {
        const value = e.target.value;

        setDataUpdateZaloPay(
            produce(dataUpdateZaloPay, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                let active_payment_methods_note = draft.active_payment_methods_note;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                    delete active_payment_methods_note[value];
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('ZALO_PAY');
    };

    const handleUpdateActivePaymentNoteZaloPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        setDataUpdateZaloPay(
            produce(dataUpdateZaloPay, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('ZALO_PAY');
    };

    //VNPAY
    const onchangeDataUpdateVNPay = (event) => {
        setDataUpdateVNPay(
            produce(dataUpdateVNPay, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('VN_PAY');
    };
    const handleUpdateActivePaymentVNPay = (e) => {
        const value = e.target.value;
        setDataUpdateVNPay(
            produce(dataUpdateVNPay, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('VN_PAY');
    };

    const handleUpdateActivePaymentNoteVNPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        setDataUpdateVNPay(
            produce(dataUpdateVNPay, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('VN_PAY');
    };

    //MOMO
    const onchangeDataUpdateMomo = (event) => {
        setDataUpdateMomo(
            produce(dataUpdateMomo, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('MOMO');
    };

    //ALEPAY
    const onchangeDataUpdateAlePay = (event) => {
        setDataUpdateAlePay(
            produce(dataUpdateAlePay, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('ALE_PAY');
    };
    const handleUpdateActivePaymentALEPay = (e) => {
        const value = e.target.value;
        setDataUpdateAlePay(
            produce(dataUpdateAlePay, (draft) => {
                const activePaymentMethods = draft.active_payment_methods;
                if (!includes(activePaymentMethods, value)) {
                    activePaymentMethods.push(value);
                } else {
                    remove(activePaymentMethods, (paymentMethod) => paymentMethod == value);
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('ALE_PAY');
    };

    const handleUpdateActivePaymentNoteALEPay = (e) => {
        const value = e.target.value;
        const method = e.target.name;

        setDataUpdateAlePay(
            produce(dataUpdateAlePay, (draft) => {
                if (draft.active_payment_methods_note) {
                    draft.active_payment_methods_note[method] = value;
                }
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('ALE_PAY');
    };

    //SHOPEEPAY
    const onchangeDataUpdateShoppePay = (event) => {
        setDataUpdateShopeePay(
            produce(dataUpdateShopeePay, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('SHOPEE_PAY');
    };

    //PAYPAL
    const onchangeDataUpdatePayPal = (event) => {
        setDataUpdatePayPal(
            produce(dataUpdatePayPal, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('PAYPAL');
    };

    //STRIPE
    const onchangeDataUpdateStripe = (event) => {
        setDataUpdateStripe(
            produce(dataUpdateStripe, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('STRIPE');
    };

    //FINAN
    const onchangeDataUpdateFinan = (event) => {
        setDataUpdateFinan(
            produce(dataUpdateFinan, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('FINAN');
    };

    //COD
    const onchangeDataUpdateCod = (event) => {
        setDataUpdateCod(
            produce(dataUpdateCod, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setShowBtnPayment('');
        setIsShowBtnSaveData(true);
        setShowBtnPayment('COD');
    };

    //BANK
    const onchangeDataUpdateBank = (event) => {
        setDataUpdateBank(
            produce(dataUpdateBank, (draft) => {
                draft[event.target.name] = event.target.value;
            })
        );
        setIsShowBtnSaveData(true);
        setShowBtnPayment('BANK');
    };
    const checkPkgFree = () => {
        // console.log(currentStore.pkg_name);
        if (currentStore.pkg_name == 'STARTER' || currentStore.pkg_name == 'PERSONAL') {
            return true;
        }
        return false;
    }

    const upDateDataCofigPayment = (paymentGateway, paymentGatewayConfigured) => {
        let errors = [];
        let currentInputsRef = null;
        setSelectedGateway({ code: paymentGateway });
        let data = {};
        let isFree = checkPkgFree();
        // console.log('upDateDataCofigPayment=  , ', isFree);
        // return;
        switch (paymentGateway) {
            case appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateVNPTEpay,
                };
                currentInputsRef = vnptEPayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateZaloPay,
                };
                currentInputsRef = zaloPayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.VN_PAY.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateVNPay,
                };
                currentInputsRef = vnPayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.MOMO.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateMomo,
                };
                currentInputsRef = momoRef;
                break;
            case appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE:
                let _dataUpdateAlePay = { ...dataUpdateAlePay };
                let active_payment_methods = _dataUpdateAlePay.active_payment_methods;
                // nếu có QRCODE thì xoá đi;
                let indexQR = active_payment_methods.indexOf('QR_CODE');
                if (indexQR > -1) active_payment_methods.splice(indexQR, 1);
                _dataUpdateAlePay.active_payment_methods = active_payment_methods;
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ..._dataUpdateAlePay,
                };
                currentInputsRef = alePayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.COD.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateCod,
                };
                currentInputsRef = codRef;
                break;
            case appConfig.PAYMENT_PARTNERS.BANK.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateBank,
                };
                currentInputsRef = bankRef;
                break;
            case appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateShopeePay,
                };
                currentInputsRef = shopeePayRef;
                break;
            case appConfig.PAYMENT_PARTNERS.PAYPAL.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdatePayPal,
                };
                currentInputsRef = paypalRef;
                break;
            case appConfig.PAYMENT_PARTNERS.STRIPE.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateStripe,
                };
                currentInputsRef = stripeRef;
                break;

            case appConfig.PAYMENT_PARTNERS.FINAN.CODE:
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateFinan,
                };
                currentInputsRef = finanRef;
                break;

            case appConfig.PAYMENT_PARTNERS.APPOTA.CODE:
                let regex = /^[a-zA-Z\s]+$/;
                if (dataUpdateAPPOTAPay.method_name == '') {
                    window.LadiUI.toastCustom('danger', '', 'Vui lòng nhập tên phương thức thanh toán');
                    return;
                }
                if (
                    dataUpdateAPPOTAPay.active_payment_methods.includes(appConfig.PAYMENT_METHODS.APPOTA.TYPE.VA.CODE) &&
                    dataUpdateAPPOTAPay.account_name == ''
                ) {
                    window.LadiUI.toastCustom('danger', '', 'Tên người nhận chuyển khoản là bắt buộc!');
                    return;
                }

                if (
                    dataUpdateAPPOTAPay.active_payment_methods.includes(appConfig.PAYMENT_METHODS.APPOTA.TYPE.VA.CODE) &&
                    !regex.test(dataUpdateAPPOTAPay.account_name)
                ) {
                    window.LadiUI.toastCustom('danger', '', 'Vui lòng nhập đúng định dạng người nhận chuyển khoản!');
                    return;
                }
                data = {
                    payment_gateway_store_config_id: paymentGatewayConfigured.payment_gateway_store_config_id,
                    ...dataUpdateAPPOTAPay,
                };

                currentInputsRef = appotaPayRef;
                break;
        }

        if (currentInputsRef) {
            const inputsRef = compact(Array.from(currentInputsRef));

            map(inputsRef, (ref) => {
                if (ref.validate) {
                    errors = errors.concat(ref.validate());
                }
            });

            errors = compact(errors);

            if (errors.length > 0) {
                const errorMessage = errors.join('<br/>');
                window.LadiUI.toastCustom('danger', '', errorMessage);
                return;
            }
        }
        // console.log(' isFree', isFree, dataUpdateBank);
        if (isFree && paymentGateway == appConfig.PAYMENT_PARTNERS.BANK.CODE) {
            const banks = dataUpdateBank.banks;
            // console.log(' banks = ', banks);
            for (let i = 0; i < banks.length; i++) {
                // console.log(" banks ", banks[i].autoConfirmPayment);
                if (banks[i].autoConfirmPayment) {
                    // window.LadiUI.toastCustom('danger', '', 'Chức năng thanh toán tự động chỉ dành cho gói Standard trở lên. Vui lòng nâng cấp để sử dụng');
                    window.LadiUI.showModal('warning-auto-payment');
                    return;
                }
            }

        }
        dispatch(settingActions.updatePaymentGateway(data));
    };

    const isLoadingDisconnect = settingReducer.loading && settingTypes.SETTING_PAYMENT_DISCONNECT === settingReducer.waiting;
    const isLoadingInactive = settingReducer.loading && settingTypes.SETTING_PAYMENT_INACTIVE === settingReducer.waiting;

    const isSubmitLoadingVNPTEPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveVNPTEPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingZaloPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveZaloPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingMomo =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.MOMO.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveMomo =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.MOMO.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingVnpay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.VN_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveVnpay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.VN_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE === settingReducer.waiting;

    const isSubmitLoadingAle =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveAle =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingShopee =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveShopee =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingPaypal =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActivePaypal =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingStripe =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveStripe =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.PAYPAL.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingCod =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.COD.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveCod =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.COD.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingBank =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.BANK.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;
    const isLoadingActiveBank =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.BANK.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isLoadingActiveAPPOTAPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.APPOTA.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingAPPOTAPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.APPOTA.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;

    const isLoadingActiveFinanPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.FINAN.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_ACTIVE == settingReducer.waiting;

    const isSubmitLoadingFinanPay =
        selectedGateway.code == appConfig.PAYMENT_PARTNERS.FINAN.CODE &&
        settingReducer.loading &&
        settingTypes.SETTING_PAYMENT_CONNECT === settingReducer.waiting;

    if (!isLoadedListGateway) {
        return <div className='loader' style={{ display: 'initial', position: 'initial' }}></div>;
    }

    const VNPTGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.VNPT_EPAY.CODE);
    const ZaloPayGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.ZALO_PAY.CODE);
    const MomoGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.MOMO.CODE);
    const VnPayGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.VN_PAY.CODE);
    const AlePayGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.ALE_PAY.CODE);
    const ShopeePayGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.SHOPEE_PAY.CODE);
    const PaypalGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.PAYPAL.CODE);
    const StripeGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.STRIPE.CODE);
    const CodGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.COD.CODE);
    const BankGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.BANK.CODE);

    const APPOTAGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.APPOTA.CODE);
    const finanGateway = isPaymentGatewayConfigured(appConfig.PAYMENT_PARTNERS.FINAN.CODE);

    return (
        <div
            className='page-setting'
            id='page-setting-payment'
        // title={t('PAYMENTS.TITLE')}
        // visible={props.visible}
        // onCancel={props.onCancel}
        >
            <div className='block-title-action'>
                <h3 className='title'>Phương thức thanh toán</h3>
            </div>
            <div className='list-payment'>
                <ItemPayment
                    active={active}
                    openInactiveModal={openInactiveModal}
                    openDisconnectModal={openDisconnectModal}
                    name={(dataUpdateBank && dataUpdateBank.method_name) ? dataUpdateBank.method_name : 'Chuyển khoản qua ngân hàng'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-bank-tranfer.svg'}
                    setAcount={setBankAccount}
                    setShowModal={setIsShowBankSetting}
                    isShowModal={isShowBankSetting}
                    initData={initBankData}
                    dataGetWay={BankGateway}
                    isLoadingActive={isLoadingActiveBank}
                    description={(dataUpdateBank && dataUpdateBank.description) ? dataUpdateBank.description : t('PAYMENTS.BANK_DESCRIPTION')}
                />

                <ItemPayment
                    active={active}
                    openInactiveModal={openInactiveModal}
                    openDisconnectModal={openDisconnectModal}
                    name={(dataUpdateCod && dataUpdateCod.method_name) ? dataUpdateCod.method_name : 'Thanh toán khi nhận hàng(COD)'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-cod.svg'}
                    setAcount={setCodAccount}
                    setShowModal={setIsShowCodSetting}
                    isShowModal={isShowCodSetting}
                    initData={initCodData}
                    dataGetWay={CodGateway}
                    isLoadingActive={isLoadingActiveCod}
                    description={(dataUpdateCod && dataUpdateCod.description) ? dataUpdateCod.description : t('PAYMENTS.COD_DESCRIPTION')}
                />

                <ItemPayment
                    name={(dataUpdateVNPTEpay && dataUpdateVNPTEpay.method_name) ? dataUpdateVNPTEpay.method_name : 'Thanh toán qua VNPT EPay'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/vnpt_epay_logo.svg'}
                    setAcount={setVNPTEPayAccount}
                    initData={initVNPTEPayData}
                    setShowModal={setIsShowVNPTEPaySetting}
                    isShowModal={isShowVNPTEPaySetting}
                    dataGetWay={VNPTGateway}
                    openInactiveModal={openInactiveModal}
                    active={active}
                    isLoadingActive={isLoadingActiveVNPTEPay}
                    openDisconnectModal={openDisconnectModal}
                    description={t('PAYMENTS.VNPT_E_PAY.LB_DESCRIPTION_CONFIGURED')}
                />

                <ItemPayment
                    name={(dataUpdateZaloPay && dataUpdateZaloPay.method_name) ? dataUpdateZaloPay.method_name : 'Thanh toán qua ZaloPay'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-zalopay.svg'}
                    setAcount={setZaloPayAccount}
                    initData={initZaloPayData}
                    setShowModal={setIsShowZaloPaySetting}
                    isShowModal={isShowZaloPaySetting}
                    dataGetWay={ZaloPayGateway}
                    openInactiveModal={openInactiveModal}
                    active={active}
                    isLoadingActive={isLoadingActiveZaloPay}
                    openDisconnectModal={openDisconnectModal}
                    description={t('PAYMENTS.ZALO_PAY.LB_DESCRIPTION_CONFIGURED')}
                />

                <ItemPayment
                    name={(dataUpdateMomo && dataUpdateMomo.method_name) ? dataUpdateMomo.method_name : 'Thanh toán qua Momo'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-momo.svg'}
                    setAcount={setMomoAccount}
                    initData={initMomoData}
                    setShowModal={setIsShowMomoSetting}
                    isShowModal={isShowMomoSetting}
                    dataGetWay={MomoGateway}
                    openInactiveModal={openInactiveModal}
                    active={active}
                    isLoadingActive={isLoadingActiveMomo}
                    openDisconnectModal={openDisconnectModal}
                    description={t('PAYMENTS.MOMO.LB_DESCRIPTION_CONFIGURED')}
                />
                <ItemPayment
                    active={active}
                    openInactiveModal={openInactiveModal}
                    openDisconnectModal={openDisconnectModal}
                    name={(dataUpdateVNPay && dataUpdateVNPay.method_name) ? dataUpdateVNPay.method_name : 'Thanh toán qua VNPay'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-vnpay.svg'}
                    setAcount={setVnPayAccount}
                    setShowModal={setIsShowVnPaySetting}
                    isShowModal={isShowVnPaySetting}
                    initData={initVNPayData}
                    dataGetWay={VnPayGateway}
                    isLoadingActive={isLoadingActiveVnpay}
                    description={t('PAYMENTS.VN_PAY.LB_DESCRIPTION_CONFIGURED')}
                />

                <ItemPayment
                    active={active}
                    openInactiveModal={openInactiveModal}
                    openDisconnectModal={openDisconnectModal}
                    name={(dataUpdateAlePay && dataUpdateAlePay.method_name) ? dataUpdateAlePay.method_name : 'Thanh toán qua AlePay'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-alepay.svg'}
                    setAcount={setAlePayAccount}
                    setShowModal={setIsShowAlePaySetting}
                    isShowModal={isShowAlePaySetting}
                    initData={initAlePayData}
                    dataGetWay={AlePayGateway}
                    isLoadingActive={isLoadingActiveAle}
                    description={t('PAYMENTS.ALE_PAY.LB_DESCRIPTION_CONFIGURED')}
                />

                <ItemPayment
                    active={active}
                    openInactiveModal={openInactiveModal}
                    openDisconnectModal={openDisconnectModal}
                    name={(dataUpdateShopeePay && dataUpdateShopeePay.method_name) ? dataUpdateShopeePay.method_name : 'Thanh toán qua Shopee Pay'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-shopee-pay.svg'}
                    setAcount={setShopeePayAccount}
                    setShowModal={setIsShowShopeePaySetting}
                    isShowModal={isShowShopeePaySetting}
                    initData={initShopeePayData}
                    dataGetWay={ShopeePayGateway}
                    isLoadingActive={isLoadingActiveShopee}
                    description={t('PAYMENTS.SHOPEE_PAY.LB_DESCRIPTION_CONFIGURED')}
                />

                <ItemPayment
                    active={active}
                    openInactiveModal={openInactiveModal}
                    openDisconnectModal={openDisconnectModal}
                    name={(dataUpdatePayPal && dataUpdatePayPal.method_name) ? dataUpdatePayPal.method_name : 'Thanh toán qua PayPal'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-paypal.svg'}
                    setAcount={setPayPalAccount}
                    setShowModal={setIsShowPayPalSetting}
                    isShowModal={isShowPayPalSetting}
                    initData={initPayPalData}
                    dataGetWay={PaypalGateway}
                    isLoadingActive={isLoadingActivePaypal}
                    description={t('PAYMENTS.PAYPAL.LB_DESCRIPTION_CONFIGURED')}
                />

                <ItemPayment
                    active={active}
                    openInactiveModal={openInactiveModal}
                    openDisconnectModal={openDisconnectModal}
                    name={(dataUpdateStripe && dataUpdateStripe.method_name) ? dataUpdateStripe.method_name : 'Thanh toán qua Stripe'}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-stripe.svg'}
                    setAcount={setStripeAccount}
                    setShowModal={setIsShowStripeSetting}
                    isShowModal={isShowStripeSetting}
                    initData={initStripeData}
                    dataGetWay={StripeGateway}
                    isLoadingActive={isLoadingActiveStripe}
                    description={t('PAYMENTS.STRIPE.LB_DESCRIPTION_CONFIGURED')}
                />
                <ItemPayment
                    name={(dataUpdateAPPOTAPay && dataUpdateAPPOTAPay.method_name) ? dataUpdateAPPOTAPay.method_name : 'Thanh toán qua Appota'}
                    logo={'https://docs.appotapay.com/img/icon.png'}
                    setAcount={setAPPOTAPayAccount}
                    initData={initAPPOTAPayData}
                    setShowModal={setIsShowAPPOTAPaySetting}
                    isShowModal={isShowAPPOTAPaySetting}
                    dataGetWay={APPOTAGateway}
                    openInactiveModal={openInactiveModal}
                    active={active}
                    isLoadingActive={isLoadingActiveAPPOTAPay}
                    openDisconnectModal={openDisconnectModal}
                    description={t('PAYMENTS.APPOTA_PAY.LB_DESCRIPTION_CONFIGURED')}
                />

                <ItemPayment
                    name={(dataUpdateFinan && dataUpdateFinan.method_name) ? dataUpdateFinan.method_name : 'Thanh toán qua Finan'}
                    logo={'https://w.ladicdn.com/ladiui/icons/bank-sobanhang.svg'}
                    setAcount={setFinanPayAccount}
                    initData={initFinanPayData}
                    setShowModal={setIsShowFinanPaySetting}
                    isShowModal={isShowFinanPaySetting}
                    dataGetWay={finanGateway}
                    openInactiveModal={openInactiveModal}
                    active={active}
                    isLoadingActive={isLoadingActiveFinanPay}
                    openDisconnectModal={openDisconnectModal}
                    description={t('PAYMENTS.FINAN.LB_DESCRIPTION_CONFIGURED')}
                />
            </div>

            {/* ----------------------------------------------------modal vnpt epay---------------------------------------------------------- */}

            {isShowVNPTEPaySetting && (
                <ModalVnptEpay
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    onCancel={() => setIsShowVNPTEPaySetting(false)}
                    paymentGatewayConfigured={VNPTGateway}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/vnpt_epay_logo.svg'}
                    dataUpdateVNPTEpays={dataUpdateVNPTEpay}
                    connect={connect}
                    isSubmitLoading={isSubmitLoadingVNPTEPay}
                    handleUpdateActivePaymentVNPTEPay={handleUpdateActivePaymentVNPTEPay}
                    onchangeDataUpdateVNPTEPay={onchangeDataUpdateVNPTEPay}
                    handleUpdateActivePaymentNoteVNPTEPay={handleUpdateActivePaymentNoteVNPTEPay}
                    vnptEPayRef={vnptEPayRef}
                    vnptEPayAccount={vnptEPayAccount}
                    onChangeVNPTEPayInput={onChangeVNPTEPayInput}
                    handleCheckActivePaymentVNPTEPay={handleCheckActivePaymentVNPTEPay}
                    handleCheckActivePaymentNoteVNPTPay={handleCheckActivePaymentNoteVNPTPay}
                    setVNPTEPayMode={setVNPTEPayMode}
                    vnptEPayMode={vnptEPayMode}
                    setIsShowVNPTEPaySetting={setIsShowVNPTEPaySetting}
                    isShowVNPTEPaySetting={isShowVNPTEPaySetting}
                />
            )}
            {isShowZaloPaySetting && (
                <ModalZaloPay
                    onCancel={() => setIsShowZaloPaySetting(false)}
                    paymentGatewayConfigured={ZaloPayGateway}
                    setZaloPayAccount={setZaloPayAccount}
                    zaloPayRef={zaloPayRef}
                    zaloPayAccount={zaloPayAccount}
                    dataUpdateZaloPay={dataUpdateZaloPay}
                    onChangeZaloPayInput={onChangeZaloPayInput}
                    handleCheckActivePaymentZaloPay={handleCheckActivePaymentZaloPay}
                    handleCheckActivePaymentNoteZaloPay={handleCheckActivePaymentNoteZaloPay}
                    setZaloPayMode={setZaloPayMode}
                    zaloPayMode={zaloPayMode}
                    setIsShowZaloPaySetting={setIsShowZaloPaySetting}
                    isSubmitLoading={isSubmitLoadingZaloPay}
                    handleUpdateActivePaymentZaloPay={handleUpdateActivePaymentZaloPay}
                    handleUpdateActivePaymentNoteZaloPay={handleUpdateActivePaymentNoteZaloPay}
                    onchangeDataUpdateZaloPay={onchangeDataUpdateZaloPay}
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-zalopay.svg'}
                />
            )}
            {isShowMomoSetting && (
                <ModalMomoPay
                    onCancel={() => setIsShowMomoSetting(false)}
                    isSubmitLoading={isSubmitLoadingZaloPay}
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    paymentGatewayConfigured={MomoGateway}
                    onchangeDataUpdateMomo={onchangeDataUpdateMomo}
                    momoRef={momoRef}
                    momoAccount={momoAccount}
                    dataUpdateMomo={dataUpdateMomo}
                    onChangeMomoInput={onChangeMomoInput}
                    setMomoMode={setMomoMode}
                    momoMode={momoMode}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-momo.svg'}
                />
            )}
            {isShowVnPaySetting && (
                <ModalVnPay
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => setIsShowVnPaySetting(false)}
                    isSubmitLoading={isSubmitLoadingVnpay}
                    paymentGatewayConfigured={VnPayGateway}
                    handleUpdateActivePaymentVNPay={handleUpdateActivePaymentVNPay}
                    handleUpdateActivePaymentNoteVNPay={handleUpdateActivePaymentNoteVNPay}
                    dataUpdateVNPay={dataUpdateVNPay}
                    onchangeDataUpdateVNPay={onchangeDataUpdateVNPay}
                    vnPayAccount={vnPayAccount}
                    vnPayRef={vnPayRef}
                    onChangeVnPayInput={onChangeVnPayInput}
                    vnPayMode={vnPayMode}
                    setVnPayMode={setVnPayMode}
                    handleCheckActivePaymentVNPay={handleCheckActivePaymentVNPay}
                    handleCheckActivePaymentNoteVNPay={handleCheckActivePaymentNoteVNPay}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-vnpay.svg'}
                />
            )}
            {isShowAlePaySetting && (
                <ModalAlePay
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => setIsShowAlePaySetting(false)}
                    isSubmitLoading={isSubmitLoadingAle}
                    paymentGatewayConfigured={AlePayGateway}
                    handleUpdateActivePaymentALEPay={handleUpdateActivePaymentALEPay}
                    onchangeDataUpdateAlePay={onchangeDataUpdateAlePay}
                    handleUpdateActivePaymentNoteALEPay={handleUpdateActivePaymentNoteALEPay}
                    dataUpdateAlePay={dataUpdateAlePay}
                    alePayRef={alePayRef}
                    alePayAccount={alePayAccount}
                    onChangeAlePayInput={onChangeAlePayInput}
                    handleCheckActivePaymentALEPay={handleCheckActivePaymentALEPay}
                    handleCheckActivePaymentNoteALEPay={handleCheckActivePaymentNoteALEPay}
                    setAlePayMode={setAlePayMode}
                    alePayMode={alePayMode}
                    setIsShowAlePaySetting={setIsShowAlePaySetting}
                    isShowAlePaySetting={isShowAlePaySetting}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-alepay.svg'}
                />
            )}
            {isShowShopeePaySetting && (
                <ModalShopeePay
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => setIsShowShopeePaySetting(false)}
                    isSubmitLoading={isSubmitLoadingShopee}
                    paymentGatewayConfigured={ShopeePayGateway}
                    onchangeDataUpdateShoppePay={onchangeDataUpdateShoppePay}
                    shopeePayRef={shopeePayRef}
                    shopeePayAccount={shopeePayAccount}
                    onChangeShopeePayInput={onChangeShopeePayInput}
                    shopeePayMode={shopeePayMode}
                    setShopeePayMode={setShopeePayMode}
                    dataUpdateShopeePay={dataUpdateShopeePay}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-shopee-pay.svg'}
                />
            )}
            {isShowPayPalSetting && (
                <ModalPaypal
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => setIsShowPayPalSetting(false)}
                    isSubmitLoading={isSubmitLoadingPaypal}
                    paymentGatewayConfigured={PaypalGateway}
                    onchangeDataUpdatePayPal={onchangeDataUpdatePayPal}
                    paypalRef={paypalRef}
                    payPalAccount={payPalAccount}
                    onChangePaypalInput={onChangePaypalInput}
                    dataUpdatePayPal={dataUpdatePayPal}
                    setPaypalModal={setPaypalModal}
                    payPalMode={payPalMode}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-paypal.svg'}
                />
            )}
            {isShowStripeSetting && (
                <ModalStripe
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => setIsShowStripeSetting(false)}
                    isSubmitLoading={isSubmitLoadingStripe}
                    paymentGatewayConfigured={StripeGateway}
                    onchangeDataUpdateStripe={onchangeDataUpdateStripe}
                    stripeRef={stripeRef}
                    stripeAccount={stripeAccount}
                    onChangeStripeInput={onChangeStripeInput}
                    dataUpdateStripe={dataUpdateStripe}
                    setPaypalModal={setPaypalModal}
                    stripeMode={stripeMode}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-stripe.svg'}
                />
            )}

            {isShowCodSetting && (
                <ModalCod
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => setIsShowCodSetting(false)}
                    isSubmitLoading={isSubmitLoadingCod}
                    paymentGatewayConfigured={CodGateway}
                    onchangeDataUpdateCod={onchangeDataUpdateCod}
                    codMode={codMode}
                    codRef={codRef}
                    codAccount={codAccount}
                    dataUpdateCod={dataUpdateCod}
                    onChangeCodInput={onChangeCodInput}
                    setCodMode={setCodMode}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-cod.svg'}
                />
            )}

            {isShowBankSetting && (
                <ModalBank
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => {
                        setIsShowBankSetting(false);
                        history.push('/settings?tab=PAYMENT');
                    }}
                    isSubmitLoading={isSubmitLoadingBank}
                    paymentGatewayConfigured={BankGateway}
                    dataUpdateBanks={dataUpdateBank}
                    onchangeDataUpdateBank={onchangeDataUpdateBank}
                    bankMode={bankMode}
                    bankRef={bankRef}
                    bankAccount={bankAccount}
                    selectedDataBank={selectedDataBank}
                    selectedDataUpdateBank={selectedDataUpdateBank}
                    onChangeBankInput={onChangeBankInput}
                    onChangeBankDataInput={onChangeBankDataInput}
                    onChangeBankDataUpdateInput={onChangeBankDataUpdateInput}
                    onChangeBankWebhook={onChangeBankWebhook}
                    setBankMode={setBankMode}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-bank-tranfer.svg'}
                    onOpenSettingNotificationModal={() => {
                        setIsShowSettingNotification(true);
                    }}
                    onOpenDisconnectNotificationBankModal={() => {
                        setIsShowModalDisconnectNotificationBank(true);
                    }}
                    onOpenModalOTP={(requestData) => {
                        // console.log('bank onOpenModalOTP', requestData);
                        setIsShowModalOTP(true);
                        setRequestBankHub(requestData);
                    }}
                    requestBankHub={requestBankHub}
                />
            )}

            {isShowAPPOTAPaySetting && (
                <ModalAppotaPay
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    onCancel={() => setIsShowAPPOTAPaySetting(false)}
                    paymentGatewayConfigured={APPOTAGateway}
                    logo={'https://docs.appotapay.com/img/icon.png'}
                    dataUpdateAPPOTAPay={dataUpdateAPPOTAPay}
                    connect={connect}
                    isSubmitLoading={isSubmitLoadingAPPOTAPay}
                    handleUpdateActivePaymentAPPOTAPay={handleUpdateActivePaymentAPPOTAPay}
                    onchangeDataUpdateAPPOTAPay={onchangeDataUpdateAPPOTAPay}
                    handleUpdateActivePaymentNoteAPPOTAPay={handleUpdateActivePaymentNoteAPPOTAPay}
                    appotaPayRef={appotaPayRef}
                    appotaPayAccount={appotaPayAccount}
                    onChangeAPPOTAPayInput={onChangeAPPOTAPayInput}
                    handleCheckActivePaymentAPPOTAPay={handleCheckActivePaymentAPPOTAPay}
                    handleCheckActivePaymentNoteAPPOTAPay={handleCheckActivePaymentNoteAPPOTAPay}
                    handleChangeAccountNameAPPOTAPay={handleChangeAccountNameAPPOTAPay}
                    handleUpdateAccountNameAPPOTAPay={handleUpdateAccountNameAPPOTAPay}
                    handleUpdateMethodNameAPPOTAPay={handleUpdateMethodNameAPPOTAPay}
                    setAPPOTAPayMode={setAPPOTAPayMode}
                    appotaPayMode={appotaPayMode}
                    setIsShowAPPOTAPaySetting={setIsShowAPPOTAPaySetting}
                    isShowAPPOTAPaySetting={isShowAPPOTAPaySetting}
                />
            )}

            {isShowFinanPaySetting && (
                <ModalFinan
                    upDateDataCofigPayment={upDateDataCofigPayment}
                    connect={connect}
                    onCancel={() => setIsShowFinanPaySetting(false)}
                    isSubmitLoading={isSubmitLoadingFinanPay}
                    paymentGatewayConfigured={finanGateway}
                    onchangeDataUpdateGateway={onchangeDataUpdateFinan}
                    finanRef={finanRef}
                    dataUpdateGateway={dataUpdateFinan}
                    paymentGatewayAccount={finanPayAccount}
                    onChangeGatewayInput={onChangeFinanInput}
                    dataUpdateFinan={dataUpdateFinan}
                    setFinanPayMode={setFinanPayMode}
                    gatewayMode={finanPayMode}
                    logo={'https://w.ladicdn.com/ladiui/icons/bank-sobanhang.svg'}
                />
            )}

            {isShowSettingNotification && (
                <ModalSettingRecieveNotificationBank
                    onCancel={() => {
                        setIsShowSettingNotification(false);
                    }}
                    logo={'https://w.ladicdn.com/ladiui/ladisales/logo-bank-tranfer.svg'}
                    onOpenModalOTP={(requestData) => {
                        // console.log('Notification onOpenModalOTP', requestData);
                        setIsShowModalOTP(true);
                        setRequestBankHub(requestData);
                    }}
                />
            )}

            {isShowModalOTP && (
                <ModalOTP
                    // bankID={requestBankHub.bank_id}
                    // requestBankHubID={requestBankHub.requestId}
                    // phone={requestBankHub.phone}
                    // action={requestBankHub.action}
                    requestBankHub={requestBankHub}
                    onCancel={() => {
                        setIsShowModalOTP(false);
                    }}
                    onOpenModalNotifyConnectSuccessfulApi={() => {
                        setIsShowModalNotifyConnectSuccessfulApi(true);
                        setIsShowSettingNotification(false);
                    }}
                />
            )}

            {isShowModalNotifyConnectSuccessfulApi && (
                <ModalNotifyConnectSuccessfulApi
                    requestBankHub={requestBankHub}
                    onCancel={() => {
                        setIsShowModalNotifyConnectSuccessfulApi(false);
                    }}
                />
            )}

            {isShowModalDisconnectNotificationBank && (
                <ModalDisconnectNotificationBank
                    onCancel={() => {
                        setIsShowModalDisconnectNotificationBank(false);
                    }}
                />
            )}

            <ConfirmModal
                id='confirm-disconnect-payment'
                title={t('SETTINGS.MSG_PAYMENT_TITLE_DISCONNECT', selectedGateway.name)}
                content={t('SETTINGS.MSG_PAYMENT_CONTENT_DISCONNECT', selectedGateway.name)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.APPLY')}
                onOk={() =>
                    dispatch(settingActions.paymentDisconnect(selectedGateway.code, selectedGateway.payment_gateway_store_config_id))
                }
                isLoading={isLoadingDisconnect}
                maxWidth={500}
            />

            <ConfirmModal
                id='confirm-inactive-payment'
                title={t('SETTINGS.MSG_PAYMENT_TITLE_INACTIVE', selectedGateway.name)}
                content={t('SETTINGS.MSG_PAYMENT_CONTENT_INACTIVE', selectedGateway.name)}
                cancelText={t('ACTIONS.CANCEL')}
                okText={t('ACTIONS.APPLY')}
                onOk={() => dispatch(settingActions.paymentInactive(selectedGateway.code, selectedGateway.payment_gateway_store_config_id))}
                isLoading={isLoadingInactive}
                maxWidth={500}
            />

            <ConfirmModal
                id='warning-auto-payment'
                title={"Thông báo"}
                content={"Chức năng thanh toán tự động chỉ dành cho gói Standard trở lên. Vui lòng nâng cấp để sử dụng"}
                // cancelText={t('ACTIONS.CANCEL')}
                okText={"Đóng"}
                isHiddenBtnCancel={true}
                onOk={() => window.LadiUI.closeModal('warning-auto-payment')}
                // isLoading={isLoadingInactive}
                maxWidth={500}
            />
            <ConfirmModal
                id='warning-delete-payment'
                title={"Thông báo"}
                content={"Bạn cần hủy liên kết tài khoản ngân hàng MB trước khi xóa hoặc vô hiệu hóa phương thức thanh toán"}
                // cancelText={t('ACTIONS.CANCEL')}
                okText={"Đóng"}
                isHiddenBtnCancel={true}
                onOk={() => window.LadiUI.closeModal('warning-delete-payment')}
                // isLoading={isLoadingInactive}
                maxWidth={500}
            />
        </div>
    );
}
