import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useLocation } from 'react-router-dom';
import { I18nContext, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import produce from 'immer';
import Input from '../../components/Input';
import { map, includes, remove } from 'lodash';

import pageCheckoutActions from '../../redux/futures/page_checkout/actions';
import courseActions from '../../redux/futures/course/actions';
import * as courseTypes from '../../redux/futures/course/types';
import * as pageCheckoutTypes from '../../redux/futures/page_checkout/types';

import customerActions from '../../redux/futures/customer/actions';
import * as customerTypes from '../../redux/futures/customer/types';

import baseHelper from '../../helpers/BaseHelper';

import appConfig from '../../config/app';

import LadiPagination from '../../components/LadiPagination';
import ModalMembershipKey from './components/ModalMembershipKey';

import LadiDropdownMenu from '../../components/LadiDropdownMenu';
import ConfirmModal from '../../components/ConfirmModal';
import ModalReasonLock from './components/ModalReasonLock';
import ModalOwnedCourse from './components/ModalOwnedCourse';
import ModalHistoryLearn from './components/ModelHistoryLearn';


import ModalAddAccount from './components/ModalAddAccount';
import ModalChangeStudent from './components/ModelChangeStudent';

import ModalShowPasswordToken from './components/ModalShowPasswordToken';
import LoadingTable from '../../components/LoadingTable';

const CDN = 'https://w.ladicdn.com/';

const PageStudentManagement = forwardRef((props, ref) => {
    let { isShow, currentDataDomain, selectedLogo, urlPublished } = props;

    const location = useLocation();
    const queryStrings = new URLSearchParams(location.search);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fileReducer = useSelector((state) => state.file);

    const courseReducer = useSelector((state) => state.course);

    const customerReducer = useSelector((state) => state.customer);

    const pageCheckoutReducer = useSelector((state) => state.pageCheckoutReducer);

    const listAction = [
        {
            value: 'LOCK',
            name: 'Vô hiệu tài khoản',
        },
        {
            value: 'UN_LOCK',
            name: 'Kích hoạt tài khoản',
        },
    ];

    const [conditions, setConditions] = useState({
        page: 1,
        limit: appConfig.PAGINATION.LIMIT_OPTIONS[0].value,
    });

    const [customers, setCustomers] = useState([]);

    const [selectedIds, setSelectedIds] = useState([]);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);

    const [isShowModalMembershipKey, setIsShowModalMembershipKey] = useState(false);

    const [keyWord, setKeyWord] = useState('');
    const [statusPaid, setStatusPaid] = useState('ALL');
    const [statusVerify, setStatusVerify] = useState(null);
    const [statusActive, setStatusActive] = useState(null);
    const [isFirstGetList, setIsFirstGetList] = useState(false);


    const [selectedReason, setSelectedReason] = useState('');
    const [isShowModalReasonLock, setIsShowModalReasonLock] = useState(false);

    const [isShowModalOwnedCourse, setIsShowModalOwnedCourse] = useState(false);
    const [isShowModalHistory, setIsShowModalHistory] = useState(false);
    const [ownedCourses, setOwnedCourses] = useState([]);

    const [isShowModalAddAccount, setIsShowModalAddAccount] = useState(false);
    const [isShowModalChangeAccount, setIsShowModalChangeAccount] = useState(false);

    const [valueSearchOwnedCourse, setValueSearchOwnedCourse] = useState('');
    const [valueSearchLearnCourse, setValueSearchLearnCourse] = useState('');
    const [statusLearn, setStatusLearn] = useState(null);

    const [isOaOa, setIsOaOa] = useState(false);

    const [isShowModalPassWord, setIsShowModalPassWord] = useState(false);

    const [dataModalShowPassword, setDataModalShowPassword] = useState({
        email: '',
        token: '',
    });

    const [statusPaids, setStatusPaids] = useState(appConfig.LIST_STATUS_PAID);
    const [freeLabelAccountConfigs, setFreeLabelAccountConfigs] = useState('Miễn phí');
    const [paidLabelAccountConfigs, setPaidLabelAccountConfigs] = useState('Trả phí');

    const [defaultSearchParams, setDefaultSearchParams] = useState({
        keyword: '',
        is_verify: null,
        customer_is_lock: null,
        paid_status: 'ALL'
    });

    const inputsRef = new Set();

    useImperativeHandle(ref, () => ({
        validate() {
            return true;
        },
        getData() {
            return {};
        },
    }));

    // const handleSubmitData = () => {
    //   props.handleSubmitData();
    // };

    useEffect(() => {
        let payload = {
            search: {
                ...defaultSearchParams
            },
            ...conditions,
            paged: conditions.page,
        };
        dispatch(pageCheckoutActions.pageCourseShow());
        dispatch(courseActions.courseCustomerList(payload));
        setIsFirstGetList(true);
        if (queryStrings.get('oanh_check')) {
            setIsOaOa(true);
        }
    }, []);

    useEffect(() => {
        if (pageCheckoutReducer.action == pageCheckoutTypes.PAGE_COUSE_SHOW) {
            if (pageCheckoutReducer.status) {
                if (pageCheckoutReducer.dataPageCheckout) {
                    const freeLabel = pageCheckoutReducer.dataPageCheckout?.config_course?.label_account_configs?.free_label || 'Miễn phí';
                    const paidLabel = pageCheckoutReducer.dataPageCheckout?.config_course?.label_account_configs?.paid_label || 'Trả phí';
                    setFreeLabelAccountConfigs(freeLabel);
                    setPaidLabelAccountConfigs(paidLabel);
                    setStatusPaids([
                        {
                            name: freeLabel,
                            value: 'NOT_PAID',
                        },
                        {
                            name: paidLabel,
                            value: 'PAID',
                        },
                        {
                            name: t('COMMON.ALL'),
                            value: 'ALL',
                        },
                    ]);
                }
            }
        }
    }, [pageCheckoutReducer]);

    // useEffect(() => {
    //     let payload = {
    //         search: {
    //             keyword: keyWord,
    //             is_verify: statusVerify,
    //             customer_is_lock: statusActive,
    //             paid_status: statusPaid
    //         },
    //         ...conditions,
    //         paged: conditions.page,
    //     };
    //     dispatch(courseActions.courseCustomerList(payload));
    // }, [conditions]);

    const onFilter = (page = 1) => {
        let payload = {
            search: {
                keyword: keyWord,
                is_verify: statusVerify,
                customer_is_lock: statusActive,
                paid_status: statusPaid
            },
            ...conditions,
            paged: page,
            page,
        };

        setConditions({
            ...conditions,
            page,
            paged: page,
        });
        dispatch(courseActions.courseCustomerList(payload));
    };

    useEffect(() => {
        if (statusLearn) {
            handleSearchLearnedCourse();
        }
    }, [statusLearn]);

    useEffect(() => {
        if (isFirstGetList) {
            onFilter();
        }
    }, [statusActive, statusPaid]);

    useEffect(() => {
        if (courseReducer.action == courseTypes.COURSE_CUSTOMER_LIST) {
            if (courseReducer.status) {
                if (courseReducer.customers) {
                    setCustomers([...courseReducer.customers]);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }

        if (courseReducer.action == courseTypes.CREATE_ACCOUNT) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                setIsShowModalAddAccount(false);

                let payload = {
                    search: {
                        ...defaultSearchParams
                    },
                    ...conditions,
                };
                dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }

        if (courseReducer.action == courseTypes.COURSE_CUSTOMER_LIST_OWNED_COURSE) {
            if (courseReducer.status) {
                if (courseReducer.customers) {
                    setOwnedCourses(courseReducer.customers);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.LOCK_CUSTOMER) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                setIsShowModalMembershipKey(false);
                let inputCheck = Array.from(document.querySelectorAll('#checkInputItem'));
                for (let i = 0; i < inputCheck.length; i++) {
                    inputCheck[i].checked = false;
                }
                document.getElementById('checkAllItem').checked = false;
                setSelectedIds([]);

                let payload = {
                    search: {
                        ...defaultSearchParams
                    },
                    ...conditions,
                    paged: conditions.page,
                };
                dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }

        if (courseReducer.action == courseTypes.UPDATE_CUSTOMER) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                let payload = {
                    search: {
                        ...defaultSearchParams
                    },
                    ...conditions,
                    paged: conditions.page,
                };
                dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
        if (courseReducer.action == courseTypes.CANCEL_LOCK_CUSTOMER) {
            if (courseReducer.status) {
                window.LadiUI.toastCustom('success', '', courseReducer.message);
                window.LadiUI.closeModal('confirm-enable-customer');

                let inputCheck = Array.from(document.querySelectorAll('#checkInputItem'));
                for (let i = 0; i < inputCheck.length; i++) {
                    inputCheck[i].checked = false;
                }
                document.getElementById('checkAllItem').checked = false;
                setSelectedIds([]);

                let payload = {
                    search: {
                        ...defaultSearchParams
                    },
                    ...conditions,
                    paged: conditions.page,
                };
                dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }
    }, [courseReducer]);

    useEffect(() => {
        if (customerReducer.action == customerTypes.VERIFY_ACCOUNT) {
            if (customerReducer.status) {
                window.LadiUI.toastCustom('success', '', customerReducer.message);
                // let payload = {
                //     search: {},
                //     ...conditions,
                // };
                // dispatch(courseActions.courseCustomerList(payload));
            } else {
                window.LadiUI.showErrorMessage('Thông báo', customerReducer.message, 'OK');
            }
        }

        if (customerReducer.action == customerTypes.GET_PASSWORD_TOKEN) {
            if (customerReducer.status) {
                if (customerReducer.password_token) {
                    setDataModalShowPassword({
                        ...dataModalShowPassword,
                        token: customerReducer.password_token,
                    });

                    setIsShowModalPassWord(true);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', customerReducer.message, 'OK');
            }
        }
    }, [customerReducer]);

    const onChangeLimit = (option) => {
        setConditions({
            ...conditions,
            limit: option.value,
            page: 1
        });

        onFilter();
    };

    const onPageChange = (pageNumber) => {
        setConditions({
            ...conditions,
            page: pageNumber,
        });
        onFilter(pageNumber);
    };

    const checkItem = (event, customerID) => {
        const { checked } = event.target;

        let _selectedIds = [...selectedIds];

        if (checked) {
            if (!includes(_selectedIds, customerID)) {
                _selectedIds.push(customerID);
            }

            if (_selectedIds.length == customers.length) {
                document.getElementById('checkAllItem').checked = true;
            }
        } else {
            document.getElementById('checkAllItem').checked = false;
            remove(_selectedIds, (selectedID) => selectedID == customerID);
        }

        setSelectedIds(_selectedIds);
    };

    const handleMembershipKey = (customerID) => {
        if (customerID) {
            setSelectedCustomerId(customerID);
            setIsShowModalMembershipKey(true);
        }
    };

    const handleCancelMembershipKey = (customerID) => {
        if (customerID) {
            setSelectedCustomerId(customerID);
            // setIsShowModalMembershipKey(true);
            window.LadiUI.showModal('confirm-enable-customer');
        }
    };

    const handleOpenModalReasonLock = (reason) => {
        if (reason) {
            setSelectedReason(reason);
            setIsShowModalReasonLock(true);
        }
    };

    const handleVerifyAccount = (customerID) => {
        if (customerID) {
            dispatch(
                customerActions.verifyAccount({
                    customer_id: customerID,
                })
            );
        }
    };


    const handleShowModalChangeStudent = async (customer) => {
        if (customer) {
            setSelectedCustomer(customer);
            setIsShowModalChangeAccount(true);
        }
    };
    const handleShowModalOwnedCourse = async (customerID) => {
        if (customerID) {
            setSelectedCustomerId(customerID);
            let payload = {
                search: {
                    customer_id: customerID,
                },
                ...conditions,
                limit: 100,
            };
            await dispatch(courseActions.courseCustomerListOwnedCourse(payload));

            setIsShowModalOwnedCourse(true);
        }
    };

    const handleShowModalHistoryLesson = async (customerID) => {
        if (customerID) {
            setSelectedCustomerId(customerID);
            let payload = {
                customer_id: customerID,
                limit: 100,
            };
            await dispatch(courseActions.historyLearn(payload));

            setIsShowModalHistory(true);
        }
    };


    const handleGetPassword = (email) => {
        if (email) {
            setDataModalShowPassword({
                ...dataModalShowPassword,
                email: email,
            });
            dispatch(customerActions.getPasswordToken(email));
        }
    };


    const fetchListCustomer = () => {
        return customers.map((item, index) => {
            return (
                <tr key={index} className='ladiui table-vertical main'>
                    <td>
                        <input
                            id='checkInputItem'
                            type='checkbox'
                            onClick={(event) => checkItem(event, item.customer_id)}
                            className='ladiui checkbox size-checkbox form-check-input-checkbox vertical-middle'
                        />
                    </td>
                    <td><div className='ls-text-primary ls-ellipsis-text' style={{ '--line': 2 }}>{(item.first_name ? item.first_name : '') + (item.last_name ? item.last_name : '')}</div></td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    {/* <td>{item.is_verify && item.is_verify == 1 ? 'Đã xác minh' : 'Chưa xác minh'}</td> */}
                    <td>{item.buy_price && item.buy_price > 0 ? paidLabelAccountConfigs : freeLabelAccountConfigs}</td>
                    <td>
                        {item.customer_is_lock == 0 ? (
                            <div className='ladiui flex-center-start'>
                                <i className='ldicon-status-success mr-8 ls-min-size-12' />
                                <span> Đang hoạt động</span>
                            </div>
                        ) : (
                            <div className='ladiui flex-center-start'>
                                <i className='ldicon-status-error mr-8 ls-min-size-12' />
                                <span> Đã vô hiệu</span>
                            </div>
                        )}
                    </td>
                    <td>{item.customer_created_at ? baseHelper.formatDateToStr(item.customer_created_at) : ''}</td>

                    <td className='text-right pd-0'>
                        <div className='ladiui btn-group'>
                            <div className='ladiui dropdown hide-mt ba-c'>
                                <button data-toggle='dropdown' className='ladiui-btn-dropdown dropdown-toggle'>
                                    <i className='ladiui icon icon-ldp-dot'></i>
                                </button>
                                <ul className='ladiui dropdown-menu r-0'>
                                    <li onClick={() => handleShowModalHistoryLesson(item.customer_id)}>
                                        <a className='ladiui dropdown-item space-nowrap'>Bài học đã xem</a>
                                    </li>

                                    <li onClick={() => handleShowModalOwnedCourse(item.customer_id)}>
                                        <a className='ladiui dropdown-item space-nowrap'>Khóa học đã sở hữu</a>
                                    </li>
                                    <li onClick={() => handleShowModalChangeStudent(item)}>
                                        <a className='ladiui dropdown-item space-nowrap'>Thay đổi thông tin</a>
                                    </li>
                                    {!item.customer_is_lock || item.customer_is_lock == 0 ? (
                                        <li onClick={() => handleMembershipKey(item.customer_id)}>
                                            <a className='ladiui dropdown-item space-nowrap'>Vô hiệu tài khoản</a>
                                        </li>
                                    ) : (
                                        <>
                                            <li onClick={() => handleOpenModalReasonLock(item.customer_lock_reason)}>
                                                <a className='ladiui dropdown-item space-nowrap'>Lý do vô hiệu</a>
                                            </li>
                                            <li onClick={() => handleCancelMembershipKey(item.customer_id)}>
                                                <a className='ladiui dropdown-item space-nowrap'>Kích hoạt tài khoản</a>
                                            </li>
                                        </>
                                    )}
                                    {!item.is_verify || item.is_verify == 0 ? (
                                        <li onClick={() => handleVerifyAccount(item.customer_id)}>
                                            <a className='ladiui dropdown-item space-nowrap'>Xác minh tài khoản</a>
                                        </li>
                                    ) : (
                                        ''
                                    )}
                                    {isOaOa && (
                                        <li onClick={() => handleGetPassword(item.email)}>
                                            <a className='ladiui dropdown-item space-nowrap'>Lấy mật khẩu</a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            );
        });
    };

    const checkAllItem = (event) => {
        const { checked } = event.target;

        let selectedIDsCm = [];
        if (checked) {
            selectedIDsCm = map(customers, (customer) => customer.customer_id);

            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = true;
            });
        } else {
            window.LadiUI.forEach(window.LadiUI.querySelector('.ladiui.checkbox'), function (e) {
                e.checked = false;
            });
        }

        setSelectedIds(selectedIDsCm);
    };

    const handleLockCustomer = (reason) => {
        let payload = {};
        if (selectedCustomerId) {
            payload = {
                customer_id: selectedCustomerId,
                reason: reason,
            };
        } else {
            payload = {
                customer_ids: selectedIds,
                reason: reason,
            };
        }

        dispatch(courseActions.lockCustomer(payload));
    };

    const handleCancelLockCustomer = () => {
        let payload = {};
        if (selectedCustomerId) {
            payload = {
                customer_id: selectedCustomerId,
            };
        } else {
            payload = {
                customer_ids: selectedIds,
            };
        }

        dispatch(courseActions.cancelLockCustomer(payload));
    };

    const eventSelectAction = (action, event) => {
        switch (action) {
            case 'LOCK':
                setIsShowModalMembershipKey(true);
                break;
            case 'UN_LOCK':
                // setIsShowModalMembershipKey(true);
                window.LadiUI.showModal('confirm-enable-customer');
                break;
            default:
        }
    };

    const onKeyDownInput = (event) => {
        if (event.key === 'Enter') {
            let payload = {
                search: {
                    keyword: keyWord,
                    is_verify: statusVerify,
                    customer_is_lock: statusActive,
                    paid_status: statusPaid
                },
                ...conditions,
            };
            dispatch(courseActions.courseCustomerList(payload));
        }
    };

    const handleSearchCustomer = () => {
        let payload = {
            search: {
                keyword: keyWord,
                is_verify: statusVerify,
                customer_is_lock: statusActive,
                paid_status: statusPaid
            },
            ...conditions,
        };
        dispatch(courseActions.courseCustomerList(payload));
    };

    const onChangeSearchOwnedCourse = (e) => {
        let { value } = e.target;

        setValueSearchOwnedCourse(value);
    };

    const onKeyDownInputOwnedCourse = async (event) => {
        if (event.key === 'Enter') {
            let payload = {
                search: {
                    customer_id: selectedCustomerId,
                    keyword: valueSearchOwnedCourse,
                },
                ...conditions,
            };
            await dispatch(courseActions.courseCustomerListOwnedCourse(payload));
        }
    };

    const handleSearchOwnedCourse = async () => {
        let payload = {
            search: {
                customer_id: selectedCustomerId,
                keyword: valueSearchOwnedCourse,
            },
            ...conditions,
            limit: 100,
        };
        await dispatch(courseActions.courseCustomerListOwnedCourse(payload));
    };

    const onChangeSearchLearnedCourse = (e) => {
        let { value } = e.target;

        setValueSearchLearnCourse(value);
    };

    const onChangeStatusLearn = (value) => {
        setStatusLearn(value);
    };
    const onKeyDownInputLearnedCourse = async (event) => {
        if (event.key === 'Enter') {
            let payload = {
                customer_id: selectedCustomerId,
                limit: 100,
                search: valueSearchLearnCourse,
                status: statusLearn
            };
            await dispatch(courseActions.historyLearn(payload));
            setIsShowModalHistory(true);
        }
    };

    const handleSearchLearnedCourse = async () => {

        let payload = {
            customer_id: selectedCustomerId,
            limit: 100,
            search: valueSearchLearnCourse,
            status: statusLearn
        };
        await dispatch(courseActions.historyLearn(payload));
        setIsShowModalHistory(true);
    };

    const isLoadingData = courseReducer.loading && courseReducer.waiting == courseTypes.COURSE_CUSTOMER_LIST;

    return (
        <div className='page-content page-course ls-pt-0'>
            <div className='header-page ls-px-0'>
                <div className='heading'>
                    <h1>Học viên</h1>
                    {/* <p>Quản lý các học viên tham gia khoá học</p> */}
                </div>

                <div className='action-btn flex items-center'>
                    <button
                        type='button'
                        className='ladiui btn btn-primary btn-lg btn-open-modal'
                        data-toggle='modal'
                        onClick={() => setIsShowModalAddAccount(true)}
                    >
                        <img className='ladiui btn-custom-img' src='https://w.ladicdn.com/design-system/icons/icon-ldp-add.svg' alt='' />
                        <div className='ladiui btn-custom-text '>{t('COURSES.ADD_STUDENT')}</div>
                    </button>
                </div>
            </div>

            <div className='block-filter'>
                <div className='block-search ls-flex ls-justify-start ls-gap-16'>
                    <div className='ladiui search-group'>
                        <input
                            id='keyword_search'
                            className='ladiui search-field dropdown-toggle form-control search-width'
                            name='searchName'
                            placeholder='Tìm kiếm học viên'
                            aria-expanded='false'
                            value={keyWord}
                            onChange={(event) => {
                                setKeyWord(event.target.value);
                                if (event?.target?.value == '') {
                                    onFilter();
                                }
                            }}
                            onKeyDown={onKeyDownInput}
                        />
                        <i className='ladiui icon icon-search' onClick={() => handleSearchCustomer()}></i>
                    </div>

                    <div className='ladiui flex gap-16'>
                        {/* <div className='ladiui flex-row'>
                            <div className='ladiui btn-group'>
                                <div className='ladiui dropdown'>
                                    <button className='ladiui btn btn-outline-light dropdown-toggle select-dropdown' data-toggle='dropdown'>
                                        <span className='ladiui dropdown-text'>{'Trạng thái xác minh'}</span>
                                    </button>
                                    <ul className='ladiui dropdown-menu'>
                                        {appConfig.LIST_STATUS_VERIFY.map((item) => (
                                            <li key={item.value} onClick={(e) => setStatusVerify(item.value)}>
                                                <a className='ladiui dropdown-item' href='#'>
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div> */}
                        <div className="ladiui flex-row">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                        <i className="ladiui icon"></i>
                                        <span className="ladiui dropdown-text">{"Tất cả"}</span>
                                    </button>
                                    <ul className="ladiui dropdown-menu" defaultValue={statusPaid}>
                                        {statusPaids.map((item) => (
                                            <li key={item.value} onClick={(e) => setStatusPaid(item.value)}>
                                                <a className="ladiui dropdown-item" href="#">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="ladiui flex-row">
                            <div className="ladiui btn-group">
                                <div className="ladiui dropdown">
                                    <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                        <i className="ladiui icon"></i>
                                        <span className="ladiui dropdown-text">{"Tất cả"}</span>
                                    </button>
                                    <ul className="ladiui dropdown-menu" defaultValue={statusActive}>
                                        {appConfig.LIST_STATUS_ACTIVE.map((item) => (
                                            <li key={item.value} onClick={(e) => setStatusActive(item.value)}>
                                                <a className="ladiui dropdown-item" href="#">
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='block-content'>
                {isLoadingData ? (
                    <LoadingTable />
                ) : (
                    <div className='setting-content-domain-course config-panel'>
                        <table className={`ladiui table text-left`}>
                            <thead style={{ position: 'relative' }}>
                                <tr className='ladiui table-vertical'>
                                    <th scope='col' className='ladiui checkall'>
                                        <input
                                            id='checkAllItem'
                                            onClick={checkAllItem}
                                            type='checkbox'
                                            className='ladiui checkbox size-checkbox form-check-input-checkbox ladiui-checkall vertical-middle'
                                        />
                                    </th>
                                    {selectedIds && selectedIds.length <= 0 ? (
                                        <th scope='col' name='full_name'>
                                            Tên học viên
                                        </th>
                                    ) : (
                                        <th>
                                            Tên học viên
                                            <LadiDropdownMenu
                                                labelDropdown={'Chọn hành động'}
                                                listProp={listAction}
                                                defaultTextProp={t('ACTIONS.TITLE')}
                                                hideLabel={true}
                                                cbProp={eventSelectAction}
                                                classSize='btn-sm'
                                                classDropdown='w-180 action-record-table'
                                            />
                                        </th>
                                    )}
                                    <th scope='col' name='full_name'>
                                        Email
                                    </th>
                                    <th scope='col' name='email' className='text-left'>
                                        Số điện thoại
                                    </th>
                                    <th scope='col' name='verify' className='text-left'>
                                        Tài khoản
                                    </th>
                                    <th scope='col' name='status' className='text-left'>
                                        Trạng thái
                                    </th>
                                    <th scope='col' name='phone' className='text-left'>
                                        Thời gian đăng ký
                                    </th>
                                    <th scope='col' />
                                </tr>
                            </thead>
                            <tbody>
                                {fetchListCustomer()}
                                {customers && customers.length <= 0 && (
                                    <tr className='text-center'>
                                        <td colSpan='100%'>{t('NO_DATA')}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {courseReducer.totalRecord && courseReducer.totalRecord > 0 && customers.length > 0 ? (
                            <LadiPagination
                                conditionsProp={conditions}
                                listName={t('PAGINATION.ITEMS')}
                                message={''}
                                items={customers || []}
                                total={courseReducer.totalRecord}
                                limitAction={onChangeLimit}
                                pageAction={onPageChange}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                )}

            </div>

            {isShowModalMembershipKey && (
                <ModalMembershipKey
                    onClose={() => setIsShowModalMembershipKey(false)}
                    isShow={isShowModalMembershipKey}
                    innerStyle={{ display: 'none' }}
                    handleLockCustomer={handleLockCustomer}
                    // handleCancelLockCustomer={handleCancelLockCustomer}
                    isStudentCourse={false}
                />
            )}

            {isShowModalReasonLock && (
                <ModalReasonLock
                    onClose={() => setIsShowModalReasonLock(false)}
                    isShow={isShowModalReasonLock}
                    innerStyle={{ display: 'none' }}
                    isStudentCourse={false}
                    selectedReason={selectedReason}
                />
            )}

            {isShowModalOwnedCourse && (
                <ModalOwnedCourse
                    onClose={() => setIsShowModalOwnedCourse(false)}
                    isShow={isShowModalOwnedCourse}
                    innerStyle={{ display: 'none' }}
                    dataOwnedCourses={ownedCourses || []}
                    onKeyDownInputOwnedCourse={onKeyDownInputOwnedCourse}
                    onChangeSearchVariantInput={onChangeSearchOwnedCourse}
                    searchOwnedCourse={handleSearchOwnedCourse}
                    customerID={selectedCustomerId}
                />
            )}

            {isShowModalHistory && (
                <ModalHistoryLearn
                    onClose={() => setIsShowModalHistory(false)}
                    isShow={isShowModalHistory}
                    innerStyle={{ display: 'none' }}
                    dataOwnedCourses={ownedCourses || []}
                    onKeyDownInputOwnedCourse={onKeyDownInputLearnedCourse}
                    onChangeSearchVariantInput={onChangeSearchLearnedCourse}
                    searchOwnedCourse={handleSearchLearnedCourse}
                    onChangeStatusLearn={onChangeStatusLearn}

                    customerID={selectedCustomerId}
                />
            )}

            {isShowModalAddAccount && (
                <ModalAddAccount
                    onClose={() => setIsShowModalAddAccount(false)}
                    isShow={isShowModalAddAccount}
                    innerStyle={{ display: 'none' }}
                    styleCustom={{ position: 'unset' }}
                />
            )}

            {isShowModalChangeAccount && (
                <ModalChangeStudent
                    onClose={() => setIsShowModalChangeAccount(false)}
                    isShow={isShowModalChangeAccount}
                    innerStyle={{ display: 'none' }}
                    styleCustom={{ position: 'unset' }}
                    selectedCustomer={selectedCustomer}
                />
            )}

            {isShowModalPassWord && (
                <ModalShowPasswordToken
                    onClose={() => setIsShowModalPassWord(false)}
                    isShow={isShowModalPassWord}
                    dataPassword={dataModalShowPassword}
                    innerStyle={{ display: 'none' }}
                    styleCustom={{ position: 'unset' }}
                />
            )}

            <ConfirmModal
                id='confirm-enable-customer'
                title={'Kích hoạt tài khoản'}
                content={'Tài khoản được kích hoạt trở lại sẽ có thể đăng nhập vào trang khóa học. Bạn có muốn thực hiện hành động này?'}
                cancelText={t('ACTIONS.CANCEL')}
                okText={'Kích hoạt'}
                isBtnPrimary={true}
                onOk={handleCancelLockCustomer}
            // isLoading={this.state.loadingDelete}
            />
        </div>
    );
});
PageStudentManagement.propTypes = {};

export default PageStudentManagement;
