/** @format */

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import paymentActions from '../redux/futures/payment/actions';

import { map, includes, find, remove, truncate } from 'lodash';
import produce from 'immer';
import baseHelper from '../helpers/BaseHelper';
import Dropdown from './Dropdown';
import appConfig from '../config/app';
import ModalAddCustomField from './ModalAddCustomField';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Input from "./Input";

const PanelSettingCheckout = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const paymentReducer = useSelector((state) => state.payment);

    const [isShowMethods, setIsShowMethods] = useState(false);
    const [paymentCode, setPaymentCode] = useState(null);

    const [configCustomer, setConfigCustomer] = useState({
        configurable: true,

        enable_full_name: false,
        required_full_name: false,

        enable_email: true,
        required_email: true,

        enable_phone: true,
        required_phone: true,

        enable_note: false,
        required_note: false,

        enable_address: false,
        required_address: false,

        enable_location: false,
        required_location: false,

        enable_zipcode: false,
        required_zipcode: false,
    });

    const [configShipping, setConfigShipping] = useState({
        configurable: true,

        enable_full_name: true,
        required_full_name: true,

        enable_phone: true,
        required_phone: true,

        enable_note: true,
        required_note: true,

        enable_email: true,
        required_email: true,

        enable_address: true,
        required_address: true,

        enable_location: false,
        required_location: false,

        enable_zipcode: false,
        required_zipcode: false,
    });

    const [configPayment, setConfigPayment] = useState([]);

    const [configCart, setConfigCart] = useState({
        enable_cart: false,
    });

    const [configStoreDescription, setConfigStoreDescription] = useState({
        enable_store_description: true,
    });

    const [configDiscount, setConfigDiscount] = useState({
        enable_discount: true,
    });

    const [configShowCoupon, setConfigShowCoupon] = useState(false);
    const [configShippingMethod, setConfigShippingMethod] = useState({
        enable_shipping_method: true,
    });

    const [configOnePageCheckout, setConfigOnePageCheckout] = useState({
        enable_one_page_checkout: false,
    });

    const [configShowCountry, setConfigShowCountry] = useState(false);

    const [configShowInvoice, setConfigShowInvoice] = useState(false);

    const [configMultiNational, setConfigMultiNational] = useState(false);

    const [configLanguage, setConfigLanguage] = useState(appConfig.LANGUAGES.VI.code);

    const [tabActiveSelected, setTabActiveSelected] = useState('TAB_CUSTOMER_INFO');

    const [isShowModalAddCustomField, setIsShowModalAddCustomField] = useState(false);

    const [dataCustomField, setDataCustomField] = useState([]);

    const [configConfirmAgreementToTerms, setConfigConfirmAgreementToTerms] = useState({
        is_required: false,
        htlm_text: ""
    });


    const languages = appConfig.LANGUAGES;

    useEffect(() => {
        dispatch(paymentActions.listGateways());
    }, []);

    // useEffect(() => {
    //   if (configCart.enable_cart === false) {
    //     setConfigStoreDescription({
    //       enable_shipping_method: false,
    //     });
    //   }
    // }, [configCart]);

    useEffect(() => {
        if (props.checkoutConfig) {
            setConfigCustomer(
                produce(configCustomer, (draft) => {
                    const _configCustomer = props.checkoutConfig.config_customer || {};

                    draft.configurable = !baseHelper.isEmpty(_configCustomer.configurable)
                        ? _configCustomer.configurable
                        : draft.configurable;

                    draft.enable_full_name = !baseHelper.isEmpty(_configCustomer.enable_full_name)
                        ? _configCustomer.enable_full_name
                        : draft.enable_full_name;
                    draft.enable_full_name = !baseHelper.isEmpty(_configCustomer.enable_full_name)
                        ? _configCustomer.enable_full_name
                        : draft.enable_full_name;
                    draft.enable_email = !baseHelper.isEmpty(_configCustomer.enable_email)
                        ? _configCustomer.enable_email
                        : draft.enable_email;
                    draft.enable_phone = !baseHelper.isEmpty(_configCustomer.enable_phone)
                        ? _configCustomer.enable_phone
                        : draft.enable_phone;
                    draft.enable_note = !baseHelper.isEmpty(_configCustomer.enable_note) ? _configCustomer.enable_note : draft.enable_note;
                    draft.enable_address = !baseHelper.isEmpty(_configCustomer.enable_address)
                        ? _configCustomer.enable_address
                        : draft.enable_address;
                    draft.enable_location = !baseHelper.isEmpty(_configCustomer.enable_location)
                        ? _configCustomer.enable_location
                        : draft.enable_location;

                    draft.enable_zipcode = !baseHelper.isEmpty(_configCustomer.enable_zipcode)
                        ? _configCustomer.enable_zipcode
                        : draft.enable_zipcode;

                    draft.required_full_name = !baseHelper.isEmpty(_configCustomer.required_full_name)
                        ? _configCustomer.required_full_name
                        : draft.required_full_name;
                    draft.required_full_name = !baseHelper.isEmpty(_configCustomer.required_full_name)
                        ? _configCustomer.required_full_name
                        : draft.required_full_name;
                    draft.required_email = !baseHelper.isEmpty(_configCustomer.required_email)
                        ? _configCustomer.required_email
                        : draft.required_email;
                    draft.required_phone = !baseHelper.isEmpty(_configCustomer.required_phone)
                        ? _configCustomer.required_phone
                        : draft.required_phone;
                    draft.required_note = !baseHelper.isEmpty(_configCustomer.required_note)
                        ? _configCustomer.required_note
                        : draft.required_note;
                    draft.required_address = !baseHelper.isEmpty(_configCustomer.required_address)
                        ? _configCustomer.required_address
                        : draft.required_address;
                    draft.required_location = !baseHelper.isEmpty(_configCustomer.required_location)
                        ? _configCustomer.required_location
                        : draft.required_location;
                    draft.required_location = !baseHelper.isEmpty(_configCustomer.required_location)
                        ? _configCustomer.required_location
                        : draft.required_location;

                    draft.required_zipcode = !baseHelper.isEmpty(_configCustomer.required_zipcode)
                        ? _configCustomer.required_zipcode
                        : draft.required_zipcode;

                    draft.custom_field =
                        _configCustomer.custom_field && _configCustomer.custom_field.length > 0 ? _configCustomer.custom_field : [];
                })
            );
            setConfigShipping(
                produce(configShipping, (draft) => {
                    const _configShipping = props.checkoutConfig.config_shipping || {};
                    draft.configurable = !baseHelper.isEmpty(_configShipping.configurable)
                        ? _configShipping.configurable
                        : draft.configurable;

                    draft.enable_full_name = !baseHelper.isEmpty(_configShipping.enable_full_name)
                        ? _configShipping.enable_full_name
                        : draft.enable_full_name;
                    draft.enable_phone = !baseHelper.isEmpty(_configShipping.enable_phone)
                        ? _configShipping.enable_phone
                        : draft.enable_phone;
                    draft.enable_email = !baseHelper.isEmpty(_configShipping.enable_email)
                        ? _configShipping.enable_email
                        : draft.enable_email;
                    draft.enable_note = !baseHelper.isEmpty(_configShipping.enable_note) ? _configShipping.enable_note : draft.enable_note;
                    draft.enable_address = !baseHelper.isEmpty(_configShipping.enable_address)
                        ? _configShipping.enable_address
                        : draft.enable_address;
                    draft.enable_location = !baseHelper.isEmpty(_configShipping.enable_location)
                        ? _configShipping.enable_location
                        : draft.enable_location;
                    draft.enable_zipcode = !baseHelper.isEmpty(_configShipping.enable_zipcode)
                        ? _configShipping.enable_zipcode
                        : draft.enable_zipcode;

                    draft.required_full_name = !baseHelper.isEmpty(_configShipping.required_full_name)
                        ? _configShipping.required_full_name
                        : draft.required_full_name;
                    draft.required_phone = !baseHelper.isEmpty(_configShipping.required_phone)
                        ? _configShipping.required_phone
                        : draft.required_phone;
                    draft.required_email = !baseHelper.isEmpty(_configShipping.required_email)
                        ? _configShipping.required_email
                        : draft.required_email;
                    draft.required_note = !baseHelper.isEmpty(_configShipping.required_note)
                        ? _configShipping.required_note
                        : draft.required_note;
                    draft.required_address = !baseHelper.isEmpty(_configShipping.required_address)
                        ? _configShipping.required_address
                        : draft.required_address;
                    draft.required_location = !baseHelper.isEmpty(_configShipping.required_location)
                        ? _configShipping.required_location
                        : draft.required_location;
                    draft.required_zipcode = !baseHelper.isEmpty(_configShipping.required_zipcode)
                        ? _configShipping.required_zipcode
                        : draft.required_zipcode;

                    draft.custom_field =
                        _configShipping.custom_field && _configShipping.custom_field.length > 0 ? _configShipping.custom_field : [];
                })
            );
            const _configPayment = props.checkoutConfig.config_payment || [];
            setConfigPayment(produce(_configPayment, (draft) => {}));

            const _configCart = props.checkoutConfig.config_cart || {};
            setConfigCart(produce(_configCart, (draft) => {}));

            const _configDiscount = props.checkoutConfig.config_discount || {};
            setConfigDiscount(produce(_configDiscount, (draft) => {}));

            const _configShippingMethod = props.checkoutConfig.config_shipping_method || {};
            setConfigShippingMethod(produce(_configShippingMethod, (draft) => {}));

            const _configStoreDescription = props.checkoutConfig.config_store_description || {};
            setConfigStoreDescription(produce(_configStoreDescription, (draft) => {}));

            const _configLanguage = props.checkoutConfig.config_language || {};

            setConfigLanguage(produce(_configLanguage, (draft) => {}));

            const _configOnePageCheckout = props.checkoutConfig.config_one_page_checkout || {};
            setConfigOnePageCheckout(produce(_configOnePageCheckout, (draft) => {}));

            const _configConfirmAgreementToTerms = props.checkoutConfig.policy_config || {};
            setConfigConfirmAgreementToTerms(produce(_configConfirmAgreementToTerms, (draft) => {}));

            const _configIsShowCoupon = props.checkoutConfig.isShowCoupon || false;
            setConfigShowCoupon(_configIsShowCoupon);

            const _configIsShowInvoice = props.checkoutConfig.isShowInvoice || false;
            setConfigShowInvoice(_configIsShowInvoice);

            const _configMultiNational = props.checkoutConfig.hidden_multi_national || false;
            setConfigMultiNational(_configMultiNational);

            const _configShowCountry = props.checkoutConfig.isShowCountry || false;
            setConfigShowCountry(_configShowCountry);
        }
    }, [props.checkoutConfig]);

    useEffect(() => {
        if (!props.checkoutConfig || !props.checkoutConfig.config_payment || props.checkoutConfig.config_payment.length == 0) {
            if (paymentReducer.gateways.length > 0) {
                const initConfigPayment = [];
                for (let i = 0; i < paymentReducer.gateways.length; i++) {
                    const itemGateway = paymentReducer.gateways[i];
                    const itemConfigPayment = {
                        code: itemGateway.code,
                        methods: itemGateway.config.active_payment_methods,
                        is_active: i == 0,
                    };

                    initConfigPayment.push(itemConfigPayment);
                }
                setConfigPayment(initConfigPayment);
            } else {
                setConfigPayment([]);
            }
        } else {
            if (!baseHelper.isEmpty(configPayment)) {
                const initConfigPayment = [];
                for (const item of configPayment) {
                    const result = find(paymentReducer.gateways, (_item) => _item.code == item.code);
                    if (result) {
                        let newItem = item;
                        if (result.config.active_payment_methods) {
                            newItem = produce(item, (draft) => {
                                remove(draft.methods, (_item) => !includes(result.config.active_payment_methods, _item));
                                for (const _item of result.config.active_payment_methods) {
                                    if (!includes(draft.methods, _item)) {
                                        draft.methods.push(_item);
                                    }
                                }
                            });
                        }
                        initConfigPayment.push(newItem);
                    }
                }

                for (const item of paymentReducer.gateways) {
                    const result = find(configPayment, (_item) => _item.code == item.code);
                    if (!result) {
                        initConfigPayment.push({
                            code: item.code,
                            methods: item.config.active_payment_methods,
                            is_active: false,
                        });
                    }
                }
                setConfigPayment(initConfigPayment);
            }
        }
    }, [paymentReducer.gateways]);

    const onChangeConfigCustomer = (event) => {
        setConfigCustomer(
            produce(configCustomer, (draft) => {
                const newValue = !draft[event.target.name];
                if (event.target.name != 'required_phone' || event.target.name != 'required_email') {
                    draft[event.target.name] = newValue;

                    if (event.target.name == 'enable_phone') {
                        draft.required_phone = newValue;
                    }

                    if (event.target.name == 'enable_email') {
                        draft.required_email = newValue;
                    }
                } else {
                    if (event.target.name == 'required_phone') {
                        if (draft.required_email) {
                            draft.required_phone = newValue;
                        }
                    }

                    if (event.target.name == 'required_email') {
                        if (draft.required_phone) {
                            draft.required_email = newValue;
                        }
                    }
                }
            })
        );
    };

    const onChangeConfigCustomerField = (event, index) => {
        let _customer_field = [...configCustomer.custom_field];

        let _dataCustomField = _customer_field.map((item, _index) => {
            if (_index == index) {
                item = produce(item, (draft) => {
                    const newValue = !draft[event.target.name];
                    draft[event.target.name] = newValue;
                });
            }

            return item;
        });

        setConfigCustomer({
            ...configCustomer,
            custom_field: _dataCustomField,
        });
    };

    const handleDeleteCustomerFieldItem = (index) => {
        let _customer_fields = [...configCustomer.custom_field];

        _customer_fields.splice(index, 1);

        setConfigCustomer({
            ...configCustomer,
            custom_field: _customer_fields,
        });
    };

    const onChangeConfigShipping = (event) => {
        setConfigShipping(
            produce(configShipping, (draft) => {
                const newValue = !draft[event.target.name];

                if (event.target.name == 'enable_email') {
                    if (newValue == false && draft.required_phone == false) {
                        return;
                    }

                    // draft.required_email = newValue;
                }
                if (event.target.name == 'enable_phone') {
                    draft.required_phone = newValue;
                }

                draft[event.target.name] = newValue;
            })
        );
    };

    // const onChangeConfigShippingField = (event, index) => {
    //   let _customer_field = [...configShipping.custom_field];

    //   _customer_field.map((item, _index) => {
    //     if (_index == index) {
    //       let newValue = !item[event.target.name];
    //       item[event.target.name] = newValue;
    //     }
    //   })

    //   setConfigShipping({
    //     ...configShipping,
    //     custom_field: _customer_field
    //   })

    // }

    const onChangeConfigShippingField = (event, index) => {
        let _customer_field = [...configShipping.custom_field];

        let _dataCustomField = _customer_field.map((item, _index) => {
            if (_index == index) {
                item = produce(item, (draft) => {
                    const newValue = !draft[event.target.name];
                    draft[event.target.name] = newValue;
                });
            }

            return item;
        });

        setConfigShipping({
            ...configShipping,
            custom_field: _dataCustomField,
        });
    };

    const handleDeleteShippingFieldItem = (index) => {
        let _customer_fields = [...configShipping.custom_field];

        _customer_fields.splice(index, 1);

        setConfigShipping({
            ...configShipping,
            custom_field: _customer_fields,
        });
    };

    const onChangeConfigPayment = (index) => {
        setConfigPayment(
            produce(configPayment, (draft) => {
                draft[index].is_active = !draft[index].is_active;
            })
        );
    };

    const onChangeLanguage = (selectedItem) => {
        setConfigLanguage(selectedItem.code);
    };

    useImperativeHandle(ref, () => ({
        validate() {
            if (!configCustomer.configurable && !configShipping.configurable) {
                window.LadiUI.toastCustom('danger', '', t('CHECKOUTS.REQUIRED_CUSTOMER_OR_SHIPPING'));
                return false;
            }
            const resultRequireEmailOrPhone =
                (configCustomer.enable_email && configCustomer.required_email) ||
                (configCustomer.enable_phone && configCustomer.required_phone);
            if (!resultRequireEmailOrPhone) {
                window.LadiUI.toastCustom('danger', '', t('CHECKOUTS.REQUIRED_EMAIL_OR_PHONE'));
                return false;
            }

            if (baseHelper.isEmpty(configPayment)) {
                window.LadiUI.toastCustom('danger', '', t('CHECKOUTS.REQUIRED_AT_LEAST_ONE_CONFIG_PAYMENT'));
                return false;
            }

            return true;
        },
        getData() {
            return {
                config_customer: produce(configCustomer, (draft) => {}),
                config_shipping: produce(configShipping, (draft) => {}),
                config_payment: produce(configPayment, (draft) => {}),
                config_cart: produce(configCart, (draft) => {}),
                config_discount: produce(configDiscount, (draft) => {}),
                config_shipping_method: produce(configShippingMethod, (draft) => {}),
                config_language: produce(configLanguage, (draft) => {}),
                config_store_description: produce(configStoreDescription, (draft) => {}),
                config_one_page_checkout: produce(configOnePageCheckout, (draft) => {}),
                policy_config: produce(configConfirmAgreementToTerms, (draft) => {}),
                isShowCoupon: configShowCoupon,
                isShowInvoice: configShowInvoice,
                isShowCountry: configShowCountry,
                hidden_multi_national: configMultiNational,
            };
        },
    }));

    const getDisplayPaymentByCode = (parentCode, childCode) => {
        let result;
        if (parentCode && childCode) {
            const result = find(appConfig.PAYMENT_METHODS[parentCode].TYPE, (item) => item.CODE == childCode);
            if (result) {
                return result.NAME;
            }
        }

        result = find(paymentReducer.gateways, (item) => item.code == parentCode) || {};
        return result.name;
    };

    //hàm giúp thực hiện việc sắp xếp khi kéo thả
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if (result.type == 'droppableItem') {
            const items = reorder(configPayment, result.source.index, result.destination.index);
            setConfigPayment(items);
        } else if (result.type.includes('droppableSubItem')) {
            const parentCode = result.type.split('-')[1];
            const itemSubItemMap = find(configPayment, (item) => item.code == parentCode);

            const reorderedSubItems = reorder(itemSubItemMap.methods, result.source.index, result.destination.index);

            let newItems = produce(configPayment, (draft) => {
                draft.map((item) => {
                    if (item.code == parentCode) {
                        item.methods = reorderedSubItems;
                    }
                    return item;
                });
            });

            setConfigPayment(newItems);
        }
    };

    const onPin = (index) => {
        const items = reorder(configPayment, index, 0);

        setConfigPayment(items);
    };

    const handleSetIsShowMethods = (item) => {
        setIsShowMethods(!isShowMethods);
        setPaymentCode(item.code);
    };

    // ---------------------------------Add Customfield---------------------------------------------

    const handleAddCustomfieldSelected = (data) => {
        if (data && data.length > 0) {
            if (tabActiveSelected == 'TAB_CUSTOMER_INFO') {
                setConfigCustomer({
                    ...configCustomer,
                    custom_field:
                        configCustomer.custom_field && configCustomer.custom_field.length > 0
                            ? [...configCustomer.custom_field, ...data]
                            : data,
                });
            } else {
                setConfigShipping({
                    ...configShipping,
                    custom_field:
                        configShipping.custom_field && configShipping.custom_field.length > 0
                            ? [...configShipping.custom_field, ...data]
                            : data,
                });
            }
            setIsShowModalAddCustomField(false);
        }
    };
    return props.preview ? null : (
        <React.Fragment>
            <div
                id='panel-setting-checkout'
                className={`ladiui form-group panel-setting-checkout ladi-row ${props.preview ? 'preview' : ''}`}
                // style={{
                //   display: "flex",
                //   justifyContent: "space-between",
                //   flexWrap: "wrap",
                //   marginBottom: 0,
                // }}
            >
                <ul className='ladiui nav nav-tabs mt-24'>
                    <li className={`ladiui nav-item`} onClick={() => setTabActiveSelected('TAB_CUSTOMER_INFO')}>
                        <span className={`ladiui tab-link ${tabActiveSelected == 'TAB_CUSTOMER_INFO' ? 'active' : ''}`}>
                            Thông tin khách hàng
                        </span>
                    </li>
                    <li className={`ladiui nav-item`} onClick={() => setTabActiveSelected('TAB_SHIPPING')}>
                        <span className={`ladiui tab-link ${tabActiveSelected == 'TAB_SHIPPING' ? 'active' : ''}`}>
                            Thông tin vận chuyển
                        </span>
                    </li>
                    <li className={`ladiui nav-item`} onClick={() => setTabActiveSelected('TAB_PAYMENT')}>
                        <span className={`ladiui tab-link ${tabActiveSelected == 'TAB_PAYMENT' ? 'active' : ''}`}>Cấu hình thanh toán</span>
                    </li>
                </ul>
                <div style={{ display: 'block' }} className='flex-basis'>
                    {tabActiveSelected == 'TAB_CUSTOMER_INFO' && (
                        <div className='ladi-card ladiui-table-responsive form-group checkout-config'>
                            <div className='ladiui-label title-block'>
                                <span>{t('PRODUCT_EVENTS.LB_CONFIG_CUSTOMER')}</span>

                                <div className='ladiui item-form switch'>
                                    <label className='ladiui switch' style={{ display: 'block', margin: '0px 12px' }}>
                                        <input
                                            type='checkbox'
                                            name='configurable'
                                            value={configCustomer.configurable}
                                            onChange={(event) => {
                                                onChangeConfigCustomer(event);
                                            }}
                                            checked={configCustomer.configurable || false}
                                            disabled={props.preview}
                                        />
                                        <span className='ladiui slider round'></span>
                                    </label>
                                </div>
                            </div>
                            <div className='block-table-customer'>
                                <table className='ladiui table'>
                                    <thead>
                                        <tr>
                                            <th className='text-left'>{t('PRODUCT_EVENTS.LB_FIELD')}</th>
                                            <th className='text-center'>{t('PRODUCT_EVENTS.LB_DISPLAY')}</th>
                                            <th className='text-center'>{t('PRODUCT_EVENTS.LB_REQUIRED')}</th>
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_FULLNAME')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_full_name'
                                                    value={configCustomer.enable_full_name}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.enable_full_name || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_full_name'
                                                    value={configCustomer.required_full_name}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.required_full_name || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                        </tr>

                                        <tr className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_PHONE')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_phone'
                                                    value={configCustomer.enable_phone}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.enable_phone || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_phone'
                                                    value={configCustomer.required_phone}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.required_phone || false}
                                                    disabled={
                                                        props.preview ||
                                                        !configCustomer.configurable ||
                                                        (configCustomer.required_phone && !configCustomer.required_email)
                                                    }
                                                />
                                            </td>
                                        </tr>

                                        <tr className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_EMAIL')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_email'
                                                    value={configCustomer.enable_email}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.enable_email || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_email'
                                                    value={configCustomer.required_email}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.required_email || false}
                                                    disabled={
                                                        props.preview ||
                                                        !configCustomer.configurable ||
                                                        (configCustomer.required_email && !configCustomer.required_phone)
                                                    }
                                                />
                                            </td>
                                        </tr>

                                        <tr className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_ADDRESS')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_address'
                                                    value={configCustomer.enable_address}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.enable_address || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_address'
                                                    value={configCustomer.required_address}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.required_address || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                        </tr>

                                        <tr className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_NOTE')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_note'
                                                    value={configCustomer.enable_note}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.enable_note || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_note'
                                                    value={configCustomer.required_note}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.required_note || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                        </tr>

                                        <tr className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_LOCATION')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_location'
                                                    value={configCustomer.enable_location}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.enable_location || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_location'
                                                    value={configCustomer.required_location}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.required_location || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                        </tr>
                                        <tr className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_ZIP_CODE')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_zipcode'
                                                    value={configCustomer.enable_zipcode}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.enable_zipcode || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_zipcode'
                                                    value={configCustomer.required_zipcode}
                                                    onChange={(event) => {
                                                        onChangeConfigCustomer(event);
                                                    }}
                                                    checked={configCustomer.required_zipcode || false}
                                                    disabled={props.preview || !configCustomer.configurable}
                                                />
                                            </td>
                                        </tr>
                                        {configCustomer.custom_field &&
                                            configCustomer.custom_field.length > 0 &&
                                            configCustomer.custom_field.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className={`checkout-config-item ${configCustomer.configurable ? '' : 'disabled'}`}
                                                    >
                                                        <td>
                                                            <div className='flex'>
                                                                <span>{item.label ? item.label : ''}</span>
                                                                <a
                                                                    className='flex ml-8'
                                                                    onClick={() => handleDeleteCustomerFieldItem(index)}
                                                                >
                                                                    <i className='ladi-icon icon-bin' />
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td className='text-center'>
                                                            <input
                                                                type='checkbox'
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                                name='is_enable'
                                                                value={item.is_enable}
                                                                onChange={(event) => {
                                                                    onChangeConfigCustomerField(event, index);
                                                                }}
                                                                checked={item.is_enable || false}
                                                                disabled={props.preview || item.is_enable}
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <input
                                                                type='checkbox'
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                                name='is_required'
                                                                value={item.is_required}
                                                                onChange={(event) => {
                                                                    onChangeConfigCustomerField(event, index);
                                                                }}
                                                                checked={item.is_required || false}
                                                                disabled={props.preview || !configCustomer.configurable}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <div className='cursor-pointer pb-16'>
                                    <a
                                        className='flex-center-left cursor-pointer pd-0'
                                        onClick={() => {
                                            setIsShowModalAddCustomField(true);
                                            setDataCustomField(configCustomer.custom_field || []);
                                        }}
                                    >
                                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' className='mr-8' />
                                        <span>Thêm trường tùy chỉnh</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                    {tabActiveSelected == 'TAB_SHIPPING' && (
                        <div className='ladi-card ladiui-table-responsive form-group checkout-config'>
                            <div className='ladiui-label title-block'>
                                <span>{t('PRODUCT_EVENTS.LB_CONFIG_SHIPPING')}</span>
                                <div className='ladiui item-form switch'>
                                    <label className='ladiui switch' style={{ display: 'block', margin: '0px 12px' }}>
                                        <input
                                            type='checkbox'
                                            name='configurable'
                                            value={configShipping.configurable}
                                            onChange={(event) => {
                                                onChangeConfigShipping(event);
                                            }}
                                            checked={configShipping.configurable || false}
                                            disabled={props.preview}
                                        />
                                        <span className='ladiui slider round'></span>
                                    </label>
                                </div>
                            </div>
                            <div className='block-table-customer'>
                                <table className='ladiui table'>
                                    <thead>
                                        <tr>
                                            <th className='text-left'>{t('PRODUCT_EVENTS.LB_FIELD')}</th>
                                            <th className='text-center'>{t('PRODUCT_EVENTS.LB_DISPLAY')}</th>
                                            <th className='text-center'>{t('PRODUCT_EVENTS.LB_REQUIRED')}</th>
                                            <th />
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_FULLNAME')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_full_name'
                                                    value={configShipping.enable_full_name}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.enable_full_name || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_full_name'
                                                    value={configShipping.required_full_name}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.required_full_name || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                        </tr>

                                        <tr className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_PHONE')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_phone'
                                                    value={configShipping.enable_phone}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.enable_phone || false}
                                                    disabled={
                                                        props.preview ||
                                                        !configShipping.configurable ||
                                                        !configShipping.required_email ||
                                                        !configShipping.enable_email
                                                    }
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_phone'
                                                    value={configShipping.required_phone}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.required_phone || false}
                                                    disabled={
                                                        props.preview ||
                                                        !configShipping.configurable ||
                                                        !configShipping.required_email ||
                                                        !configShipping.enable_email
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_EMAIL')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_email'
                                                    value={configShipping.enable_email}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.enable_email || false}
                                                    disabled={
                                                        props.preview ||
                                                        !configShipping.configurable ||
                                                        !configShipping.required_phone ||
                                                        !configShipping.enable_phone
                                                    }
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_email'
                                                    value={configShipping.required_email}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.required_email || false}
                                                    disabled={
                                                        props.preview ||
                                                        !configShipping.configurable ||
                                                        !configShipping.required_phone ||
                                                        !configShipping.enable_phone
                                                    }
                                                />
                                            </td>
                                        </tr>
                                        <tr className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_ADDRESS')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_address'
                                                    value={configShipping.enable_address}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.enable_address || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_address'
                                                    value={configShipping.required_address}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.required_address || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                        </tr>
                                        <tr className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_NOTE')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_note'
                                                    value={configShipping.enable_note}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.enable_note || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_note'
                                                    value={configShipping.required_note}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.required_note || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                        </tr>

                                        <tr className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_LOCATION')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_location'
                                                    value={configShipping.enable_location}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.enable_location || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_location'
                                                    value={configShipping.required_location}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.required_location || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                        </tr>
                                        <tr className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}>
                                            <td>{t('PRODUCT_EVENTS.LB_CONFIG_ZIP_CODE')}</td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='enable_zipcode'
                                                    value={configShipping.enable_zipcode}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.enable_zipcode || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                            <td className='text-center'>
                                                <input
                                                    type='checkbox'
                                                    className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                    name='required_zipcode'
                                                    value={configShipping.required_zipcode}
                                                    onChange={(event) => {
                                                        onChangeConfigShipping(event);
                                                    }}
                                                    checked={configShipping.required_zipcode || false}
                                                    disabled={props.preview || !configShipping.configurable}
                                                />
                                            </td>
                                        </tr>
                                        {configShipping.custom_field &&
                                            configShipping.custom_field.length > 0 &&
                                            configShipping.custom_field.map((item, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        className={`checkout-config-item ${configShipping.configurable ? '' : 'disabled'}`}
                                                    >
                                                        <div className='flex'>
                                                            <span>{item.label ? item.label : ''}</span>
                                                            <a className='flex ml-8' onClick={() => handleDeleteShippingFieldItem(index)}>
                                                                <i className='ladi-icon icon-bin' />
                                                            </a>
                                                        </div>
                                                        <td className='text-center'>
                                                            <input
                                                                type='checkbox'
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                                name='is_enable'
                                                                value={item.is_enable}
                                                                onChange={(event) => {
                                                                    onChangeConfigShippingField(event, index);
                                                                }}
                                                                checked={item.is_enable || false}
                                                                disabled={props.preview || item.is_enable}
                                                            />
                                                        </td>
                                                        <td className='text-center'>
                                                            <input
                                                                type='checkbox'
                                                                className='ladiui checkbox size-checkbox form-check-input-checkbox'
                                                                name='is_required'
                                                                value={item.is_required}
                                                                onChange={(event) => {
                                                                    onChangeConfigShippingField(event, index);
                                                                }}
                                                                checked={item.is_required || false}
                                                                disabled={props.preview || !configShipping.configurable}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                                <div className='cursor-pointer pb-16'>
                                    <a
                                        className='flex-center-left cursor-pointer pd-0'
                                        onClick={() => {
                                            setIsShowModalAddCustomField(true);
                                            setDataCustomField(configShipping.custom_field || []);
                                        }}
                                    >
                                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-add.svg' className='mr-8' />
                                        <span>Thêm trường tùy chỉnh</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                    {tabActiveSelected == 'TAB_PAYMENT' && (
                        <div className='ladi-card ladiui-table-responsive form-group checkout-config flex-basis config-payments'>
                            <label className='ladiui-label title-block'>{t('PRODUCT_EVENTS.LB_CONFIG_PAYMENT')}</label>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId='droppable' type='droppableItem'>
                                    {(provided, snapshot) => (
                                        <div {...provided.droppableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                            <div className='ladiui accordion' id='accordionExample'>
                                                <table className='ladiui table list-active-payment-method'>
                                                    <tbody>
                                                        {map(configPayment, (item, index) => (
                                                            <Draggable key={item.code} draggableId={item.code} index={index}>
                                                                {(provided, snapshot) => (
                                                                    <tr
                                                                        className='ladiui form-group accordion-item'
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                    >
                                                                        <td>
                                                                            <div
                                                                                className='menu-item-payment-method'
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between',
                                                                                }}
                                                                            >
                                                                                <div className='flex'>
                                                                                    <div className='flex mr-8'>
                                                                                        <img src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg' />
                                                                                    </div>
                                                                                    <label className='flex items-center'>
                                                                                        <input
                                                                                            id={`gateway-item-${item.code}`}
                                                                                            type='checkbox'
                                                                                            className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                                                                            value={item.code}
                                                                                            onChange={(event) => {
                                                                                                onChangeConfigPayment(index);
                                                                                            }}
                                                                                            checked={item.is_active || false}
                                                                                            disabled={props.preview}
                                                                                        />{' '}
                                                                                        <span>{getDisplayPaymentByCode(item.code)}</span>
                                                                                    </label>
                                                                                </div>
                                                                                {item.methods && item.methods.length > 0 && (
                                                                                    <div
                                                                                        style={{
                                                                                            float: 'right',
                                                                                            padding: '10px',
                                                                                            height: 'auto',
                                                                                        }}
                                                                                        className={`ladiui btn dropdown-payment-method ${
                                                                                            isShowMethods && paymentCode == item.code
                                                                                                ? 'arrow-up'
                                                                                                : 'arrow-down'
                                                                                        }`}
                                                                                        data-toggle='collapse'
                                                                                        data-target={`colspan-${item.code}`}
                                                                                        onClick={() => handleSetIsShowMethods(item)}
                                                                                    ></div>
                                                                                )}
                                                                            </div>
                                                                            {item.methods &&
                                                                                item.methods.length > 0 &&
                                                                                isShowMethods &&
                                                                                paymentCode == item.code && (
                                                                                    <Droppable
                                                                                        droppableId={item.code}
                                                                                        type={`droppableSubItem-${item.code}`}
                                                                                    >
                                                                                        {(provided, snapshot) => (
                                                                                            <div
                                                                                                className='gateway-item'
                                                                                                {...provided.droppableProps}
                                                                                                {...provided.dragHandleProps}
                                                                                                ref={provided.innerRef}
                                                                                            >
                                                                                                <div
                                                                                                    id={`colspan-${item.code}`}
                                                                                                    className='ladiui'
                                                                                                    // data-parent="accordionExample"
                                                                                                >
                                                                                                    <div className='child-content'>
                                                                                                        {map(
                                                                                                            item.methods,
                                                                                                            (method, index) => {
                                                                                                                return (
                                                                                                                    <Draggable
                                                                                                                        key={method}
                                                                                                                        draggableId={`${item.code}_${method}`}
                                                                                                                        index={index}
                                                                                                                    >
                                                                                                                        {(
                                                                                                                            provided,
                                                                                                                            snapshot
                                                                                                                        ) => (
                                                                                                                            <div className='flex'>
                                                                                                                                <div
                                                                                                                                    ref={
                                                                                                                                        provided.innerRef
                                                                                                                                    }
                                                                                                                                    {...provided.draggableProps}
                                                                                                                                    {...provided.dragHandleProps}
                                                                                                                                    className='flex'
                                                                                                                                >
                                                                                                                                    <img
                                                                                                                                        src='https://w.ladicdn.com/ladiui/ladisales/icons/icon-move.svg'
                                                                                                                                        className='mr-8'
                                                                                                                                    />
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            display:
                                                                                                                                                'block',
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        {getDisplayPaymentByCode(
                                                                                                                                            item.code,
                                                                                                                                            method
                                                                                                                                        )}
                                                                                                                                    </span>
                                                                                                                                </div>
                                                                                                                                {
                                                                                                                                    provided.placeholder
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </Draggable>
                                                                                                                );
                                                                                                            }
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                                {provided.placeholder}
                                                                                            </div>
                                                                                        )}
                                                                                    </Droppable>
                                                                                )}
                                                                        </td>
                                                                        {provided.placeholder}
                                                                    </tr>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                        {provided.placeholder}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    )}
                    <div className='block-config-checkout mt-24 mb-0'>
                        <label className='ladiui-label title-block'>Cấu hình trang thanh toán</label>
                        <div className='block-list-config-store-checkout'>
                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 8}}>
                                <label className='flex'>
                                    <input
                                        id='checkout-config-show-cart'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configDiscount.enable_discount}
                                        onChange={(event) => {
                                            setConfigDiscount(
                                                produce(configDiscount, (draft) => {
                                                    draft.enable_discount = !draft.enable_discount;
                                                })
                                            );
                                        }}
                                        checked={configDiscount.enable_discount || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>{t('PRODUCT_EVENTS.LB_CONFIG_SHOW_DISCOUNT')}</span>
                                </label>
                            </div>

                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 8}}>
                                <label className='flex'>
                                    <input
                                        id='checkout-config-show-cart'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configShowCoupon}
                                        onChange={(event) => {
                                            setConfigShowCoupon(!configShowCoupon);
                                        }}
                                        checked={configShowCoupon || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>{t('PRODUCT_EVENTS.LB_CONFIG_SHOW_LIST_COUPON')}</span>
                                </label>
                            </div>

                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 12}}>
                                <label className='flex'>
                                    <input
                                        id='checkout-config-show-cart'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configShippingMethod.enable_shipping_method}
                                        onChange={(event) => {
                                            setConfigShippingMethod(
                                                produce(configShippingMethod, (draft) => {
                                                    draft.enable_shipping_method = !draft.enable_shipping_method;
                                                })
                                            );
                                        }}
                                        checked={configShippingMethod.enable_shipping_method || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>{t('PRODUCT_EVENTS.LB_CONFIG_SHOW_SHIPPING_METHOD')}</span>
                                </label>
                            </div>

                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 12}}>
                                <label className='flex'>
                                    <input
                                        id='show-store-description'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configStoreDescription.enable_store_description}
                                        onChange={(event) => {
                                            setConfigStoreDescription(
                                                produce(configStoreDescription, (draft) => {
                                                    draft.enable_store_description = !draft.enable_store_description;
                                                })
                                            );
                                        }}
                                        checked={configStoreDescription.enable_store_description || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>{t('PRODUCT_EVENTS.LB_CONFIG_SHOW_STORE_DESCRIPTION')}</span>
                                </label>
                            </div>

                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 12}}>
                                <label className='flex'>
                                    <input
                                        id='show-one-page-checkout'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configOnePageCheckout.enable_one_page_checkout}
                                        onChange={(event) => {
                                            setConfigOnePageCheckout(
                                                produce(configOnePageCheckout, (draft) => {
                                                    draft.enable_one_page_checkout = !draft.enable_one_page_checkout;
                                                })
                                            );
                                        }}
                                        checked={configOnePageCheckout.enable_one_page_checkout || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>One Page Checkout</span>
                                </label>
                            </div>

                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 8}}>
                                <label className='flex'>
                                    <input
                                        id='checkout-config-show-invoice'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configShowInvoice}
                                        onChange={(event) => {
                                            setConfigShowInvoice(!configShowInvoice);
                                        }}
                                        checked={configShowInvoice || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>Hiển thị xuất hóa đơn</span>
                                </label>
                            </div>

                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 12}}>
                                <label className='flex'>
                                    <input
                                        id='config-show-country'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configShowCountry}
                                        onChange={(event) => {
                                            setConfigShowCountry(!configShowCountry);
                                        }}
                                        checked={configShowCountry || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>Hiển thị quốc gia trong địa chỉ vận chuyển</span>
                                </label>
                            </div>

                            <div className='config-store-checkout' style={{marginBottom: 12, marginTop: 8}}>
                                <label className='flex'>
                                    <input
                                        id='checkout-config-show-invoice'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configMultiNational}
                                        onChange={(event) => {
                                            setConfigMultiNational(!configMultiNational);
                                        }}
                                        checked={configMultiNational || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>Ẩn đa quốc gia</span>
                                </label>
                            </div>
                        </div>

                        <div style={{marginTop: 24}}>
                            <div className='request-confirmation-of-agreement-to-terms'>
                                <label className='flex' style={{ marginBottom: "8px" }}>
                                    <input
                                        id='checkout-config-confirm-agreement-to-terms'
                                        type='checkbox'
                                        className='ladiui checkbox size-checkbox form-check-input-checkbox mr-8'
                                        value={configConfirmAgreementToTerms.is_required}
                                        onChange={(event) => {
                                            // SetconfigConfirmAgreementToTerms(!configConfirmAgreementToTerms);
                                            setConfigConfirmAgreementToTerms(
                                                produce(configConfirmAgreementToTerms, (draft) => {
                                                    draft.is_required = !draft.is_required;
                                                })
                                            );
                                        }}
                                        checked={configConfirmAgreementToTerms.is_required || false}
                                        disabled={props.preview}
                                    />{' '}
                                    <span>Yêu cầu xác nhận đồng ý điều khoản</span>
                                </label>

                                {
                                    configConfirmAgreementToTerms.is_required
                                    &&
                                    <Input
                                        name='content-agreement-to-terms'
                                        validationName={'Vui lòng nhập nội dung'}
                                        value={configConfirmAgreementToTerms.htlm_text}
                                        onChange={(event) => {
                                            setConfigConfirmAgreementToTerms(
                                                produce(configConfirmAgreementToTerms, (draft) => {
                                                    draft.htlm_text = event.target.value;
                                                })
                                            );
                                        }}
                                        placeholder={'Nhập nội dung'}
                                        validations={{ isRequired: true }}
                                    />
                                }
                            </div>
                        </div>

                        <div style={{marginTop: 24}}>
                            <div className='block-change-language'>
                                <div className='ladiui form-group'>
                                    <label className='ladiui-label'>{t('COMMON.CHOOSE_LANGUAGE')}</label>
                                    <Dropdown
                                        id='language'
                                        data={languages}
                                        onSelectItem={onChangeLanguage}
                                        currentKey={configLanguage}
                                        dropup={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isShowModalAddCustomField && (
                <ModalAddCustomField
                    isShow={isShowModalAddCustomField}
                    onClose={() => setIsShowModalAddCustomField(false)}
                    handleAddCustomfieldSelected={handleAddCustomfieldSelected}
                    selectedDataCustomField={dataCustomField || []}
                />
            )}
        </React.Fragment>
    );
});

PanelSettingCheckout.propTypes = {
    checkoutConfig: PropTypes.object,
    preview: PropTypes.bool,
};

PanelSettingCheckout.defaultProps = {
    preview: false,
};
export default PanelSettingCheckout;
