import React, { useEffect, useState, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import baseHelper from '../../../helpers/BaseHelper';
import ModalMembershipKey from './ModalMembershipKey';

import courseActions from '../../../redux/futures/course/actions';
import * as courseTypes from '../../../redux/futures/course/types';

import productActions from '../../../redux/futures/product/actions';
import * as productTypes from '../../../redux/futures/product/types';
import appConfig from '../../../config/app';
import { find } from 'lodash';
// eslint-disable-next-line max-lines-per-function
function ModalHistoryLearn(props) {
    const {
        t,
        onClose,
        isShow,
        statusActive,
        onChangeSearchVariantInput,
        onChangeStatusLearn,
        searchVariantValue,
        onKeyDownInputOwnedCourse,
        searchOwnedCourse,
        customerID,
    } = props;
    const wrapperRef = useRef();

    const dispatch = useDispatch();

    const courseReducer = useSelector((state) => state.course);
    const productReducer = useSelector((state) => state.product);

    const [dataOwnedCourses, setDataOwnedCourses] = useState([]);

    const [selectedProductId, setSelectedProductId] = useState(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedReason, setSelectedReason] = useState('');
    const [isShowModalMemberShipKey, setIsShowModalMembershipKey] = useState(false);
    const [isShowModalReasonLock, setIsShowModalReasonLock] = useState(false);
    const [isShowModalAddCourse, setIsShowModalAddCourse] = useState(false);

    const [dataCourses, setDataCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [valueSearchOwnedCourse, setValueSearchOwnedCourse] = useState('');

    useEffect(() => {
        if (customerID) {
            setSelectedCustomerId(customerID);
        }
    }, [customerID]);

    useEffect(() => {
        if (props.dataOwnedCourses && props.dataOwnedCourses.length > 0) {
            setDataOwnedCourses(props.dataOwnedCourses);
        }
    }, [props.dataOwnedCourses]);

    useEffect(() => {
        if (productReducer.action == productTypes.SEARCH_PRODUCT) {
            if (productReducer.status) {
                if (productReducer.products && productReducer.products.length > 0) {
                    setDataCourses(productReducer.products);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', productReducer.message, 'OK');
            }
        }
    }, [productReducer]);

    useEffect(() => {

        if (courseReducer.action == courseTypes.HISTORY_LEARN) {
            if (courseReducer.status) {
                if (courseReducer.history) {
                    setDataOwnedCourses(courseReducer.history);
                }
            } else {
                window.LadiUI.showErrorMessage('Thông báo', courseReducer.message, 'OK');
            }
        }

    }, [courseReducer]);

    const eventCancel = () => {
        onClose(false);
    };

    const handleMembershipKey = (item) => {
        if (item.customer_id && item.product_id) {
            setSelectedCustomerId(item.customer_id);
            setSelectedProductId(item.product_id);
            setIsShowModalMembershipKey(true);
        }
    };

    const handleActiveCourse = (item) => {
        if (item.customer_id && item.product_id) {
            setSelectedCustomerId(item.customer_id);
            setSelectedProductId(item.product_id);
            window.LadiUI.showModal('confirm-enable-course');
        }
    };

    const handleOpenModalReasonLock = (reason) => {
        if (reason) {
            setSelectedReason(reason);
            setIsShowModalReasonLock(true);
        }
    };

    const fetchListCourseItem = () => {
        return dataOwnedCourses.map((item, index) => {
            return (
                <tr key={index} className='ladiui table-vertical main'>
                    <td>
                        <span
                            style={{
                                display: '-webkit-box',
                                //     overflow: 'hidden',
                                //     WebkitLineClamp: 1,
                                //     WebkitBoxOrient: 'vertical',
                                width: '250px',
                            }}
                        >
                            {item.product_name ? item.product_name : ''}
                        </span>
                    </td>
                    <td>{item.lesson_name}</td>
                    <td>{item.start_time ? baseHelper.formatDateToStr(item.start_time) : ''}</td>
                    <td>
                        {item.finish_time ? (
                            <div
                                data-tooltip={baseHelper.formatDateToStr(item.finish_time)}
                                data-tooltip-position='left'>
                                <span className='ladiui flex-center-start'>
                                    <i className='ldicon-status-success mr-8' />
                                    Hoàn thành
                                </span>
                            </div>
                        ) : (
                            <span className='ladiui flex-center-start'>
                                <i className='ldicon-status-normal mr-8' />
                                Đang học
                            </span>
                        )}
                    </td>

                </tr>
            );
        });
    };

    const handleShowModalAddCourse = () => {
        setIsShowModalAddCourse(true);

        let selectedProductIds =
            dataOwnedCourses && props.dataOwnedCourses.length > 0 ? dataOwnedCourses.map((item) => item.product_id) : [];
        dispatch(productActions.search('', 'Course', null, selectedProductIds));
    };
    const isLoadingAddOwnedCourse = courseReducer.loading && courseReducer.waiting == courseTypes.ADD_COURSE_TO_STUDENT;

    return (
        <>
            <div className={`ladiui modal ${isShow ? 'show' : ''}`} id='modal-history-learn'>
                <div className='ladiui modal-dialog modal-dialog-centered' ref={wrapperRef} style={{ width: "1000px" }}>
                    <div className='ladiui modal-content '>
                        <div className='ladiui modal-header '>
                            <h2 className='ladiui modal-title '>Danh sách bài học đã xem</h2>
                            <button data-dismiss='modal' data-target='add-product' onClick={eventCancel} className='ladiui modal-close'>
                                <i className='ladiui icon icon-ldp-close'></i>
                            </button>
                        </div>
                        <div className='ladiui modal-body position-initial'>
                            <div className='block-search ls-flex ls-justify-start ls-gap-16'>
                                <div className='ladiui search-group' style={{ width: "400px" }}>
                                    <input
                                        className='ladiui search-field dropdown-toggle form-control'
                                        value={searchVariantValue}
                                        placeholder={'Tìm kiếm khóa học'}
                                        data-toggle='dropdown'
                                        aria-expanded='false'
                                        onChange={onChangeSearchVariantInput}
                                        onKeyDown={onKeyDownInputOwnedCourse}
                                    />
                                    <i className='ladiui icon icon-search' onClick={searchOwnedCourse}></i>
                                </div>
                                <div className="ladiui flex-row">
                                    <div className="ladiui btn-group">
                                        <div className="ladiui dropdown">
                                            <button className="ladiui btn btn-outline-light dropdown-toggle select-dropdown" data-toggle="dropdown">
                                                <i className="ladiui icon"></i>
                                                <span className="ladiui dropdown-text">{"Tất cả"}</span>
                                            </button>
                                            <ul className="ladiui dropdown-menu" defaultValue={statusActive}>
                                                {appConfig.LIST_STATUS_LEARN.map((item) => (
                                                    <li key={item.value} onClick={(e) => onChangeStatusLearn(item.value)}>
                                                        <a className="ladiui dropdown-item" href="#">
                                                            {item.name}
                                                        </a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='list-courses'>
                                {dataOwnedCourses && dataOwnedCourses.length > 0 && (
                                    <table className={`ladiui table text-left`}>
                                        <thead>
                                            <tr className='ladiui table-vertical'>
                                                <th scope='col'>Tên khóa học</th>
                                                <th scope='col' className='text-left'>
                                                    Tên bài học
                                                </th>
                                                <th scope='col' className='text-left'>
                                                    Bắt đầu xem
                                                </th>
                                                <th scope='col' className='text-left'>
                                                    Trạng thái
                                                </th>

                                            </tr>
                                        </thead>
                                        <tbody>{fetchListCourseItem()}</tbody>
                                    </table>
                                )}

                            </div>
                        </div>

                        <div className='ladiui modal-footer '>
                            <div className='ladiui footer-modal-btn'>
                                <button
                                    type='button'
                                    data-dismiss='modal'
                                    data-target='add-product'
                                    className='ladiui btn btn-secondary'
                                    onClick={eventCancel}
                                >
                                    {t('ACTIONS.CLOSE')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default withTranslation()(ModalHistoryLearn);
